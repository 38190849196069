import styles from "./css/ForOwners.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

const ForOwners: React.FC = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heroHeaderSection}>
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.container3}>
              <div className={styles.headingSupportingText}>
                <div className={styles.headingBadge}>
                  <span className={styles.subheading}>Nice to meet you</span>
                  <span className={styles.heading}>
                    Earn Revenue from Your Idle Trailers and Vehicles
                  </span>
                </div>
                <span className={styles.supportingText}>
                  COOP makes it simple and safe to rent out your vehicles to
                  trusted businesses near you. List your vehicles today and
                  generate up to $5,000/month.
                </span>
                <button className={styles.button4}>
                  <span className={styles.applyNow}>Apply Now</span>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.section5}>
            <img src="/landing/driver.png" alt="driver" />
          </div>
        </div>
      </div>
      <div className={styles.featuresContainer}>
        <div className={styles.featuresSection}>
          <div className={styles.container6}>
            <div className={styles.content}>
              <div className={styles.headingSupportingText7}>
                <div className={styles.headingSubheading}>
                  <span className={styles.values}>Our values</span>
                  <span className={styles.whyRentOutVehicles}>
                    Why Rent Out Your Vehicles?
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container8}>
            <div className={styles.content9}>
              <div className={styles.featureText}>
                <div className={styles.featuredIcon}>
                  <div className={styles.chartBreakoutSquare} />
                </div>
                <div className={styles.textSupportingText}>
                  <span className={styles.avoidExpensiveStorage}>
                    Avoid Expensive Storage and Parking Costs
                  </span>
                  <span className={styles.supportingTextA}>
                    Save money and free up capital to invest in other parts of
                    your business.
                  </span>
                </div>
              </div>
              <div className={styles.featureTextB}>
                <div className={styles.featuredIconC}>
                  <div className={styles.messageSmileCircle} />
                </div>
                <div className={styles.textSupportingTextD}>
                  <span className={styles.increaseVehicleUtilization}>
                    Increase Vehicle Utilization
                  </span>
                  <span className={styles.maximizeEfficiency}>
                    Maximize efficiency and reduce operational costs with
                    vehicles consistently in use.
                  </span>
                </div>
              </div>
              <div className={styles.featureTextE}>
                <div className={styles.featuredIconF}>
                  <div className={styles.zap} />
                </div>
                <div className={styles.textSupport}>
                  <span className={styles.offsetTransportation}>
                    Offset Transportation Costs
                  </span>
                  <span className={styles.payOffCosts}>
                    Pay off vehicle leases, permits, and more.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.content9}>
              <div className={styles.featureText}>
                <div className={styles.featuredIcon}>
                  <div className={styles.chartBreakoutSquare} />
                </div>
                <div className={styles.textSupportingText}>
                  <span className={styles.avoidExpensiveStorage}>
                    Avoid Expensive Storage and Parking Costs
                  </span>
                  <span className={styles.supportingTextA}>
                    Save money and free up capital to invest in other parts of
                    your business.
                  </span>
                </div>
              </div>
              <div className={styles.featureTextB}>
                <div className={styles.featuredIconC}>
                  <div className={styles.messageSmileCircle} />
                </div>
                <div className={styles.textSupportingTextD}>
                  <span className={styles.increaseVehicleUtilization}>
                    Increase Vehicle Utilization
                  </span>
                  <span className={styles.maximizeEfficiency}>
                    Maximize efficiency and reduce operational costs with
                    vehicles consistently in use.
                  </span>
                </div>
              </div>
              <div className={styles.featureTextE}>
                <div className={styles.featuredIconF}>
                  <div className={styles.zap} />
                </div>
                <div className={styles.textSupport}>
                  <span className={styles.offsetTransportation}>
                    Offset Transportation Costs
                  </span>
                  <span className={styles.payOffCosts}>
                    Pay off vehicle leases, permits, and more.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blogSection}>
        <div className={styles.container19}>
          <div className={styles.content1a}>
            <div className={styles.headingSupport}>
              <div className={styles.frame1b}>
                <span className={styles.subheading1c}>Process</span>
                <span className={styles.howTruckSharing}>
                  How truck sharing works
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container1d}>
          <div className={styles.content1e}>
            <div className={styles.blogPostCard}>
              <div className={styles.image}>
                <img src="/landing/driver.png" alt="driver" />
              </div>
              <div className={styles.listCommercialVehicle}>
                <span className={styles.createAccount}>
                  List your commercial vehicle
                </span>
                <span className={styles.startRenting}>
                  Create an account for free and to start renting out your
                  company's idle trucks, tractors, or trailers.
                </span>
              </div>
            </div>
            <div className={styles.blogPostCard1f}>
              <div className={styles.image}>
                <img src="/landing/man with phone in hands.png" alt="driver" />
              </div>
              <div className={styles.setAvailability}>
                <span className={styles.text19}>Set your availability</span>
                <span className={styles.supportingText20}>
                  Select and adjust the dates that your vehicles are available
                  for other trusted companies to rent.
                </span>
              </div>
            </div>
            <div className={styles.blogPostCard21}>
              <div className={styles.image}>
                <img src="/landing/handshake_2.png" alt="driver" />
              </div>
              <div className={styles.headingText}>
                <span className={styles.approveReservations}>
                  Approve reservations
                </span>
                <span className={styles.supportingText23}>
                  Accept rental requests and meet with Renters on the
                  reservation start date to hand off your keys.
                </span>
              </div>
            </div>
            <div className={styles.blogPostCard24}>
              <div className={styles.image}>
                <img src="/landing/dude_looking.png" alt="driver" />
              </div>{" "}
              <div className={styles.headingText26}>
                <span className={styles.sitBackEarn}>Sit back & earn</span>
                <span className={styles.supportingText27}>
                  With complete peace of mind, optimize your fleet's utilization
                  and enjoy your new stream of income.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container28Parent}>
        <div className={styles.container28}>
          <div className={styles.image29}>
            <img src="/landing/handshake_3.png" alt="handshak" />
          </div>
          <div className={styles.frame2a}>
            <div className={styles.frame2b}>
              <span className={styles.testimonials}>Testimonials</span>
              <span className={styles.howTruckSharingWorks}>
                How truck sharing works
              </span>
            </div>
            <div className={styles.testimonialsTestimonial}>
              <div className={styles.icon} />
              <div className={styles.leadingContent}>
                <span className={styles.text}>
                  From the day I set it up, the trucks were already getting
                  rented, and I was able to cover our lease payment and our
                  trailer’s maintenance in just one month.
                </span>
              </div>
              <div className={styles.cite}>
                <div className={styles.avatar} />
                <div className={styles.text2c}>
                  <span className={styles.name}>Joseph Rodriguez</span>
                  <span className={styles.title}>CEO, Workcation</span>
                </div>
              </div>
            </div>
            <div className={styles.testimonialsTestimonial2d}>
              <div className={styles.icon2e} />
              <div className={styles.leadingContent2f}>
                <span className={styles.text30}>
                  You can take advantage of the platform, rent those trucks,
                  tractors, or trailers, and generate revenue that you didn’t
                  have before.
                </span>
              </div>
              <div className={styles.cite31}>
                <div className={styles.avatar32} />
                <div className={styles.text33}>
                  <span className={styles.name34}>Joseph Rodriguez</span>
                  <span className={styles.title35}>
                    Business Development Manager,
                    <br />
                    Bowman Trailer Leasing
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.topBorder} /> */}

      <div className={styles.container36}>
        <div className={styles.frame37}>
          <div className={styles.accordion}>
            <Accordion
              defaultExpanded
              style={{
                background: "transparent",
                // boxShadow: "unset",
                border: "1px solid lightgray",
                borderRadius: "16px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Subject 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                background: "transparent",
                // boxShadow: "unset",
                border: "1px solid lightgray",
                borderRadius: "16px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Subject 2</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={styles.header4f}>
            <div className={styles.headingSupportingText50}>
              <div className={styles.heading51}>
                <span className={styles.frequentlyAskedQuestions}>
                  Frequently{" "}
                </span>
                <span className={styles.asked}>asked</span>
                <span className={styles.questions}> questions</span>
              </div>
              <div className={styles.supportingText52}>
                <span className={styles.moreInformationCommonQuestions}>
                  For more information or to see some of our most common
                  questions, please visit the{" "}
                </span>
                <span className={styles.helpCenter}>Help Center</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sectionDivider}>
        <div className={styles.container7b}>
          <div className={styles.divider} />
        </div>
      </div>
    </div>
  );
};

export default ForOwners;
