import { SET_USER } from "./actionTypes";
import { CurrentUserData } from "../types";

type PartialCurrentUserData = Partial<CurrentUserData>;

export const setCurrentUserData = (
  data: PartialCurrentUserData,
) => ({
  type: SET_USER,
  data,
});
