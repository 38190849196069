import styles from "./css/AddTruckApplyToRent.module.css";
import MapPageNavbar from "../Map_Page/MapPageNavbar";
import { INavbarProps } from "./types";
import { Link } from "react-router-dom";

const Navbar2: React.FC<INavbarProps | any> = (isNotOnMapPage) => {
  return (
    <>
      <div className={styles.celesClubPVidiGruzovikhMaParent}>
        <Link to="/main">
          <img
            className={styles.theLogoMakerBuiltFor10001}
            loading="eager"
            alt=""
            src="/logos/Truck4 logo-1.png"
          />
        </Link>

        <img
          className={styles.stockPhotoBigRigGrayBonneIcon}
          loading="eager"
          alt=""
          src="/add_truck/addTruckSidebar.png"
        />
        <div className={styles.inputTextParent}>
          <div className={styles.inputText}>
            Hire internationally with complete confidence
          </div>
          <div className={styles.inputText1}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
      </div>
      <MapPageNavbar hideLogo={true} isNotOnMapPage={isNotOnMapPage} />
    </>
  );
};

export default Navbar2;
