import styles from '../styles/Registration/RegistrationLayout.module.css';
import RegistrationNavbar from './Navbars/RegistrationNavbar';
import OwnerRegistrationSidebar from './Sidebars/OwnerRegistrationSidebar';
import OwnerRegistration from '../pages/Registration/OwnerRegistration';

const OwnerRegistrationLayout = () => {
  return (
    <>
      <section className={styles.layoutContainer}>
        <div className={styles.navbarContainer}>
          <RegistrationNavbar />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.sidebarContainer}>
            <OwnerRegistrationSidebar />
          </div>
          <div className={styles.mainContent}>
            <OwnerRegistration />
          </div>
        </div>
      </section>
    </>
  )
}

export default OwnerRegistrationLayout;
