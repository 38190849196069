import { useRef, useState } from "react";
import styles from "./ProductPage.module.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import CheckBox from "./CheckBox";
import MapComponent from "../Map_Page/MapComponent";

interface ImageGrid {
  truck: any;
  imageCount: number;
  setImageViewer: (value: boolean) => void;
  scrollToMap: () => void;
}

const PhotosParent: React.FC<ImageGrid> = ({
  truck,
  imageCount,
  setImageViewer,
}) => {
  const tableData: Record<string, any> = {
    year: truck.year,
    manufacturer: truck.manufacturer,
    model: truck.model,
    mileage: truck.mileage,
    transmission: truck.transmissionType,
    chassis: truck.chassis,
    "Door Type": truck.doorType,
    "Lift Gate":
      truck.liftGateType === "noliftgate" ? "No Lift Gate" : truck.liftGateType,
  };

  const filteredData = Object.entries(tableData).filter(([key, value]) => {
    return value != null && value !== "";
  });

  const thumbnailsRef = useRef<{
    show: () => void;
    hide: () => void;
    visible: boolean;
  } | null>(null);
  const [index, setIndex] = useState<number>(-1);

  return (
    <div className={styles.photosParent}>
      <Lightbox
        close={() => setIndex(-1)}
        plugins={[Thumbnails]}
        thumbnails={{ ref: thumbnailsRef }}
        open={index >= 0}
        index={index}
        on={{
          click: () => {
            (thumbnailsRef.current?.visible
              ? thumbnailsRef.current?.hide
              : thumbnailsRef.current?.show)?.();
          },
        }}
      />
      <div
        className={styles.photosContainer}
        onClick={(event) => setImageViewer(true)}
      >
        {imageCount === 1 && (
          <img
            className={styles.singleImage}
            alt=""
            src={truck?.images?.threeQuarterView || "/truck/no-image-icon.svg"}
          />
        )}

        {imageCount === 2 && (
          <div className={styles.twoImages}>
            <img
              className={`${styles.imageHalf} ${styles.imageHalfForTwo}`}
              alt=""
              src={
                truck?.images?.threeQuarterView || "/truck/no-image-icon.svg"
              }
            />
            <img
              className={styles.imageHalf}
              alt=""
              src={truck?.images?.front || "/truck/no-image-icon.svg"}
            />
          </div>
        )}

        {imageCount === 3 && (
          <div className={styles.threeImages}>
            <img
              className={styles.imageLeft}
              alt=""
              src={
                truck?.images?.threeQuarterView || "/truck/no-image-icon.svg"
              }
            />
            <div className={styles.imageRight}>
              <img
                className={`${styles.imageQuarter} ${styles.imageQuarterForThree}`}
                alt=""
                src={truck?.images?.front || "/truck/no-image-icon.svg"}
              />
              <img
                className={styles.imageQuarter}
                alt=""
                src={truck?.images?.passengerSide || "/truck/no-image-icon.svg"}
              />
            </div>
          </div>
        )}

        {imageCount > 3 && (
          <div className={styles.threeImages}>
            <img
              className={styles.imageLeft}
              alt=""
              src={
                truck?.images?.threeQuarterView || "/truck/no-image-icon.svg"
              }
            />
            <div className={styles.imageRight}>
              <img
                className={`${styles.imageQuarter} ${styles.imageQuarterForThree}`}
                alt=""
                src={truck?.images?.front || "/truck/no-image-icon.svg"}
              />
              <div className={styles.imageOverlayContainer}>
                <img
                  className={styles.imageQuarter}
                  alt=""
                  src={
                    truck?.images?.passengerSide || "/truck/no-image-icon.svg"
                  }
                />
                <div className={styles.overlay}>+{imageCount - 3} photos</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.frameParent}>
        <div className={styles.peterbilt579Parent}>
          <div className={styles.peterbilt579}>
            {truck.year}{" "}
            {truck.manufacturer?.charAt(0).toUpperCase() +
              truck.manufacturer?.slice(1)}{" "}
            {truck?.model}
          </div>

          <div className={styles.vecicleCardrectangle1271}>
            <div className={styles.vecicleCardrectangle1271Child} />
          </div>
          <div
            style={{ textTransform: "capitalize" }}
            className={styles.tandemTractor}
          >
            {truck.vehicleType}
          </div>
        </div>
        <div className={styles.peterbilt579Parent}>
          <span style={{ fontWeight: "600" }}>Location: </span>
          {truck?.state?.toUpperCase()},{" "}
          {truck.city?.charAt(0).toUpperCase() + truck.city?.slice(1)},{" "}
          {truck.zipCode}
        </div>
        <div className={styles.component21Parent}>
          {truck.enableForRent && (
            <>
              <div className={styles.component21}>
                <div className={styles.rent}>Rent</div>
              </div>
              <div className={styles.div}>/</div>
            </>
          )}
          {truck.enableForLease && (
            <>
              <div className={styles.component22}>
                <div className={styles.rent1}>Lease</div>
              </div>
              <div className={styles.div1}>/</div>
            </>
          )}
          {truck.enableForLeaseForPurchase && (
            <div className={styles.component19}>
              <div className={styles.sale}>Lease To Purchase</div>
            </div>
          )}
        </div>
        <div className={styles.heyThereThisContainer}>
          <span className={styles.heyThere}>
            {" "}
            {truck.description?.charAt(0).toUpperCase() +
              truck.description?.slice(1)}
          </span>
          <span className={styles.soHitMe}></span>
        </div>
        <i className={styles.updatedTueJune}>Updated: N/A</i>
        <div className={styles.frameInner} />
        <div className={styles.about}>About</div>
        <div className={styles.gridContainer}>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
              />
              <div
                className={`${styles.reefer} bodyType`}
                style={{ textTransform: "capitalize" }}
              >
                {truck.vehicleSubtype}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.inputTextParent}>
            <div className={styles.inputText}>Length </div>
            <div className={styles.checkboxParent}>
              <CheckBox checked={truck.crewCab} text="Crew Cab" />
            </div>
          </div>
          <div className={styles.inputTextGroup}>
            <div className={styles.inputText1}>Length</div>
            <div className={styles.checkboxGroup}>
              <CheckBox checked={truck.GPS == true ? true : false} text="GPS" />
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText2}>Length</div>
            <div className={styles.checkboxContainer}>
              <CheckBox checked={truck.iftaCompliant} text="IFTA Compliant" />
            </div>
          </div>
          {/* <div className={styles.inputTextContainer}>
            <div className={styles.inputText2}>Length</div>
            <div className={styles.checkboxContainer}>
              <CheckBox
                checked={truck.dockHeight == "yes" ? true : false}
                text="Dock Height"
              />
            </div>
          </div> */}
        </div>
        <div className={styles.rectangleDiv} />
        <div className={styles.general}>General</div>
        <div className={styles.frameDiv}>
          <div className={styles.tablesParent}>
            {filteredData.map(([key, value], idx) => (
              <div key={idx} className={styles[`tables${idx + 1}`]}>
                <div className={styles[`text${idx + 1}`]}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.tablesGroup}>
            {filteredData.map(([key, value], idx) => (
              <div
                key={idx}
                className={styles[`tables${idx + 7}`]}
                style={{ textTransform: "uppercase" }}
              >
                <div className={styles[`text${idx + 7}`]}>{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.usageRestrictionsParent}>
        <div className={styles.usageRestrictions}>Usage Restrictions</div>
        <div className={styles.tagContainer}>
          <div
            className={styles.tag6}
            style={{
              backgroundColor:
                truck.allowTravelOutOfState == true ? "#ecfdf5" : "#fdecec",
            }}
          >
            <img
              className={styles.earnRevenueFromYourIdleFl6}
              alt=""
              src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
            />
            <div className={`${styles.reefer}`}>
              {truck.allowTravelOutOfState == true
                ? "Can travel out of state"
                : "Can not travel out of state"}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ratesParent}>
        <div className={styles.rates}>Rates</div>
        <div className={styles.frameWrapper}>
          <div className={styles.tagParent1}>
            <div className={styles.tag7}>
              <img
                className={styles.earnRevenueFromYourIdleFl7}
                alt=""
                src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
              />
              <div className={styles.reefer7}>${truck.rentPerDay} Daily</div>
            </div>
            <div className={styles.tag8}>
              <img
                className={styles.earnRevenueFromYourIdleFl8}
                alt=""
                src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer8}>
                ${truck.rentPrepaidMileage}/mile
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mapParent} id="map">
        <div className={styles.map}>Map</div>

        <div className={styles.theLocationIs}>
          The location is assumed, more details will be known after booking*
        </div>
        <div className={styles.rectangleContainer}>
          <MapComponent singleTruck={truck} styles={styles} />
        </div>
      </div>
      <div className={styles.tipsAboutTheRulesOfTakingParent}>
        <div className={styles.tipsAboutThe}>
          Tips about the rules of taking in rent
        </div>
        <div className={styles.instanceGroup}>
          <div className={styles.rectangleParent1}>
            <div className={styles.instanceChild} />
            <div className={styles.div4}>Truck Condition</div>
            <div className={styles.leaveTheTruck}>
              Leave the truck in the same condition in which it was received.
              Avoid damage and leave it clean and tidy.
            </div>
            <div className={styles.minOfReading}>3 min. of reading</div>
            <div className={styles.readMore}>Read more</div>
          </div>
          <div className={styles.rectangleParent2}>
            <div className={styles.instanceItem} />
            <div className={styles.div4}>Additional charges</div>
            <div className={styles.leaveTheTruck}>
              Make sure that you do not exceed the mileage limits specified in
              the rental agreement. Exceeding the limit may result in additional
              costs and penalties.
            </div>
            <div className={styles.minOfReading}>3 min. of reading</div>
            <div className={styles.readMore1}>Read more</div>
          </div>
          <div className={styles.rectangleParent3}>
            <div className={styles.instanceInner} />
            <div className={styles.div4}>Rules of canceling</div>
            <div className={styles.leaveTheTruck}>
              Make sure that you returned the truck in accordance with the
              rental agreement. Delays may result in additional fines or
              restrictions in the future.
            </div>
            <div className={styles.minOfReading}>3 min. of reading</div>
            <div className={styles.readMore2}>Read more</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotosParent;
