import styles from "../../styles/BlogsPage/Blog.module.css";
import MapPageNavbar from "../Map_Page/MapPageNavbar";
import Footer from "../../Layouts/Footers/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faArrowRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { articles } from "./Constants";
import CommercialVehicles from "./CommercialVehicles";
import { Link } from "react-router-dom";

const BlogsPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const articlesPerPage = 8;
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
  const totalPages = Math.ceil(articles.length / articlesPerPage);
  // const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState('Select an option');
  // const options = ['View all', 'Maintenance', 'Rent', 'Policy'];
  //
  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
  //
  // const handleOptionClick = (option: string) => {
  //   setSelectedOption(option);
  //   setIsOpen(false);
  // };

  return (
    <>
      <div className={styles.navbar}>
        <MapPageNavbar />
      </div>
      <main className={styles.parentContainer}>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>All topics</h2>
            <p className={styles.subtitle}>
              Look through the reference articles, we have a whole library.
            </p>
          </div>

          <div className={styles.mainGrid}>
            {/*<div className={styles.dropdown} onClick={toggleDropdown}>*/}
            {/*  <span>{selectedOption}</span>*/}
            {/*  <FontAwesomeIcon icon={faAngleDown} />*/}
            {/*  {isOpen && (*/}
            {/*    <div className={styles.dropdownList}>*/}
            {/*      {options.map((option, index) => (*/}
            {/*        <div key={index} className={styles.dropdownOption} onClick={() => handleOptionClick(option)}>*/}
            {/*          {option}*/}
            {/*        </div>*/}
            {/*      ))}*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
            <div className={styles.articlesContainer}>
              <div className={styles.firstArticle}>
                <img src={articles[0].img} alt={articles[0].alt} className={styles.articleImage} />
                <div className={styles.articleContentparent}>
                  <div className={styles.articleContent}>
                    <h2 className={styles.articleTitle}>
                      {articles[0].heading}
                    </h2>
                    <div className={styles.articleDiscription}>
                      <p className={styles.articleText}> {articles[0].description}</p>
                      <p className={styles.articleReadTime}> {articles[0].read}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.articlesGrid}>
                {currentArticles.map((article, index) => (
                  <Link to={`article/${article.id}`} key={index} className={styles.card}>
                    <img src={article.img} alt={article.alt} className={styles.cardImage} />
                    <div className={styles.cardContentParent}>
                      <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>
                          {article.heading}

                        </h2>
                        <div className={styles.discription}>
                          <p className={styles.cardText}>
                            {article.description}
                          </p>
                          <p className={styles.readTime}>{article.read}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              <div className={styles.pagination}>
                <button className={styles.previousButton} onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)} disabled={currentPage === 1}>
                  <FontAwesomeIcon icon={faArrowLeft} className={styles.buttonIcon} />
                  <span className={styles.buttonText}>Previous</span>
                </button>

                <div className={styles.paginate}>
                  {/* Always show the first page */}
                  <button className={`${styles.button} ${currentPage === 1 ? styles.active : ''}`} onClick={() => setCurrentPage(1)}>1</button>

                  {/* Show ellipsis if there are more pages between */}
                  {totalPages > 5 && currentPage > 3 && <span className={styles.ellipsis}>...</span>}

                  {/* Show the previous pages */}
                  {currentPage > 2 && (
                    <button className={`${styles.button} ${currentPage === currentPage - 1 ? styles.active : ''}`} onClick={() => setCurrentPage(currentPage - 1)}>
                      {currentPage - 1}
                    </button>
                  )}

                  {/* Show the current page */}
                  {currentPage > 1 && currentPage < totalPages && (
                    <button className={`${styles.button} ${styles.active}`} onClick={() => setCurrentPage(currentPage)}>
                      {currentPage}
                    </button>
                  )}

                  {/* Show the next pages */}
                  {currentPage < totalPages - 1 && (
                    <button className={`${styles.button} ${currentPage === currentPage + 1 ? styles.active : ''}`} onClick={() => setCurrentPage(currentPage + 1)}>
                      {currentPage + 1}
                    </button>
                  )}

                  {/* Show ellipsis if there are more pages between */}
                  {totalPages > 5 && currentPage < totalPages - 2 && <span className={styles.ellipsis}>...</span>}

                  {/* Always show the last page */}
                  {totalPages > 1 && (
                    <button className={`${styles.button} ${currentPage === totalPages ? styles.active : ''}`} onClick={() => setCurrentPage(totalPages)}>
                      {totalPages}
                    </button>
                  )}
                </div>

                <button className={styles.nextButton} onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)} disabled={currentPage === totalPages}>
                  <span className={styles.buttonText}>Next</span>
                  <FontAwesomeIcon icon={faArrowRight} className={styles.buttonIcon} />
                </button>
              </div>
            </div>

            {/*<div className={styles.sidebar}>*/}
            {/*  <div className={styles.searchContainer}>*/}
            {/*    <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />*/}
            {/*    <input type="text" placeholder="Search" className={styles.searchInput} />*/}
            {/*  </div>*/}
            {/*  <div className={styles.viewAllSection}>*/}
            {/*    <h3 className={styles.viewAllTitle}>View all</h3>*/}
            {/*    <ul className={styles.linkList}>*/}
            {/*      <li className={styles.link}>Maintenance</li>*/}
            {/*      <li className={styles.link}>Rent</li>*/}
            {/*      <li className={styles.link}>Policy</li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </main>
      <CommercialVehicles />
      <Footer />
    </>
  );
};

export default BlogsPage;
