import { UPDATE_USER_TOKEN } from "../actions/actionTypes";
import { UserToken } from "../types";

const initialState: UserToken = {
  accessToken: "",
  userToken: undefined,
  refreshToken: "",
};

export const updateUserToken = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER_TOKEN:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default updateUserToken;
