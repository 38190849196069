import React, { useState, useEffect } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import store from "./store/store";
import PagesRoutes from "./Routes";
import Toast from "./components/Toast/Toast";
import { Provider } from "react-redux";
import { GlobalProvider } from "./components/Global/GlobalState";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [location, setLocation] = useState<any>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          localStorage.setItem(
            "userLocation",
            JSON.stringify({ latitude, longitude })
          );
          console.log("User  location saved:", { latitude, longitude });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <Provider store={store}>
      <GlobalProvider>
        <Toast />
        <Router>
          <PagesRoutes />
        </Router>
      </GlobalProvider>
    </Provider>
  );
}

export default App;
