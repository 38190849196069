import AdminNavBarLeadingContent from "./AdminNavBarLeadingContent";
import { ICollapsedsidebar } from "./types";

const AdminSidebar: React.FC<ICollapsedsidebar> = ({
  isCollapsed,
}) => {
  return (
    <div>
      <AdminNavBarLeadingContent isCollapsed={isCollapsed} />
    </div>
  );
};

export default AdminSidebar;
