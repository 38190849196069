import { useState } from "react";
import axios from 'axios';
import GenericModal from "../../../components/Modal/GenericModal";
import PasswordModalBody from "./PasswordModalBody";
import {successToast, errorToast} from '../../../components/Toast/ToastMessage';
import { getAuthHeaders } from "../../../utils/GetHeaders";
import { REACT_APP_AUTH_SVC_API_BASE_URL } from '../../../utils/UrlConstants';
import { PasswordModalProps } from "./types";

const PasswordModal: React.FC<PasswordModalProps> = ({
  lgShow,
  setLgShow,
}) => {
  const [formPasswordData, setFormPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const validateForm = () => {
    const { oldPassword, newPassword, confirmPassword } = formPasswordData;
    if (!oldPassword || !newPassword || !confirmPassword) {
      return false;
    }
    return true;
  };

  const handlePasswordChange = (e :any) => {
    const { id, value } = e.target;
    setFormPasswordData(prevState => ({
        ...prevState,
        [id]: value
    }));
  };

  const handleSubmit = async (e :any) => {
    e.preventDefault();

    if (validateForm()){
      try {
        const url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/password/update`;
        const response :any = await axios.put(url, formPasswordData, {
          headers: getAuthHeaders(),
        });
        if (response.status === 200) {
          localStorage.setItem("accessToken", response.headers.get('x-access-token'));
          localStorage.setItem("refreshToken", response.headers.get('x-refresh-token'));
          successToast("Password updated successfully!")
        } else {
          errorToast("Error while updating password!")
        }
      } catch (error :any) {
        errorToast(error.response.data.error)
      }
    } else{
      errorToast("Password fields should not be empty!");
    } 
  };

  return (
  <GenericModal
    cmp={PasswordModalBody}
    setShowModal={setLgShow}
    showModal={lgShow}
    onClickSubmit={handleSubmit}
    showFooter={true}
    title="Password Modal"
    submitButtonText="Change"
    cancelButtonText="Cancel"
    cmpProps={{
      setLgShow,
      handlePasswordChange,
      lgShow,
      formPasswordData,
    }}
  />
  )
}

export default PasswordModal;
