import React, { createContext, useState, useContext } from "react";
import { GlobalStateType, GlobalProviderProps } from "@/src/lib/types";

const GlobalContext = createContext<GlobalStateType | undefined>(undefined);

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [searchString, setSearchString] = useState<string>("");
  const [truckSearch, setTruckSearch] = useState<string>("");
  const [searchByLocation, setSearchByLocation] = useState<string>("");
  const [sideBarToggle, setSideBarToggle] = useState<boolean>(false);
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openForgot, setOpenForgot] = useState<boolean>(false);

  return (
    <GlobalContext.Provider
      value={{
        setSearchString,
        setTruckSearch,
        setSideBarToggle,
        setOpenLogin,
        setSearchByLocation,
        setOpenForgot,
        searchString,
        truckSearch,
        searchByLocation,
        sideBarToggle,
        openLogin,
        openForgot,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
