import AddTruckApplyToRent from "./AddTruckApplyToRent";
import AddTruckTypeAndDetails from "./AddTruckTypeAndDetails";
import AddTruckSpecs from "./AddTruckSpecs";
import AddTruckExpirationDate from "./AddTruckExpirationDate";
import AddtruckExpirationDate2 from "./AddTruckExpirationDate2";
import stylesPayment from "./css/AddTruckPrice.module.css";
import AddTruckPrice from "./AddTruckPrice";
import { useSelector } from "react-redux";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51QjoT3ImktpGeG5l3a0ofIBZRTNBsIV4NnsjgOgaVy0WMw2AeykXAzF3AQk5Xwoihq9d8NwhAGvpdLqll9hVs0b600Ml6FDBX6"
);

const TruckAddPreview = (props: any) => {
  const navigate = useNavigate();

  const truckAddRegistrationDataRedux = useSelector((state: any) => state);
  const [currentStepPayment, setCurrentStepPayment] = useState(1);

  const handleNextStepPayment = () => setCurrentStepPayment(2);
  const handlePreviousStepPayment = () => setCurrentStepPayment(1);

  const handleNextClick = async () => {
    let modifiedTruckData = truckFieldsFilter(
      truckAddRegistrationDataRedux.truckAddRegistrationData
    );

    const body = JSON.stringify(modifiedTruckData);

    if (!props.idToUpdate) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_TRUCK_API_BASE_URL}/trucks/register`,
          {
            method: "POST",
            headers: {
              ...getAuthHeaders(),
              "Content-Type": "application/json",
            },
            body: body,
          }
        );

        if (!response.ok) {
          const errorData = await response.json();

          const errorMessage = errorData.error || "Failed to upload";
          errorToast(errorMessage);

          return;
        }

        const data = await response.json();
        successToast("Upload successful");
        console.log("Success:", data);
        props.onNextButtonClick();
      } catch (error) {
        errorToast("An unexpected error occurred. Please try again.");
        console.error("Error caught:", error);
      }
    } else {
      try {
        const updatedData = { ...props?.idToUpdate };
        delete updatedData?._id;
        delete updatedData?.__v;

        console.log(
          truckAddRegistrationDataRedux?.truckAddRegistrationData?.enableForRent
        );
        console.log(props?.idToUpdate?.enableForRent);

        console.log("update body ^");
        let updateBody = {
          deposit:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.deposit ||
            props?.idToUpdate?.deposit,
          equipmentPrice:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.equipmentPrice || props?.idToUpdate?.equipmentPrice,
          insurancePrice:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.insurancePrice || props?.idToUpdate?.insurancePrice,
          taxes:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.taxes ||
            props?.idToUpdate?.taxes,
          city:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.city ||
            props?.idToUpdate?.city,
          state:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.state ||
            props?.idToUpdate?.state,
          zipCode:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.zipCode ||
            props?.idToUpdate?.zipCode,
          enableForRent:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.enableForRent || props?.idToUpdate?.enableForRent,
          enableForLease:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.enableForLease || props?.idToUpdate?.enableForLease,
          enableForLeaseForPurchase:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.enableForLeaseForPurchase ||
            props?.idToUpdate?.enableForLeaseForPurchase,
          rentPerDay:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.rentPerDay || props?.idToUpdate?.rentPerDay,
          rentPrepaidMileage:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.rentPrepaidMileage || props?.idToUpdate?.rentPrepaidMileage,

          leasePerDay:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.leasePerDay || props?.idToUpdate?.leasePerDay,
          leasePrepaidMileage:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.leasePrepaidMileage || props?.idToUpdate?.leasePrepaidMileage,
          rentPriceType:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.rentPriceType || props?.idToUpdate?.rentPriceType,
          leasePriceType:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.leasePriceType || props?.idToUpdate?.leasePriceType,

          LeaseForPurchase: {
            description:
              truckAddRegistrationDataRedux.truckAddRegistrationData
                ?.LeaseForPurchase?.description ||
              props?.idToUpdate?.leaseForPurchase?.description,
            numberOfWeeks:
              truckAddRegistrationDataRedux?.truckAddRegistrationData
                ?.LeaseForPurchase?.numberOfWeeks ||
              props?.idToUpdate?.leaseForPurchase?.numberOfWeeks,
            pricePerWeek:
              truckAddRegistrationDataRedux?.truckAddRegistrationData
                ?.LeaseForPurchase?.pricePerWeek ||
              props?.idToUpdate?.leaseForPurchase?.pricePerWeek,
          },
          plateNumber:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.plateNumber || props?.idToUpdate?.plateNumber,
          GPS: truckAddRegistrationDataRedux?.truckAddRegistrationData?.GPS,
          description:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.description || props?.idToUpdate?.description,
          plateState:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.plateState || props?.idToUpdate?.plateState,
          engine:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.engine ||
            props?.idToUpdate?.engine,
          VIN:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.VIN ||
            props?.idToUpdate?.VIN,
          vehicleType:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.vehicleType || props?.idToUpdate?.vehicleType,
          vehicleSubtype:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.vehicleSubtype || props?.idToUpdate?.vehicleSubtype,
          manufacturer:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.manufacturer || props?.idToUpdate?.manufacturer,
          model:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.model ||
            props?.idToUpdate?.model,
          year:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.year ||
            props?.idToUpdate?.year,
          mileage:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.mileage ||
            props?.idToUpdate?.mileage,
          doorType:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.doorType ||
            props?.idToUpdate?.doorType,
          length: {
            feet:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.length
                ?.feet || props?.idToUpdate?.length?.feet,
          },
          cargoSecurementTrack:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.cargoSecurementTrack || props?.idToUpdate?.cargoSecurementTrack,
          dockHeight:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.dockHeight || props?.idToUpdate?.dockHeight,
          allowTravelOutOfState:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.allowTravelOutOfState,
          transmissionType:
            truckAddRegistrationDataRedux?.truckAddRegistrationData
              ?.transmissionType || props?.idToUpdate?.transmissionType,
          chassis:
            truckAddRegistrationDataRedux?.truckAddRegistrationData?.chassis ||
            props?.idToUpdate?.chassis,
          files: {
            vehicleRegistrationFile:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.files
                ?.vehicleRegistrationFile ||
              props?.idToUpdate?.files?.vehicleRegistrationFile,
            technicalInspectionFile:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.files
                ?.technicalInspectionFile ||
              props?.idToUpdate?.files?.technicalInspectionFile,
            vehicleInsuranceFile:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.files
                ?.vehicleInsuranceFile ||
              props?.idToUpdate?.files?.vehicleInsuranceFile,
          },

          images: {
            threeQuarterView:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.images
                ?.threeQuarterView ||
              props?.idToUpdate?.images?.threeQuarterView,
            front:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.images
                ?.front || props?.idToUpdate?.images?.front,
            passengerSide:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.images
                ?.passengerSide || props?.idToUpdate?.images?.passengerSide,
            back:
              truckAddRegistrationDataRedux?.truckAddRegistrationData?.images
                ?.back || props?.idToUpdate?.images?.back,
          },
        };

        let modifiedTruckData = truckFieldsFilter(updateBody);

        const response = await fetch(
          `${process.env.REACT_APP_TRUCK_API_BASE_URL}/trucks/${props?.idToUpdate?._id}`,
          {
            method: "PUT",
            headers: {
              ...getAuthHeaders(),
              "Content-Type": "application/json",
            },
            body: JSON.stringify(modifiedTruckData),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.error || "Failed to update truck";
          errorToast(errorMessage);
          return;
        }
        successToast("Truck updated successfully");
        navigate("/admin");
      } catch (error: any) {
        console.log(error);
        errorToast(`${error.message}`);
      }
    }
  };

  const truckFieldsFilter = (data: any) => {
    const { vehicleType, vehicleSubtype, ...truckData } = data;

    let modifiedTruckData = { vehicleType, vehicleSubtype, ...truckData };

    if (vehicleType === "trailer" || vehicleType === "truck") {
      delete modifiedTruckData.dockHeight;
    }

    if (vehicleType != "trailer" && vehicleType != "boxtruck") {
      delete modifiedTruckData.length;
    }

    if (
      !(
        vehicleSubtype.includes("boxtruck") ||
        vehicleSubtype.includes("dry van") ||
        vehicleSubtype.includes("reefer") ||
        vehicleSubtype.includes("box truck") ||
        vehicleSubtype.includes("dryvan")
      )
    ) {
      delete modifiedTruckData.doorType;
    }
    return modifiedTruckData;
  };

  return (
    <>
      {currentStepPayment === 1 && (
        <div>
          <AddTruckApplyToRent
            handleUpdateTruckRegistrationData={
              props.handleUpdateTruckRegistrationData
            }
            setPlateNumber={props.setPlateNumber}
            setInputValue={props.setInputValue}
            setPlateState={props.setPlateState}
            setVINNumber={props.setVINNumber}
            setCity={props.setCity}
            setState={props.setState}
            setZipCode={props.setZipCode}
            city={props.city}
            state={props.state}
            zipCode={props.zipCode}
            inputValue={props.inputValue}
            plateNumber={props.plateNumber}
            truckRegData={props.registrationData}
            plateState={props.plateState}
            VINNumber={props.VINNumber}
            onNextButtonClick={props.handleNextButtonClick}
            onBackButtonClick={props.handleBackButtonClick}
            idToUpdate={props.idToUpdate}
            currentStep={props.currentStep}
            isOnPreview={true}
          />

          <AddTruckTypeAndDetails
            setPlateState={props.setPlateState}
            setPlateNumber={props.setPlateNumber}
            setModel={props.setModel}
            setEngine={props.setEngine}
            setYear={props.setYear}
            setYearValue={props.setYearValue}
            setPlateStateValue={props.setPlateStateValue}
            setMileage={props.setMileage}
            setSelectedTruckSubtype={props.setSelectedTruckSubtype}
            setSelectedTruckSubtype2={props.setSelectedTruckSubtype2}
            setManufacturerValue={props.setManufacturerValue}
            setManufaturer={props.setManufaturer}
            setEngineValue={props.setEngineValue}
            engineValue={props.engineValue}
            manufacturer={props.manufacturer}
            manufacturerValue={props.manufacturerValue}
            plateState={props.plateState}
            plateNumber={props.plateNumber}
            model={props.model}
            engine={props.engine}
            year={props.year}
            yearValue={props.yearValue}
            plateStateValue={props.plateStateValue}
            mileage={props.mileage}
            selectedTruckSubtype={props.selectedTruckSubtype}
            selectedTruckSubtype2={props.selectedTruckSubtype2}
            handleUpdateTruckRegistrationData={
              props.handleUpdateTruckRegistrationData
            }
            truckRegData={props.registrationData}
            onNextButtonClick={props.handleNextButtonClick}
            onBackButtonClick={props.handleBackButtonClick}
            idToUpdate={props.idToUpdate}
            currentStep={props.currentStep}
          />

          <AddTruckSpecs
            handleUpdateTruckRegistrationData={
              props.handleUpdateTruckRegistrationData
            }
            truckRegData={props.registrationData}
            onNextButtonClick={props.handleNextButtonClick}
            onBackButtonClick={props.handleBackButtonClick}
            idToUpdate={props.idToUpdate}
            doorType={props.doorType}
            setDoorType={props.setDoorType}
            liftGateType={props.liftGateType}
            setLiftGateType={props.setLiftGateType}
            dockHeight={props.dockHeight}
            setDockHeight={props.setDockHeight}
            allowTravelOutOfState={props.allowTravelOutOfState}
            setAllowTravelOutOfState={props.setAllowTravelOutOfState}
            selectedTruckSubtype2={props.selectedTruckSubtype2}
            selectedTruckSubtype={props.selectedTruckSubtype}
            gps={props.gps}
            setGps={props.setGps}
            chassis={props.chassis}
            setChassis={props.setChassis}
            transmissionType={props.transmissionType}
            setTransmissionType={props.setTransmissionType}
            lengthValue={props.lengthValue}
            setLengthValue={props.setLengthValue}
            cargoValue={props.cargoValue}
            setCargoValue={props.setCargoValue}
            cargoSecurementTrack={props.cargoSecurementTrack}
            setCargoSecurementTrack={props.setCargoSecurementTrack}
            currentStep={props.currentStep}
          />

          <AddTruckExpirationDate
            handleUpdateTruckRegistrationData={
              props.handleUpdateTruckRegistrationData
            }
            fileNames={props.fileNames}
            setFileNames={props.setFileNames}
            setDescription={props.setDescription}
            description={props.description}
            truckRegData={props.registrationData}
            onNextButtonClick={props.handleNextButtonClick}
            onBackButtonClick={props.handleBackButtonClick}
            idToUpdate={props.idToUpdate}
            currentStep={props.currentStep}
          />

          <AddtruckExpirationDate2
            handleUpdateTruckRegistrationData={
              props.handleUpdateTruckRegistrationData
            }
            setFileNames={props.setFileNames}
            fileNames={props.fileNames}
            setStartDate={props.setStartDate}
            setEndDate={props.setEndDate}
            setInsurranceStartDate={props.setInsurranceStartDate}
            setInsurranceEndDate={props.setInsurranceEndDate}
            setinspectionDate={props.setinspectionDate}
            startDate={props.startDate}
            endDate={props.endDate}
            insurranceStartDate={props.insurranceStartDate}
            insurranceEndDate={props.insurranceEndDate}
            inspectionDate={props.inspectionDate}
            truckRegData={props.registrationData}
            onNextButtonClick={props.handleNextButtonClick}
            onBackButtonClick={props.handleBackButtonClick}
            idToUpdate={props.idToUpdate}
            currentStep={props.currentStep}
          />

          <AddTruckPrice
            handleUpdateTruckRegistrationData={
              props.handleUpdateTruckRegistrationData
            }
            setCostRentPerDay={props.setCostRentPerDay}
            setPrepaidMileage={props.setPrepaidMileage}
            setLeaseCostRentPerDay={props.setLeaseCostRentPerDay}
            setLeasePrepaidMileage={props.setLeasePrepaidMileage}
            setDeposite={props.setDeposite}
            setEquipementPrice={props.setEquipementPrice}
            setInsurrancePrice={props.setInsurrancePrice}
            setTaxes={props.setTaxes}
            setEnableForLease={props.setEnableForLease}
            setEnableForRent={props.setEnableForRent}
            setLeaseForPurchase={props.setLeaseForPurchase}
            costRentPerDay={props.costRentPerDay}
            prepaidMileage={props.prepaidMileage}
            leaseCostRentPerDay={props.leaseCostRentPerDay}
            leasePrepaidMileage={props.leasePrepaidMileage}
            leaseForPurchase={props.leaseForPurchase}
            deposite={props.deposite}
            equipementPrice={props.equipementPrice}
            insurrancePrice={props.insurrancePrice}
            taxes={props.taxes}
            enableForLease={props.enableForLease}
            enableForRent={props.enableForRent}
            truckRegData={props.registrationData}
            onNextButtonClick={props.handleNextButtonClick}
            onBackButtonClick={props.handleBackButtonClick}
            idToUpdate={props.idToUpdate}
            currentStep={props.currentStep}
            setLeaseForPurchasePrice={props.setLeaseForPurchasePrice}
            setLeaseForPurchaseWeeks={props.setLeaseForPurchaseWeeks}
            setTruckDescription={props.setTruckDescription}
            leaseForPurchasePrice={props.leaseForPurchasePrice}
            leaseForPurchaseWeeks={props.leaseForPurchaseWeeks}
            truckDescription={props.truckDescription}
            handleNextClick={handleNextClick}
            handleToPayment={handleNextStepPayment}
          />
        </div>
      )}
      {currentStepPayment === 2 && (
        <div
          style={{ width: "50%", margin: "auto", justifyContent: "flex-end" }}
        >
          <div className={stylesPayment.inputWithLabel2}>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
          <div className={`${stylesPayment.buttonContainer} mb-4`}>
            <div className={stylesPayment.buttonParent1}>
              <div
                onClick={handlePreviousStepPayment}
                className={`${stylesPayment.button5} hoverButtons`}
                // onClick={stylesPayment != 8 ? handleNextStep : handleToPayment}
              >
                <div className={stylesPayment.text6}>Back</div>
              </div>
            </div>
            <div className={stylesPayment.buttonParent1}>
              <div
                className={`${stylesPayment.button6} hoverButtons`}
                // onClick={stylesPayment != 8 ? handleNextStep : handleToPayment}
              >
                <div className={stylesPayment.text6}>Finish</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card Element not found");
      setLoading(false);
      return;
    }

    // Create a PaymentMethod object
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
      setError(error.message || "An unexpected error occurred");
    } else {
      console.log("PaymentMethod:", paymentMethod);
      setError("");
      setSuccess(true);
    }

    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ maxWidth: "400px", margin: "0 auto" }}
    >
      <h2>Test Stripe Payment</h2>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "4px",
          marginBottom: "10px",
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": { color: "#aab7c4" },
              },
              invalid: { color: "#9e2146" },
            },
          }}
        />
      </div>
      {error && (
        <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>
      )}
      {success && (
        <div style={{ color: "green", marginBottom: "10px" }}>
          PaymentMethod created successfully!
        </div>
      )}
      <button
        type="submit"
        disabled={!stripe || loading}
        style={{
          backgroundColor: "#6772e5",
          color: "white",
          border: "none",
          padding: "10px 15px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        {loading ? "Processing..." : "Submit Payment"}
      </button>
    </form>
  );
};

export default TruckAddPreview;
