import React, { useEffect, useState } from "react";
import "react-international-phone/style.css";
import styles from "./Profile.module.css";
import axios from "axios";
import PasswordModal from "./PasswordModal";
import AddOrganizationModal from "./AddOrganizationModal";
import Footer from "../../Add_Truck/Footer";
import AdminNavBar from "../AdminNavBar";
import AdminSidebar from "../AdminSidebar";
import { PhoneInput } from "react-international-phone";
import { parsePhoneNumber } from "libphonenumber-js";
import { useSelector } from "react-redux";
import { getAuthHeaders } from "../../../utils/GetHeaders";
import { useGlobalContext } from "../../../components/Global/GlobalState";
import { successToast, errorToast } from "../../../components/Toast/ToastMessage";
import { REACT_APP_AUTH_SVC_API_BASE_URL, REACT_APP_OTP_API_BASE_URL } from "../../../utils/UrlConstants";

const Profile: React.FC = () => {
  const moment = require("moment");
  const reduxState = useSelector((state: any) => state);
  const currentUser = useSelector((state: any) => state.currentUser);
  const [user] = useState<any>(currentUser);
  const [userCreatedAt] = useState<any>(currentUser.createdAt);
  const [lgShow, setLgShow] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [lgOrgShow, setLgOrgShow] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(currentUser.phone);
  const [emailConfirmation, setEmailConfirmation] = useState<boolean | null>(null);
  const [showOTPField, setShowOTPField] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [buttonStyle, setButtonStyle] = useState<string>(styles.button3);

  const [formData, setFormData] = useState({
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    phone: phone,
    email: currentUser.email,
  });

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      if (response.data.user.emailVerification.active !== true) {
        setEmailConfirmation(false);
      }
    } catch (error) {
      console.error(error, error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handlePhoneNumber = (phone: string) => {
    setFormData((prevState) => ({
      ...prevState,
      ["phone"]: phone,
    }));
    setPhone(phone);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const phoneNumber = parsePhoneNumber(phone, "US");
      if (phoneNumber.isValid()) {
        setIsValid(true);
      } else {
        setIsValid(false);
        return;
      }
      if (phoneNumber.country != "US") {
        errorToast("Phone number should only for US");
        return;
      }
    } catch (error) {
      setIsValid(false);
      return;
    }

    try {
      const url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me/update`;
      const response: any = await axios.put(url, formData, {
        headers: getAuthHeaders(),
      });
      if (response.status === 200) {
        localStorage.setItem("currentUserUpdated", "true");
        successToast("User data updated successfully!");
      } else {
        errorToast("Error while updating user!");
      }
    } catch (error) {
      errorToast("Error while updating user!");
    }
  };

  const handleSendCodeClick = async () => {
    try {
      const email = reduxState.registrationData.email;
      const apiUrl = `${REACT_APP_OTP_API_BASE_URL}/otp/send/email`;

      const requestBody = {
        email: email,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        errorToast("Incorrect One Time Password");
        console.error("Login failed", response);
        return false;
      }

      const data = await response.json();
      if (data.exists === false) {
        errorToast("Email does not exist");
      } else if (data.exists === true) {
        errorToast("Email already exists");
      } else {
        console.log("Success:", data);
        setShowOTPField(true);
      }
    } catch (error) {
      errorToast(`${error}`);
      console.error("Error:", error);
    }
  };

  const handleConfirmEmail = async () => {
    try {
      const apiUrl = `${REACT_APP_OTP_API_BASE_URL}/otp/verify/email`;

      const requestBody = {
        otp: inputValue,
      };

      console.log(inputValue);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          ...getAuthHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (!response.ok) {
        return response.json().then((data) => {
          errorToast(`Bad request: ${data.error}`);
        });
      }

      if (data.exists === false) {
        errorToast("Email does not exist");
      } else if (data.exists === true) {
        errorToast("Email already exists");
      } else {
        successToast("Email verified successfully");
        window.location.reload();
      }
    } catch (error) {
      errorToast(`${error}`);
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;
    setInputValue(formattedValue);
    if (/^\d-\d-\d-\d-\d$/.test(formattedValue)) {
      setButtonStyle(styles.button1);
    } else {
      setButtonStyle(styles.button3);
    }
  };

  const { sideBarToggle, setSideBarToggle } = useGlobalContext();

  return (
    <>
      <div>
        <div className={styles.adminNavBar}>
          <AdminNavBar />
        </div>
        <div className="d-flex justify-content-between" style={{ width: "100%", height: "100vh" }}>
          <div className={styles.adminSideBar}>
            <AdminSidebar />
          </div>
          <div className="d-flex flex-column align-items-center overflow-auto w-100 py-3 gap-4">
            <select
              className={styles.tabsDropdown}
              name="deactivateReason"
              id="deactivateReason"
            >
              <option value="reason1">Account</option>
              <option value="reason1">Notifications</option>
            </select>
            <div className={styles.container}>
              <main className={styles.content}>
                <section className={styles.profile}>
                  <div
                    className={styles.profileHeader}
                    style={{ marginBottom: 0 }}
                  >
                    <div className={styles.profileInfo}>
                      <b>
                        <h3 style={{ marginBottom: 0 }}>Profile</h3>
                      </b>
                    </div>
                  </div>
                  <div className={`${styles.divider} mt-3 mb-3`}></div>
                  <div className={styles.profileHeader}>
                    <div className={styles.profilePhoto}>JS</div>
                    <div className={styles.profileInfo}>
                      <span>
                        {user.firstName} {user.lastName}
                      </span>
                      <span className={styles.dot}></span>
                      <span>
                        Joined {moment(userCreatedAt).format("MMM D, YYYY")}
                      </span>
                      <br />
                      <span className={styles.changePhotoTop}>
                        Change photo &nbsp;
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.85638 6.5C6.31398 5.52901 7.54869 4.83333 9.00004 4.83333C10.841 4.83333 12.3334 5.95262 12.3334 7.33333C12.3334 8.49953 11.2687 9.47923 9.82856 9.7555C9.37657 9.84221 9.00004 10.2064 9.00004 10.6667M9 13.1667H9.00833M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                            stroke="#94A3B8"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  {emailConfirmation === false && (
                    <div>
                      <div
                        className="alert alert-warning"
                        role="alert"
                        style={{ width: "40%" }}
                      >
                        Your email has not been verified. Please verify your
                        email.
                      </div>

                      <form className={styles.profileForm}>
                        <div className={`${styles.formGroup} row`}>
                          <input
                            style={{ marginLeft: "10px" }}
                            className={`${styles.inpfutField} col-8`}
                            type="text"
                            id="email"
                            value={
                              formData.email ? formData.email : "Enter e-mail"
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                      <button
                        type="submit"
                        className={`${styles.btn} ${styles.saveChangesBtn} d-flex`}
                        onClick={handleSendCodeClick}
                      >
                        Send Confirmation
                      </button>
                      {showOTPField && (
                        <div
                          className={styles.profileForm}
                          style={{ gap: "10px" }}
                        >
                          <span className="d-flex">
                            Please, check your email
                          </span>
                          <div className={`${styles.formGroup} row`}>
                            {" "}
                            <input
                              type="text"
                              className={styles.inputField}
                              style={{ marginLeft: "10px", marginTop: "10px" }}
                              placeholder="0-0-0-0-0"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <button
                              type="submit"
                              style={{
                                width: "fit-content",
                                marginTop: "10px",
                                marginLeft: "10px",
                              }}
                              className={`${styles.btn} ${styles.saveChangesBtn} d-flex`}
                              onClick={handleConfirmEmail}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className={`${styles.divider} mt-5 mb-5`}></div>
                  <form className={styles.profileForm} onSubmit={handleSubmit}>
                    <div className={`${styles.formGroup} row`}>
                      <label className="col-4" htmlFor="firstName">
                        First Name
                      </label>
                      <input
                        className={`${styles.inputField} col-8`}
                        type="text"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={`${styles.formGroup} row`}>
                      <label className="col-4" htmlFor="lastName">
                        Last Name
                      </label>
                      <input
                        className={`${styles.inputField} col-8`}
                        type="text"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={`${styles.formGroup} row`}>
                      <label className="col-4" htmlFor="phone">
                        Phone number
                      </label>

                      <PhoneInput
                        className={`${styles.inputField} col-8`}
                        value={phone}
                        defaultCountry="US"
                        disableDialCodeAndPrefix={true}
                        onChange={(phone: string) => {
                          handlePhoneNumber(phone);
                        }}
                        inputStyle={{
                          width: "80%",
                          fontSize: "16px",
                          border:"none",
                          backgroundColor: "transparent",
                          display:"flex",
                          alignItems:"center",
                          padding:"7px 0px 0px 0px"
                        }}
                      />
                      {!isValid && (
                        <p style={{ color: "red", marginLeft: "7%" }}>
                          Please add a valid US phone
                        </p>
                      )}
                    </div>
                    <div
                      className={`${styles.formGroup} ${styles.inputSVGWraper} row`}
                    >
                      <label className="col-4" htmlFor="email">
                        E-mail
                      </label>
                      <input
                        className={`${styles.inputField} col-7 cursor-pointer`}
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <svg
                        className="col-1 offset-7"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6935 3.36019L14.6398 6.30647M12.9435 2.11019C13.7571 1.2966 15.0762 1.2966 15.8898 2.11019C16.7034 2.92379 16.7034 4.24288 15.8898 5.05647L4.41667 16.5296H1.5V13.5537L12.9435 2.11019Z"
                          stroke="#94A3B8"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className={`${styles.formGroup} row`}>
                      <label className="col-4"></label>
                      <button
                        type="submit"
                        className={`${styles.btn} ${styles.saveChangesBtn} col-2`}
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                  <div className={`${styles.divider} mt-5 mb-5`}></div>
                  <div className={`row`}>
                    <div className={`${styles.modalButtonLabel} col-4`}>
                      Password Update:
                    </div>
                    <button
                      className={`${styles.btn} ${styles.btnLight} col-3`}
                      onClick={() => setLgShow(true)}
                    >
                      Change Password
                    </button>
                  </div>
                  <div className={`${styles.divider} mt-5 mb-5`}></div>
                  <div className="row">
                    <div className={`${styles.modalButtonLabel} col-4`}>
                      Switch to company account:
                    </div>
                    <div className={`${styles.modalButtonRight} col-6`}>
                      <div
                        className={`${styles.modalButtinContainerText} mb-3`}
                      >
                        You will need to add data about your company to our
                        registry, after which there will be a confirmation
                        procedure for the company after verification by
                        moderators
                      </div>
                      <button
                        className={`${styles.btn} ${styles.btnLight} ${styles.addCompanyBtn}`}
                        onClick={() => setLgOrgShow(true)}
                      >
                        Add company
                      </button>
                    </div>
                  </div>
                  <div className={`${styles.divider} mt-5 mb-5`}></div>
                  <div className={`row mb-3`}>
                    <div className={`${styles.modalButtonLabel} col-4`}>
                      Account deactivation:
                    </div>
                    <div className={`${styles.modalButtonRight} col-6`}>
                      <div
                        className={`${styles.modalButtinContainerText} mb-3`}
                      >
                        <span>
                          What happens when you deactivate your account?
                        </span>
                        <ul className="mt-4">
                          <li>
                            Your profile and Gigs won't be shown on Fiverr
                            anymore.
                          </li>
                          <li>Active orders will be cancelled.</li>
                          <li>You won't be able to re-activate your Gigs.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.row} row`}>
                    <div className={`${styles.modalButtonLabel} col-4`}>
                      I'm leaving because...
                    </div>
                    <div className={`${styles.modalButtonRight} col-6`}>
                      <div>
                        <select
                          className={`${styles.deactivateReason} mb-3`}
                          name="deactivateReason"
                          id="deactivateReason"
                        >
                          <option disabled>Choose a reason</option>
                          <option value="reason1">Reason 1</option>
                          <option value="reason2">Reason 2</option>
                          <option value="reason3">Reason 3</option>
                        </select>
                      </div>
                      <button className={`${styles.btn} ${styles.btnRight}`}>
                        Deactivate account
                      </button>
                    </div>
                  </div>
                  <div className={`${styles.divider} mt-5 mb-5`}></div>
                  <div className="row">
                    <div className={`${styles.modalButtonLabel} col-4`}></div>
                    <div className={`${styles.modalButtonRight} col-6`}>
                      <div>
                        <div className="mb-3 col-8"></div>
                      </div>
                      <button
                        className={`${styles.btn} ${styles.btnLight} ${styles.btnRight} ${styles.btnMobile}`}
                      >
                        Restore account
                      </button>
                      <button
                        className={`${styles.btn} ${styles.btnLight} ${styles.btnRight} ${styles.btnDisabled} ${styles.btnMobile} mx-3`}
                      >
                        28 days for delete
                      </button>
                    </div>
                  </div>
                </section>
              </main>
              <div></div>
            </div>
          </div>
        </div>
        <Footer />
        <PasswordModal lgShow={lgShow} setLgShow={setLgShow} />
        <AddOrganizationModal
          lgOrgShow={lgOrgShow}
          setLgOrgShow={setLgOrgShow}
        />
      </div>
    </>
  );
};

export default Profile;
