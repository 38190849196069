import { useState, useEffect } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import styles from "./css/UserAdministration.module.css";
import AdminSidebar from "../../pages/Admin/AdminSidebar";
import AdminNavBar from "./AdminNavBar";
import axios from "axios";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Loader from "../../components/Loader/Loader";
import AddNewUser from "./addNewUser/AddNewUser";
import GenericModal from "../../components/Modal/GenericModal";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import {Link, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { Column } from "./types";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_ORG_API_BASE_URL } from "../../utils/UrlConstants";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";

const OrganizationsManagement: React.FC = () => {
  const moment = require("moment");
  const currentUser = useSelector((state: any) => state.currentUser);
  const [loading, setloading] = useState<boolean>(true);
  const [rows, setRows] = useState<{ [key: string]: string }[]>([{}]);
  const [filteredRows, setFilteredRows] = useState<{ [key: string]: string }[]>([{}]);
  const [filter, setFilter] = useState<string>("all");
  const [organizationsCount, setOrganizationsCount] = useState<number>(0);
  const [page, pageChange] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(5);
  const [open, setOpen] = useState<boolean>(false);
  const [blockedOrganizationsCount, setBlockedOrganizationsCount] = useState<number>(0);
const navigate = useNavigate();
  const columns: Column[] = [
    { id: "name", name: "NAME" },
    { id: "isBlocked", name: "STATUS" },
    { id: "role", name: "DOT" },
    { id: "email", name: "E-MAIL" },
    { id: "lastActive", name: "LAST ACTIVITY" },
    { id: "added", name: "ADDED" },
    { id: "delete", name: "" },
  ];

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleChangePage = (event: any, newPage: any) => {
    pageChange(newPage);
  };

  const handleRowsPerPage = (event: any) => {
    setRowPerPage(event.target.value);
    pageChange(0);
  };

  const fetchOrganizations = async () => {
    var url = `${REACT_APP_ORG_API_BASE_URL}/organizations`;

    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      let organizations = response.data.organizations;
      setOrganizationsCount(organizations.length);
      organizations = organizations.reverse();

      const formattedOrganizations = organizations.map((organization: any) => ({
        _id: organization._id,
        isBlocked: organization.isBlocked,
        name: organization.name,
        role: organization.dotNumber,
        email: organization.email,
        lastActive: organization.updatedAt,
        added: organization.createdAt,
      }));

      const blockedOrganizations = organizations.filter(
        (organization: any) => organization.isBlocked === true
      );

      setBlockedOrganizationsCount(blockedOrganizations.length);
      setRows(formattedOrganizations);
      setFilteredRows(formattedOrganizations);
      setloading(false);
    } catch (error) {
      setloading(false);
      errorToast("Error fetching organizations");
      console.error("Error fetching organizations:", error);
    }
  };

  const handleBlockCompany = async (id: string, value: string) => {
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;

    const newIsBlockedValue = value === "true" ? false : true;

    try {
      const response = await axios.patch(
        url,
        {
          isBlocked: newIsBlockedValue,
        },
        {
          headers: getAuthHeaders(),
        }
      );

      successToast(
        `Company status changed to ${newIsBlockedValue === false ? "active" : "blocked"
        }`
      );

      fetchOrganizations();
      console.log(response);
      console.log("Response");
    } catch (error) {
      errorToast("Error while blocking organization");
      console.error(error);
    }
  };

  const filterUsers = (filter: string) => {
    pageChange(0);
    let filteredData: any;
    switch (filter) {
      case "all":
        setFilteredRows(rows);
        setFilter("all");
        break;
      case "blocked":
        filteredData = rows.filter(
          (row) => row.isBlocked.toString() === "true"
        );
        setFilter("blocked");
        setFilteredRows(filteredData);
        break;
      case "unblocked":
        filteredData = rows.filter(
          (row) => row.isBlocked.toString() === "false"
        );
        setFilter("unblocked");
        setFilteredRows(filteredData);
        break;
      default:
        setFilteredRows(rows);
        setFilter("all");
        break;
    }
  };

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ width: "100%", height: "100vh" }}
       >
        <div className={styles.adminSideBar}>
          <AdminSidebar />
        </div>
        <div className={styles.userAdministration}>
          <div className={styles.textParent}>
            <div className="d-flex flex-wrap justify-content-between flex-container w-100">
              <div className={styles.text15}>
                <Link to="/admin">
                  <span>Settings / </span>
                </Link>
                <span className={styles.userAdministration1}>
                  Organizations Management
                </span>
              </div>
              <div className="ms-auto">
                <div
                  className={`${styles.button2} hoverButtons`}
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <div className={styles.text18}>
                    <PersonAddAltIcon /> Add new staff
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid bg-white rounded-3 py-3">
              <div className="row row-cols-1 row-cols-sm-2 g-0 py-2 px-2">
                <section className="d-flex">
                  <div className={styles.box}>
                    <div className={styles.creditsUsedParent}>
                      <div className={styles.creditsUsed}>Companies registered</div>
                    </div>
                    <span className="fs-5 lh-sm fw-semibold text-black">{organizationsCount} companies</span>
                  </div>
                </section>
                <section className="d-flex">
                  <div className={styles.box}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Blocked companies</span>
                      <img src="/admin/main/iconoutlinequestionmarkcircle1.svg" alt="" />
                    </div>
                    <span className="fs-5 lh-sm fw-semibold text-black">{blockedOrganizationsCount}</span>
                  </div>
                </section>
              </div>
            </div>
            <div className={styles.tabExampleLightDesktopParent}>
              <div className={styles.tabExampleLightDesktop}>
                <div className={styles.tabExampleLightDesktop1}>
                  <div className={styles.tabs}>
                    <div
                      className={styles.desktopTab1}
                      style={
                        filter === "all"
                          ? {
                            borderBottom: "2px solid #fa6f32",
                            color: "#fa6f32",
                          }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab2Wrapper}
                        onClick={() => {
                          filterUsers("all");
                        }}
                      >
                        <div className={styles.tab2}>All</div>
                      </div>
                      <div className={styles.desktopTabChild1} />
                    </div>
                    <div
                      className={styles.desktopTab1}
                      style={
                        filter === "blocked"
                          ? {
                            borderBottom: "2px solid #fa6f32",
                            color: "#fa6f32",
                          }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab2Wrapper}
                        onClick={() => {
                          filterUsers("blocked");
                        }}
                      >
                        <div className={styles.tab2}>Blocked</div>
                      </div>
                      <div className={styles.desktopTabItem} />
                    </div>
                    <div
                      className={styles.desktopTab1}
                      style={
                        filter === "unblocked"
                          ? {
                            borderBottom: "2px solid #fa6f32",
                            color: "#fa6f32",
                          }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab3Wrapper}
                        onClick={() => {
                          filterUsers("unblocked");
                        }}
                      >
                        <div className={styles.tab3}>Unblocked</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div className={styles.desktopTab3}>
                      <div className={styles.tab4Wrapper}>
                        <div className={styles.tab4}>On hold</div>
                      </div>
                      <div className={styles.desktopTabChild1} />
                    </div>
                    <div className={styles.desktopTab4}>
                      <div className={styles.tab5Wrapper}>
                        <div className={styles.tab5}>Hidden</div>
                      </div>
                      <div className={styles.desktopTabChild2} />
                    </div>
                    <div className={styles.desktopTab5}>
                      <div className={styles.tab6Wrapper}>
                        <div className={styles.tab6}>In rent</div>
                      </div>
                      <div className={styles.desktopTabChild3} />
                    </div>
                    <div className={styles.desktopTab6}>
                      <div className={styles.tab7Wrapper}>
                        <div className={styles.tab7}>History</div>
                      </div>
                      <div className={styles.desktopTabChild4} />
                    </div>
                  </div>
                  <div className={styles.underLine} />
                </div>
              </div>
              <div className={`${styles.frameDiv} ${styles.userTable}`}>
                {loading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  <>
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ minHeight: 450, maxHeight: 1000 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {columns.map((column, i) => (
                                <TableCell
                                  className={styles.tableHeaderCell}
                                  style={{
                                    backgroundColor: "#F8FAFC",
                                    fontWeight: "600",
                                    color: "gray",
                                    textAlign: "center",
                                    width: "20%",
                                  }}
                                  key={i}
                                >
                                  {column.name}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredRows &&
                              filteredRows
                                .slice(
                                  page * rowPerPage,
                                  page * rowPerPage + rowPerPage
                                )
                                .map((row, i) => {
                                  return (
                                    <TableRow
                                      key={i}
                                      className={styles.tableRow}
                                      style={{
                                        backgroundColor: row.active
                                          ? "red"
                                          : "white",
                                      }}
                                    >
                                      {columns &&
                                        columns.map((column, i) => {
                                          let value = row[column.id];
                                          return (
                                            <TableCell
                                              key={i}
                                              style={{
                                                textAlign: "center",
                                                height: "2vh",
                                              }}
                                            >
                                              {column.id === "name" && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace:"nowrap",
                                                    fontWeight:"bold",
                                                  }}
                                                  onClick={() =>
                                                    // window.open(
                                                    //   `/organization/${row._id}`,
                                                    //   "_blank"
                                                    // )
                                                    navigate(`/organization/${row._id}`)

                                                  }
                                                >
                                                  <CorporateFareIcon
                                                    sx={{ color: "#8C939D" }}
                                                  />
                                                  <div
                                                    style={{
                                                      textAlign: "left",

                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                      }}
                                                    >
                                                      &nbsp;{row.name}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                              {column.id === "isBlocked" && (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    height: "100%",
                                                    alignItems: "center",
                                                    padding:"0px",
                                                    color:
                                                      value.toString() ===
                                                        "false"
                                                        ? "green"
                                                        : "red",
                                                  }}
                                                  onClick={() =>
                                                    handleBlockCompany(
                                                      row._id,
                                                      value.toString()
                                                    )
                                                  }
                                                >
                                                  {value.toString() === "false"
                                                    ? "Active"
                                                    : "Blocked"}
                                                </span>
                                              )}
                                              {column.id !== "name" &&
                                                column.id !== "lastActive" &&
                                                column.id !== "added" && (
                                                  <span
                                                    onClick={
                                                      column.id === "delete"
                                                        ? () =>
                                                          console.log(
                                                            "delete user"
                                                          )
                                                        : // handleDeleteUser(
                                                        //   row._id
                                                        // )
                                                        currentUser.role ==
                                                          "admin" &&
                                                          column.id ===
                                                          "role" &&
                                                          value.includes(
                                                            " -- role switch requested"
                                                          )
                                                          ? () =>
                                                            console.log(
                                                              "role wtich"
                                                            )
                                                          : // handleRoleSwitch(
                                                          //   row._id
                                                          // )
                                                          undefined
                                                    }
                                                    style={{
                                                      borderRadius: "16px",
                                                      // padding: "4%",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      backgroundColor:
                                                        column.id == "role" &&
                                                          value &&
                                                          value.includes(
                                                            "employee"
                                                          )
                                                          ? "orange"
                                                          : column.id ==
                                                            "role" &&
                                                            value &&
                                                            value.includes(
                                                              "admin"
                                                            )
                                                            ? "#2bbdbd"
                                                            : column.id ==
                                                              "role" &&
                                                              value &&
                                                              value.includes(
                                                                "renter"
                                                              )
                                                              ? "#00BF74"
                                                              : column.id ==
                                                                "role" &&
                                                                value &&
                                                                value.includes(
                                                                  "driver"
                                                                )
                                                                ? "#FC9366"
                                                                : column.id ==
                                                                  "role" &&
                                                                  value &&
                                                                  value.includes(
                                                                    "owner"
                                                                  )
                                                                  ? "#8282e9"
                                                                  : "none",
                                                      color:
                                                        column.id === "delete"
                                                          ? "red"
                                                          : value === "employee"
                                                            ? "white"
                                                            : column.id ==
                                                              "role" &&
                                                              (value ===
                                                                "admin" ||
                                                                value.includes(
                                                                  "driver"
                                                                ) ||
                                                                value ===
                                                                "owner" ||
                                                                value ===
                                                                "renter")
                                                              ? "white"
                                                              : "inherit",
                                                    }}
                                                  >
                                                    {value}

                                                    {column.id === "delete" ? (
                                                      <DeleteForever />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                )}
                                              {column.id == "lastActive" && (
                                                <>
                                                  {moment(value).format(
                                                    "MMM D, YYYY"
                                                  )}
                                                </>
                                              )}
                                              {column.id == "added" && (
                                                <>
                                                  {moment(value).format(
                                                    "MMM D, YYYY"
                                                  )}
                                                </>
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        className={styles.tablePagination}
                        sx={{ display: "flex", alignItems: "baseline" }}
                        rowsPerPageOptions={[5, 10, 25]}
                        page={page}
                        rowsPerPage={rowPerPage}
                        component="div"
                        count={filteredRows.length}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPage}
                      ></TablePagination>
                    </Paper>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GenericModal
        cmp={AddNewUser}
        setShowModal={handleClose}
        showModal={open}
        onClickSubmit={handleClose}
        showFooter={false}
        title="Add User"
        cmpProps={{
          handleClose,
        }}
      />
    </>
  );
};

export default OrganizationsManagement;

const style = {
  position: "absolute",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "80vh",

  bgcolor: "#f8fafc",
  border: "1px solid #fff",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  "& > form > div": {
    width: "unset",
  },
  "& > form": {
    backgroundColor: "unset",
  },
  "& > div > div": {
    left: "0",
    width: "unset",
    margin: "auto",
  },
  "@media (max-width: 750px)": {
    width: "90%",
    maxHeight: "90vh",
    padding: "2rem",
  },
  "@media (max-width: 500px)": {
    width: "100%",
    maxHeight: "100vh",
    padding: "1rem",
  },
};
