import React, { ReactElement, useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { NoAuthRestrictedRouteProps } from "../../lib/types";

const NoAuthRestrictedRoute: React.FC<NoAuthRestrictedRouteProps> = ({
  cmp,
}: NoAuthRestrictedRouteProps): ReactElement => {
  const Cmp = cmp;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (accessToken && refreshToken) {
      navigate("/admin/truck_management");
    } else {
      setLoading(false);
    }
  }, [navigate]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Cmp />
        </>
      )}
    </div>
  );
};

export default NoAuthRestrictedRoute;
