import React, {useEffect, useRef, useState} from "react";
import styles from "./MapPage.module.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Box, Tab, Tabs} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/KeyboardArrowDown";
import {useGlobalContext} from "../../components/Global/GlobalState";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fa6f32",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {},
        scrollButtons: {
          "&:last-of-type": {
            backgroundColor: "white !important",
            boxShadow: "0px 10px 17px -11px rgba(0,0,0,0.75)",
            position: "absolute",
            right: "0",
            zIndex: "999",
            height: "50%",
            top: "25%",
            borderRadius: "360px",
            color: "white",
            "&:hover": {
              backgroundColor: "green",
              color: "white",
            },
          },
          "&:first-of-type": {
            backgroundColor: "white !important",
            boxShadow: "0px 10px 17px -11px rgba(0,0,0,0.75)",
            position: "absolute",
            height: "50%",
            top: "25%",
            zIndex: "999",
            borderRadius: "360px",
            color: "white",
            "&:hover": {
              backgroundColor: "green",
              color: "white",
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "black !important",
          fontSize: "32px",
        },
      },
    },
  },
});

interface TruckData {
  imgSrc: string;
  headline: string;
}

interface MapPageSelectorProps {
  selectedPrecategory: string;
  allTruckData: TruckData[];
  allTruckDataOnly: TruckData[];
  allTrailerDataOnly: TruckData[];
  selectedItem: string;
  handlePrecategory: (category: string) => void;
  handleItemClick: (category: string) => void;
  getSubCatTrucks: (subCategory: string) => void;
  getCatTrucks: (subCategory: string) => void;
  isResetSelected: boolean;
  selectedTruck: string | null;
  setSelectedTruck: (value: string) => void;
  setResetSelected:(value:boolean) => void;
}

const MapPageSelector: React.FC<MapPageSelectorProps> = ({
                                                           allTruckData,
                                                           allTruckDataOnly,
                                                           allTrailerDataOnly,
                                                           getSubCatTrucks,
                                                           isResetSelected,
                                                           selectedTruck,
                                                           setSelectedTruck,
                                                           setResetSelected
                                                         }) => {

  const {searchString, setSearchString, searchByLocation, setSearchByLocation} = useGlobalContext();

  const [value, setValue] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  // Determine if the menus are open based on anchorEl
  const open = Boolean(anchorEl) && anchorEl?.dataset.type === "0";
  const open2 = Boolean(anchorEl) && anchorEl?.dataset.type === "1";
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedCategory(type);
    event.currentTarget.dataset.type = type.toString();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setValue(null);
  }, [isResetSelected]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number | null
  ) => {
    setValue(newValue);
  };

  const tabIndicatorRef = useRef(null);
  const gradient = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar
        expand="lg"
        style={{
          background: "white !important",
          width: "84%",
          display: "flex",
          flexDirection: "row",
          padding: "0",
        }}
      >
        <>
          <Container style={{flexFlow: "nowrap"}}>
            <div id="containerLinks" style={{display: "flex", gap: "2vw"}}>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  width: "inherit",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event, 0)}
                >
                  <Tab
                    className={styles.truckListChoices}
                    style={{maxHeight: "0"}}
                    onClick={toggleDropdown}
                    icon={
                      <div>
                        <img
                          className={`${styles.image75Icon1}`}
                          src="./truck_types/17.svg"
                          alt="Truck Icon"
                        />
                        <span
                          className={`${styles.dropdownArrow} ${
                            open ? styles.arrowUp : styles.arrowDown
                          }`}
                        >
                          <ArrowBackIosIcon
                            style={{height: "20px", width: "20pxm"}}
                          />
                        </span>
                      </div>
                    }
                    label={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          textTransform: "capitalize",
                          fontWeight: 600,
                        }}
                      >
                        Truck
                      </div>
                    }
                  />
                </Button>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleClick(event, 1)}
                >
                  <Tab
                    className={styles.truckListChoices}
                    style={{maxHeight: "0"}}
                    // onClick={() => getCatTrucks("trailer")}
                    icon={
                      <div>
                        <img
                          className={`${styles.image75Icon1}`}
                          src="./truck_types/Trailer.svg"
                          alt="Truck Icon"
                        />
                        <span
                          className={`${styles.dropdownArrow} ${
                            open2 ? styles.arrowUp : styles.arrowDown
                          }`}
                        >
                          <ArrowBackIosIcon
                            style={{height: "20px", width: "20px"}}
                          />
                        </span>
                      </div>
                    }
                    label={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          textTransform: "capitalize",
                          fontWeight: 600,
                        }}
                      >
                        Trailer
                      </div>
                    }
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  style={{textTransform: "capitalize",zIndex:100000}}
                  open={open || open2}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {(selectedCategory == 0
                      ? allTruckDataOnly
                      : allTrailerDataOnly
                  ).map((truck: any, index: number) => (
                    <MenuItem
                      key={index}
                      style={{
                        gap: "2%",

                        backgroundColor:
                          selectedTruck === truck.headline
                            ? "#fb804c7a"
                            : "transparent",
                        color:
                          selectedTruck === truck.headline ? "white" : "black",
                      }}
                      onClick={() => {
                        getSubCatTrucks(truck.headline);
                        setSelectedTruck(truck.headline.toString());
                        setResetSelected(!isResetSelected);
                        setSearchString("");
                        setSearchByLocation("");
                        handleClose();
                      }}
                    >
                      <img
                        className={styles.image75Icon1}
                        src={truck.imgSrc}
                        alt="Truck Icon"
                      />

                      <span>{truck.headline}</span>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>

            <div className={styles.verticalDivider}></div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              style={{position: "absolute", right: "-1px", zIndex: 10}}
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{overflow: "auto", width: "auto"}}
            >
              <Nav className="me-auto" style={{width: "100%"}}>
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    width: "inherit",
                  }}
                >
                  <Tabs
                    ref={tabIndicatorRef}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    aria-label="scrollable auto tabs example"
                    indicatorColor="secondary"
                    textColor="primary"
                  >

                    {allTruckData.map((truck: any, index: number) => (
                      <Tab
                        key={index}
                        className={styles.truckListChoices}
                        style={{maxHeight: "0"}}
                        onClick={() => {
                          setSearchString("");
                          setSearchByLocation("");
                          setSelectedTruck("");
                          getSubCatTrucks(truck.headline)
                        }}
                        icon={
                          <img
                            className={styles.image75Icon1}
                            src={truck.imgSrc}
                            alt="Truck Icon"
                          />
                        }
                        label={
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100px",
                              textTransform: "capitalize",
                              fontWeight: 600,
                            }}
                          >
                            {truck.short}
                          </div>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
                <img
                  ref={gradient}
                  style={{
                    position: "absolute",
                    right: "-20px",
                    zIndex: "0",
                    pointerEvents: "none",
                  }}
                  src="./gradient.png"
                />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </>
      </Navbar>
    </ThemeProvider>
  );
};

export default MapPageSelector;
