import React, { useState, useEffect } from "react";
import Header from "./Header";

// import VehicleTypes from './VehicleTypes';
// import Features from './Features';
// import ProcessSection from './ProcessSection';
// import OwnerSection from './OwnerSection';
// import PlatformFeatures from './PlatformFeatures';
// import FAQSection from './FAQSection';
// import NewsletterSection from './NewsletterSection';
import styles from "./HomePage.module.css";
import HeroSection from "./HeroSection";
import PartnersSection from "./PartnersSection";
import VehicleTypes from "./VehicleTypes";
import MapPageNavbar from "../Map_Page/MapPageNavbar";
import Features from "./Features";
import Footer from "../Map_Page/Footer";
import ProcessSection from "./ProcessSection";
import OwnerSection from "./OwnerSection";
import PlatformFeatures from "./PlatformFeatures";
import { validateCurrentUser } from "../../components/Route/CheckAuth";
// import "react-joyride/lib/react-joyride-compiled.css";

const HomePage: React.FC = () => {
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (accessToken && refreshToken) {
      validateCurrentUser(accessToken, refreshToken)
        .then((validUser) => {
          console.log("validUser", validUser);
        })
        .catch((error) => {
          console.error("Error validating user:", error);
        });
    }
  }, []);

  return (
    <div className={styles.homePage}>
      <MapPageNavbar />
      <main>
        <HeroSection />
        {/* <PartnersSection /> */}
        <VehicleTypes />
        <Features />
        <ProcessSection />
        <OwnerSection />
        {/* <PlatformFeatures /> */}
        {/* <FAQSection />
        <NewsletterSection /> */}
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
