import React, { useEffect, useState } from "react";
import styles from "../../css/Registration_Driver/AddOrganizationVerification.module.css";
import { AddOrganizationDetails2Props } from "./types";

const AddOrganizationDetails2: React.FC<AddOrganizationDetails2Props> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {

  const [confirmationSent] = useState<boolean>(false);
  const [time, setTime] = useState<number>(80);
  const [expired, setExpired] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [buttonStyle, setButtonStyle] = useState(styles.button3);
  const [requiredField] = useState<boolean>(false);
  const [errorType] = useState<any>({});

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (confirmationSent) {
      timer = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
        } else {
          clearInterval(timer);
          setExpired(true);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [time, confirmationSent]);

  // FOR NOW, TO SKIP SMS VERIFICATION
  useEffect(() => {
    handleNextClick();
  }, []);

  const handleReset = () => {
    setTime(80);
    setExpired(false);
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value.replace(/[^0-9-]/g, "");

    let formattedValue = "";
    let digitCount = 0;

    for (let i = 0; i < sanitizedValue.length; i++) {
      if (digitCount === 5) {
        break;
      }

      if (sanitizedValue[i] === "-") {
        continue;
      }

      if (digitCount > 0) {
        formattedValue += "-";
      }

      formattedValue += sanitizedValue[i];
      digitCount++;
    }

    setInputValue(formattedValue);

    if (/^\d-\d-\d-\d-\d$/.test(formattedValue)) {
      setButtonStyle(styles.button1);
    } else {
      setButtonStyle(styles.button3);
    }
  };

  const handleNextClick = () => {
    onNextButtonClick?.();
  };

  return (
    <div className={styles.backButtonParent}>
      <div className={styles.textButton} style={{ cursor: "pointer" }} onClick={handleBackButtonClick}>
        <img className={styles.icon} alt="" src="/generics/icon5.svg" />
        <div className={styles.reefer}>Back</div>
      </div>
      <div className={styles.stepcircleWithTextParent}>
        <div className={styles.stepcircleWithText}>
          <div className={styles.stepcircle}>
            <img className={styles.icon} alt="" src="/generics/check1.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Sign up</div>
          </div>
        </div>
        <div className={styles.instanceChild} />
        <div className={styles.stepcircleWithText}>
          <div className={styles.stepcircle}>
            <img className={styles.icon} alt="" src="/generics/check1.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Company details</div>
          </div>
        </div>
        <div className={styles.instanceChild} />
        <div className={styles.stepcircleWithText2}>
          <div className={styles.stepcircle2}>
            <img className={styles.icon} alt="" src="/generics/check.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Verification</div>
          </div>
        </div>
      </div>
      <div className={styles.frameWrapper}>
        <div className={styles.textButtonGroup}>
          <div className={styles.frameParent}>
            <div className={styles.iconParent}>
              <img className={styles.icon} alt="" src="/generics/icon3.svg" />
              <div className={styles.userName}>Verification phone number</div>
            </div>
            <div className={styles.inputText3}>
              Thank you for registering on our website! We're excited to have
              you join our community. Before you can fully access your account
              and enjoy all the features, we kindly ask you to verify your
              phone number and e-mail address.
            </div>
            <img
              className={styles.groupChild}
              alt=""
              src="/generics/group-24561.svg"
            />
          </div>
          <div className={styles.frameChild} />
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText1}>
              <span>We well send you a security code by SMS to&nbsp;</span>
              <span className={styles.span}>+7-1111-44-44</span>
            </div>
            {confirmationSent ? (
              <div
                className={`${expired ? styles.button1 : styles.button2
                  } hoverButtons`}
                style={expired ? {} : { pointerEvents: "none" }}
              >
                <div
                  className={styles.text}
                  onKeyDown={() => { }}
                  role="button"
                  onClick={handleReset}
                >
                  Resend code confirmation
                  {expired
                    ? " 0:00"
                    : ` ${formatTime(minutes)}:${formatTime(seconds)}`}
                </div>
              </div>
            ) : (
              <div
                className={`${styles.button1} hoverButtons`}
                onKeyDown={() => { }}
                role="button"
              >
                <div className={styles.text}>Send code confirmation</div>
              </div>
            )}
            <div className={`errorBox ${requiredField ? "active" : ""}`}>
              Sorry,{" "}
              {errorType.exists === false
                ? "this email does not exist."
                : errorType.exists === true
                  ? "this email aready exists."
                  : "Something went wrong"}
            </div>
          </div>
          <div className={styles.frameChild} />
          <div className={styles.inputWithLabelParent}>
            <div className={styles.inputWithLabel}>
              <div className={styles.reefer}>Verification code</div>
              <div className={styles.basicInput}>
                <input
                  type="text"
                  className={styles.inputText6}
                  placeholder="0-0-0-0-0"
                  style={{ border: "none" }}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={`${buttonStyle} hoverButtons`}>
              <div
                className={styles.text}
                onKeyDown={() => { }}
                role="button"
                onClick={handleNextClick}
              >
                Apply
              </div>
            </div>
          </div>
          <div className={`${styles.button4} hoverButtons`}>
            <div className={styles.text}>Next</div>
          </div>

        </div>
      </div>

      <div className={styles.frameContainer}>
        <div className={`${styles.textButtonGroup} hoverButtons`}>
          <div className={styles.iconGroup}>
            <img className={styles.icon} alt="" src="/generics/icon3.svg" />
            <div className={styles.userName}>Verification e-mail</div>
          </div>
          <div className={`${styles.button4} hoverButtons`}>
            <div className={styles.text}>Next</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrganizationDetails2;
