import React from 'react';
import styles from "./SwitchBtn.module.css";

interface SwitchButtonProps {
  isOn: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SwitchButton:React.FC<SwitchButtonProps>=({isOn,onChange,label})=> {
  return (
    <div
      className={`${styles.toggle} ${isOn ? styles.toggleChecked : ""
      }`}
    >
      <input
        className={styles.checkbox2}
        type="checkbox"
        style={{
          width: "100%",
          border: "0",
        }}
        onChange={onChange}
        checked={isOn}
      />
      <div className={styles.on}>{label}</div>
    </div>);
}

export default SwitchButton;