import { useParams, useNavigate } from "react-router-dom";
import styles from '../../../styles/BlogsPage/ArticlePage/ArticlePage.module.css';
import MapPageNavbar from '../../Map_Page/MapPageNavbar';
import CommercialVehicles from '../CommercialVehicles';
import Footer from '../../../Layouts/Footers/Footer';
import {articles} from '../Constants';
import {useEffect} from "react";
const informations = [
  {
    img: '/informationImages/1.jpeg',
    alt: 'Truck driving on a road with green fields'
  },
  {
    img: '/informationImages/2.jpeg',
    alt: 'Truck parked in front of warehouse'
  },
  {
    img: '/informationImages/3.jpeg',
    alt: 'Truck driving with blue sky background'
  },
]

const ArticlePage = () => {
  const { articleId }  = useParams();
  const navigate = useNavigate();
  const article = articles.find((article)=>article.id===articleId);
  const imageUrl= article?.img

  useEffect(() => {
 if (!article){
   navigate('/blogs');
 }
  }, []);

  return (
    <>
      <div className={styles.navbar}>
        <MapPageNavbar />
      </div>
      <header className={styles.header} style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}>
        <div className={styles.headerContent}>
          <h1 className={styles.title}>Find your Truck here</h1>
          <p className={styles.subTitle}>
            Truck 4 gives you access to thousands of commercial trucks, tractors, and trailers available for rent in your area.
          </p>
        </div>
      </header>
      <main className={styles.container}>
        <section className={styles.infoWrapper}>
          <div className={styles.infoDetails}>
            <h2 className={styles.infoTitle}>{article?.heading}</h2>
            <p className={styles.description}>{article?.description}
           </p>
            <div className={styles.quoteParent}>
              <div className={styles.verticalLine} />
              <blockquote className={styles.quote}>
                Choosing the right truck for your needs can be a daunting task, especially if you're not familiar with the various types of trucks available on the market.
              </blockquote>
            </div>
            <ol className={styles.tipsList}>
              <li>Determine Your Needs: The first step in choosing the right truck is to determine your needs. Consider the size and weight of the items you need to move, the distance you need to travel, and any specific requirements you may have, such as the need for a liftgate or a certain type of trailer.</li>
              <li>Consider the Payload Capacity: Payload capacity is the weight that a truck can carry in addition to its own weight. When choosing a truck, make sure to consider the payload capacity and choose a truck that can safely carry the weight of your cargo.</li>
              <li>Think About Fuel Efficiency: Fuel efficiency is an important factor to consider when choosing a truck, especially if you'll be driving long distances. Look for a truck with good gas mileage or consider alternative fuel options, such as hybrid or electric trucks.</li>
              <li>Choose the Right Type of Truck: There are several types of trucks available, including pickup trucks, cargo vans, box trucks, and flatbed trucks. Choose the type of truck that best fits your needs and can accommodate the size and weight of your cargo.</li>
              <li>Consider Maintenance and Repair Costs: Trucks require regular maintenance and repairs, so it's important to consider these costs when choosing a truck. Look for a truck with a good warranty and choose a brand with a reputation for reliability to help minimize these costs.</li>
            </ol>
          </div>
          <div className={styles.infoCard}>
            <h2 className={styles.infoTitle}>Conclusion</h2>
            <div className={styles.infoImageParent}>
              <img src={article?.img} className={styles.infoImage} />
              <p className={styles.infoText}>{article?.alt}</p>
            </div>
            <p className={styles.infoDiscription}>{article?.conclusion}</p>
            <button className={styles.button}>To rent out</button>
          </div>
        </section>
        <section className={styles.informationContainer}>
          <div className={styles.informationSection}>
            <h3 className={styles.sectionTitle}>It might be interesting</h3>
            <div className={styles.informationGrid}>
              {informations.map((information, index) => (
                <div key={index} className={styles.card}>
                  <img src={information.img} alt={information.alt} className={styles.cardImage} />
                  <div className={styles.cardContent}>
                    <h2 className={styles.cardTitle}>
                      Cancellation policy
                    </h2>
                    <p className={styles.cardText}>
                      Make sure that you returned the truck in accordance with the rental agreement. Delays may result in additional fines or restrictions in the future.
                    </p>
                    <p className={styles.readTime}>3 min. of reading</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <CommercialVehicles />
      <Footer />
    </>
  )
}

export default ArticlePage;
