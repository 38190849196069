import { UPDATE_REGISTRATION_DATA, UPDATE_TRUCK_REGISTRATION_DATA, UPDATE_USER_TOKEN } from "./actionTypes";
import { RegistrationData, TruckRegistrationData, UserToken } from "../types";

type PartialRegistrationData = Partial<RegistrationData>;
type PartialTruckRegistrationData = Partial<TruckRegistrationData>;
type PartialUserToken = Partial<UserToken>;

export const updateRegistrationData = (data: PartialRegistrationData) => ({
  type: UPDATE_REGISTRATION_DATA,
  data,
});

export const updateTruckRegistrationData = (
  data: PartialTruckRegistrationData,
) => ({
  type: UPDATE_TRUCK_REGISTRATION_DATA,
  data,
});

export const updaeteUserTokenData = (data: PartialUserToken) => ({
  type: UPDATE_USER_TOKEN,
  data,
});
