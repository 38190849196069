import React, { useCallback, useState } from "react";
import styles from "../../pages/RegistrationInitialPage.module.css";
import "react-international-phone/style.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { connect, ConnectedProps } from "react-redux";
import { RegistrationData, RootState } from "../../store/types";
import { updateRegistrationData } from "../../store/actions/registrationActions";
import { PhoneInput } from "react-international-phone";
import { errorToast } from "../Toast/ToastMessage";
import { parsePhoneNumber } from "libphonenumber-js";

export type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  onClick?: () => void;
  role: string;
  registerData?: RegistrationData;
};

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorVechicleToRent
>;

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorVechicleToRent = connect(mapStateToProps, mapDispatchToProps);

const VechicleToRent: React.FC<
  VechicleToRentProps & PropsFromReduxVechicleToRent
> = ({
  onNextButtonClick,
  onBackButtonClick,
  onNextButtonKeyDown,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
    const currentUrl = window.location.href;
    const [requiredField, setRequiredField] = useState<string>("");
    const [firstName, setFirstName] = useState<string>(registrationData.firstName);
    const [lastName, setLastName] = useState<string>(registrationData.lastName);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [phone, setPhone] = useState<string>(registrationData.phone);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>(registrationData.password);

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFirstName = e.target.value;
      setFirstName(newFirstName);
      updateRegistrationData({
        ...registrationData,
        firstName: newFirstName,
      });
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newLastName = e.target.value;
      setLastName(newLastName);
      updateRegistrationData({
        ...registrationData,
        lastName: newLastName,
      });
    };

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPassword = e.target.value;

      setPassword(newPassword);
      updateRegistrationData({
        ...registrationData,
        password: newPassword,
      });
      checkConditions(newPassword);
    };

    const handlePhoneNumber = (phone: string) => {
      const newPhoneNumber = phone;
      setPhone(newPhoneNumber);
      updateRegistrationData({
        ...registrationData,
        phone: newPhoneNumber,
      });
    };
    const onInputWithLabelClick = useCallback(() => { }, []);

    const handleNextClick = () => {
      if (!firstName || !lastName || !password || !phone) {
        const emptyField = !firstName
          ? "firstName"
          : !lastName
            ? "lastName"
            : !password
              ? "password"
              : "phone";
        setRequiredField(emptyField);

        return;
      } else {
        setRequiredField("");
      }

      try {
        const phoneNumber = parsePhoneNumber(phone, "US");
        if (phoneNumber.isValid()) {
          setIsValid(true);
        } else {
          setIsValid(false);
          return;
        }
        if (phoneNumber.country != "US") {
          errorToast("Phone number should only for US");
          return;
        }
      } catch (error) {
        setIsValid(false);
        return;
      }

      if (checkConditions(password).includes(false)) {
        errorToast("Please enter a strong password");
        return;
      }

      updateRegistrationData({
        ...registrationData,
        firstName: firstName,
        lastName: lastName,
        password: password,
        phone: phone,
        role: `${currentUrl.includes("registration_driver") ? "driver" : "renter"
          }`,
      });
      onNextButtonClick();
    };

    const handleBackButtonClick = () => {
      onBackButtonClick();
    };

    const checkConditions = (password: string) => {
      const conditions = [
        password.length >= 6,
        /[A-Z]/.test(password),
        /[a-z]/.test(password),
        /\d/.test(password),
        /[^a-zA-Z0-9]/.test(password),
      ];

      return conditions;
    };

    const passwordConditions = [
      "a minimum of 6 characters",
      "an uppercase character",
      "a lowercase character",
      "a number",
      "a special character",
    ];

    return (
      <div className={styles.backButtonParent}>
        <div className={styles.backButton} onClick={handleBackButtonClick}>
          <img className={styles.backButtonIcon} alt="" src="/generics/icon5.svg" />
          <span className={styles.backButtonText}>Back</span>
        </div>
        <div className={styles.textButton}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.signUpStep}>Sign Up {"(1/3 step)"}</div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>Apply to rent vehicle on Truck4</div>
          <div className={styles.inputText3}></div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.frameParent}>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel} role="button" onClick={onInputWithLabelClick}>
                <div className={styles.reefer}>First name&nbsp;</div>
                <div className={styles.basicInput} style={requiredField === "firstName" ? { border: "1px solid red" } : {}}>
                  <input type="text" className={styles.inputField} value={firstName} placeholder="First name" style={{ border: "none", width: "90%" }} onChange={handleFirstNameChange} />
                </div>
              </div>
              <div className={styles.inputWithLabel1}>
                <div className={styles.reefer}>Last name&nbsp;</div>
                <div className={styles.basicInput} style={requiredField === "lastName" ? { border: "1px solid red" } : {}}>
                  <input className={styles.inputField} value={registrationData.lastName} type="text" placeholder="Last name" style={{ border: "none", width: "90%" }} onChange={handleLastNameChange} />
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.inputWithLabelWrapper}>
                  <div className={styles.inputWithLabel1}>
                    <div className={styles.reefer}>Password</div>
                    <div className={styles.basicInput} style={requiredField === "password" ? { border: "1px solid red" } : {}}>
                      <input className={styles.inputText9} type={showPassword ? "text" : "password"} placeholder="Password" value={password} style={{ border: "none", background: "transparent" }} onChange={handlePassword} />
                      {showPassword ? (
                        <VisibilityOffIcon onClick={toggleShowPassword} className={`${styles.iconoutlineeye} hoverButtons`} />
                      ) : (
                        <VisibilityIcon onClick={toggleShowPassword} className={`${styles.iconoutlineeye} hoverButtons`} />
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelWrapper}>
                  <div className={styles.inputWithLabel1}>
                    <div className={styles.reefer}>Phone number</div>
                    <PhoneInput
                      style={{
                        height: "44px",
                        border:
                          requiredField === "phone"
                            ? "1px solid red"
                            : "1px solid #94a3b8",
                        borderRadius: "8px",
                      }}
                      className={styles.phoneNumberInput}
                      defaultCountry="US"
                      disableDialCodeAndPrefix={true}
                      value={phone}
                      onChange={(phone) => {
                        handlePhoneNumber(phone);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "100%",
                        fontSize: "16px",
                        borderRadius: "8px",
                        border: "none",
                        color: "#64748b",
                      }}
                    />
                  </div>
                  {!isValid && (
                    <p style={{ color: "red" }}>Please add a valid US phone</p>
                  )}
                </div>
              </div>
              <div className={styles.body}>
                <div className={styles.title}>Your password must contain:</div>
                <div className={styles.frameDiv}>
                  {checkConditions(password).map((conditionMet, index) => (
                    <div className={styles.iconsolidcheckCircleParent} key={index}>
                      <img className={styles.icon} alt="" src={`/generics/iconsolidcheckcircle${conditionMet ? "" : "1"}.svg`} />
                      <div className={styles.inputText12}>
                        {passwordConditions[index]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`errorBox ${requiredField ? "active" : ""}`}>Please, fill all required fields</div>
          <button className={`${styles.button1} hoverButtons`} onClick={handleNextClick}>Next</button>
        </div>
        <div className={styles.inputText19}>
          By confirming your email, you agree to our&nbsp;
          <span className={styles.termsOfService}>Terms of Service&nbsp;</span>
          and that you have read and understood our&nbsp;
          <span className={styles.termsOfService}>Privacy Policy</span>.
        </div>
      </div>
    );
  };

export default connectorVechicleToRent(VechicleToRent);
