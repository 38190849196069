import React, { useState, useEffect } from "react";
import styles from "../../css/Registration_Driver/LookingForRentFinal.module.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { REACT_APP_SHORT_COUNTDOWN } from '../../utils/UrlConstants';
import { AddOrganizationFinalProps } from "./types";

const AddOrganizationFinal: React.FC<AddOrganizationFinalProps> = ({
  onNextButtonClick,
  onBackButtonClick,
  onNextButtonKeyDown,
  role,
  ...props
}) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(parseInt(REACT_APP_SHORT_COUNTDOWN || '5'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate("/main");
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown, navigate]);

  return (
    <div className={styles.lookingForRent08}>
      <div className={styles.modal}>
        <div className={styles.iconParent}>
          <img className={styles.icon1} alt="" src="/generics/iconsolidcheckcircle.svg" />
          <div className={styles.userName}>
            Thank you for successfully registering on our website!
          </div>
        </div>
        <div className={styles.inputTextContainer}>
          <div className={styles.inputText3}>
            We are glad to welcome you to our community.
          </div>
          <div className={styles.inputText3}>
            Redirecting in {countdown} sec
          </div>
        </div>
        <div className={`${styles.button1} hoverButtons`}>
          <Link to="/main">
            <div className={styles.text} style={{ color: "white" }}>
              Explore the sites
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddOrganizationFinal;
