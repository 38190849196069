import React from 'react';
import styles from "./CommonButton.module.css";

interface CommonButtonProps {
  label: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?:"submit" | "button";
}

const CommonButton:React.FC<CommonButtonProps>=({label, style, onClick, disabled, type})=> {
  return (
    <button className={styles.promoBtn} style={style} onClick={onClick} disabled={disabled} type={type}>
      <div className={styles.promoText} >{label}</div>
    </button>);
}

export default CommonButton;