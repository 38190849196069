import styles from "../../styles/Registration/RegistrationNavbar.module.css";
import GenericModal from '../../components/Modal/GenericModal';
import Login from '../../components/Login/Login';
import ForgotPassword from '../../components/Login/ForgotPassword';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGlobalContext } from "../../components/Global/GlobalState";

const RegistrationNavbar: React.FC = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const { openForgot, setOpenForgot, openLogin, setOpenLogin } = useGlobalContext();
  const forgotClose = () => setOpenForgot(false);
  const handleClose = () => setOpenLogin(false);

  return (
    <>
      <div className={styles.registrationNavbar}>
        <Link to={currentUser && currentUser.role == 'renter' ? '/admin/truck_management' : '/main'}>
          <img className={styles.logo} src="/logos/Logo.png" />
        </Link>
        <div className={styles.authPrompt}>
          <span className={styles.authPromptText}>Already have an account?</span>
          <button className={`${styles.loginButton} hoverButtons`} onClick={() => setOpenLogin(true)}>Log In</button>
          <button className={styles.hamburger} onClick={() => setOpenLogin(true)}><img src="/Registration/hamburger.svg" /></button>
        </div>
      </div>
      <GenericModal
        cmp={Login}
        setShowModal={handleClose}
        onClickSubmit={handleClose}
        showModal={openLogin}
        showFooter={false}
        title="Log In"
      />
      <GenericModal
        cmp={ForgotPassword}
        setShowModal={forgotClose}
        onClickSubmit={forgotClose}
        showModal={openForgot}
        showFooter={false}
        title="Reset Password"
      />
    </>
  )
}

export default RegistrationNavbar;
