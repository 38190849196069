import styles from "./css/Footer.module.css";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.container1}>
          <div className={styles.links}>
            <div className={styles.linksInner}>
              <div className={styles.footerssimpleLinkWrapper}>
                <div className={styles.footerssimpleLink}>
                  <div className={styles.text}>Terms & Conditions</div>
                </div>
              </div>
            </div>
            <div className={styles.linksChild}>
              <div className={styles.footerssimpleLinkContainer}>
                <div className={styles.footerssimpleLink}>
                  <div className={styles.text1}>Privacy Policy</div>
                </div>
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.footerssimpleLinkFrame}>
                <div className={styles.footerssimpleLink}>
                  <div className={styles.text2}>Help Center</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footersocialLinks}>
          <div className={styles.footerssocialLink}>
            <img
              className={styles.footerssocialIcon}
              alt=""
              src="/generics/footerssocial-icon.svg"
            />
          </div>
          <div className={styles.footerssocialLink1}>
            <img
              className={styles.footerssocialIcon1}
              alt=""
              src="/generics/footerssocial-icon-1.svg"
            />
          </div>
          <div className={styles.footerssocialLink2}>
            <img
              className={styles.footerssocialIcon2}
              alt=""
              src="/generics/footerssocial-icon-2.svg"
            />
          </div>
          <div className={styles.footerssocialLink3}>
            <img
              className={styles.footerssocialIcon3}
              alt=""
              src="/generics/footerssocial-icon-3.svg"
            />
          </div>
          <div className={styles.footerssocialLink4}>
            <img
              className={styles.footerssocialIcon4}
              alt=""
              src="/generics/footerssocial-icon-4.svg"
            />
          </div>
        </div>
        <div className={styles.copyright}>
          © 2023 Truck4, Inc. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
