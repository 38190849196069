import axios from 'axios';
import store from '../../store/store';
import { setCurrentUserData } from '../../store/actions/userAction';
import { REACT_APP_AUTH_SVC_API_BASE_URL } from '../../utils/UrlConstants'

export const validateCurrentUser = async (accessToken :string, refreshToken :string) => {
  try {
    const url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    const response :any = await axios.get(url, {
      headers: {
        'X-Access-Token': accessToken,
        'X-Refresh-Token': refreshToken,
      },
    });
    if (response.status === 200) {
      let responseAccessToken = response.headers.get('x-access-token')
      if(responseAccessToken !== undefined && responseAccessToken !== accessToken) {
        localStorage.setItem('accessToken', responseAccessToken);
      }
      store.dispatch(setCurrentUserData(response.data.user));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    error
    console.error('Error validating user:', error);
    return false;
  }
};
