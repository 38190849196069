import React, { useEffect, useState } from "react";
import styles from "../styles/Registration/RegistrationStep1.module.css";
import { Link } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RegistrationData, RootState } from "../store/types";
import { updateRegistrationData } from "../store/actions/registrationActions";

const countryOptions = [
  { value: "+1", label: "United States (US)" },
  { value: "+373", label: "Moldova (MD)" },
];

const mapStateToPropsOwnerFirst = (state: RootState) => ({
  registrationData: state.registrationData,
});

const mapDispatchToPropsOwnerFirst = {
  updateRegistrationData,
};

const connectorOwnerFirst = connect(
  mapStateToPropsOwnerFirst,
  mapDispatchToPropsOwnerFirst
);

export type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData?: RegistrationData;
  onClick?: () => void;
};
const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorVechicleToRent = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorVechicleToRent
>;

type PropsFromReduxOwnerFirst = ConnectedProps<typeof connectorOwnerFirst>;

const RegistrationOwnerFirst: React.FC<
  VechicleToRentProps & PropsFromReduxOwnerFirst
> = ({
  onNextButtonClick,
  onBackButtonClick,
  onNextButtonKeyDown,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
    const [selectedCountry] = useState(countryOptions[0]);
    const [firstName] = useState<string>(registrationData?.firstName || "");
    const [lastName] = useState<string>(registrationData.lastName || "");
    const [phone, setPhone] = useState<string>(
      selectedCountry.value + registrationData.phone
    );
    const [password] = useState<string>(registrationData.password);
    const currentUrl = window.location.href;

    useEffect(() => {
      setPhone(selectedCountry.value + registrationData.phone);
    }, [selectedCountry.value]);

    useEffect(() => {
      const handleBeforeUnload = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    const handleNextClick = () => {
      updateRegistrationData({
        ...registrationData,
        firstName: firstName,
        lastName: lastName,
        password: password,
        phone: phone,
        role: `${currentUrl.includes('registration/driver') ? 'driver' : 'renter'}`,
      });
      onNextButtonClick();
    };

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h4 className={styles.title}>How will you use Truck4?</h4>
          <p className={styles.detail}>Please select an option below.</p>
        </div>
        <div className={styles.radioGroup}>
          <Link to="/registration/driver" className={`${styles.radioGroupItem1} hoverButtons`}>
            I want to rent
          </Link>
          <Link
            to="/registration/owner"
            className={`${styles.radioGroupItem2} ${styles.activeItem} hoverButtons`}>
            I'm a vehicle owner
          </Link>
          <Link to="#" className={`${styles.radioGroupItem3} hoverButtons`}>
            I'm an Employee
          </Link>
        </div>
        <div className={styles.instanceGroup}>
          <div className={styles.backButtonParent}>
            <div className={styles.frameParent}>
              <h5 className={styles.userName1}>Sign up as an organization to</h5>
              <div className={styles.details}>
                <div className={styles.iconsolidcheckCircleParent}>
                  <img className={styles.icon} src="/generics/iconsolidcheckcircle.svg" />
                  <span className={styles.text}>Work compliantly from 150+ countries</span>
                </div>
                <div className={styles.iconsolidcheckCircleParent}>
                  <img className={styles.icon} src="/generics/iconsolidcheckcircle.svg" />
                  <span className={styles.text}>Automate your invoicing for every client</span>
                </div>
                <div className={styles.iconsolidcheckCircleParent}>
                  <img className={styles.icon} src="/generics/iconsolidcheckcircle.svg" />
                  <span className={styles.text}>Avoid transfer fees with 7+ payment options</span>
                </div>
              </div>
            </div>
            <img className={styles.image} alt="" src="/generics/group-24561.svg" />
          </div>
          <button className={`${styles.button} hoverButtons`} onClick={handleNextClick}>Next</button>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.about}>Got any questions?&nbsp;<Link to="#" className={styles.link}>Let us know!</Link></span>
        </div>
      </div>
    );
  };

export default connectorOwnerFirst(RegistrationOwnerFirst);
