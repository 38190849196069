import styles from "./css/OrganizationPage.module.css";

interface OrganizationContactProps {
  togglePhoneNumber: () => void;
  showImage: boolean;
  company: any;
  showPhoneNumber: boolean;
}


export const OrganizationContact: React.FC<OrganizationContactProps> = ({
  togglePhoneNumber,
  showImage,
  company,
  showPhoneNumber,
}) => {
  return (
    <>
      <div className={styles.avatarParent}>
        { showImage && (
            <img
              className={styles.avatarIcon}
              alt=""
              src="/organization/company-avatar.svg"
            />
          )
        }
        <div className={styles.k1CompanyParent}>
          <div className={styles.k1Company}>{company.name}</div>
        </div>
        <div className={styles.frameChild29} />
        <div className={styles.workHoursParent}>
          <div className={styles.workHours}>Work hours:</div>
          <div className={styles.am10pm}>10am - 10pm&nbsp;</div>
        </div>
        <div className={styles.companyEmailParent}>
          <div className={styles.companyEmail}>Company Email:</div>
          <div className={styles.k1gmailcom}>{company.email}</div>
        </div>
        <div className={styles.companysStateHeadquartesParent}>
          <div className={styles.companysStateHeadquartes}>
            Company's state headquartes
          </div>
          <div className={styles.alabama}>
            {company?.physicalAddress?.state}
          </div>
        </div>
        <div className={styles.frameChild30} />
        <div
          className={`${styles.button} hoverButtons`}
          onClick={togglePhoneNumber}
        >
          {showPhoneNumber ? (
            <div className={styles.phoneNumberDiv}>{company.phone}</div>
          ) : (
            <>
              <img
                className={styles.iconsolidphone}
                alt=""
                src="/organization/iconsolidphone.svg"
              />
              <div className={styles.text6}>Show phone number</div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default OrganizationContact
