import { useState } from "react";
import AdminSidebar from "../Admin/AdminSidebar";
import AdminNavBar from "./AdminNavBar";
import styles from "./css/MainAdministration.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          className={styles.progressBar}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const MainPage: React.FC = () => {
  const reduxState = useSelector((state: any) => state);
  const [progress] = useState<number>(16);

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div className={styles.mainPage}>
        <div className={styles.adminSideBar}>
          <AdminSidebar />
        </div>
        <div className={styles.headerDashPageParent}>
          <div className={styles.headerDashPage}>
            <div className={styles.textParent}>
              <div className={styles.text12}>Main page</div>
            </div>
            <div className={styles.buttonParent}>
              <div className={styles.button}>
                <img
                  className={styles.iconoutlinecog1}
                  alt=""
                  src="/admin/main/iconoutlinecog1.svg"
                />
                <div className={styles.text13}>Fleet settings</div>
              </div>
              <div className={styles.button1}>
                <div className={styles.text14}>Connect your Database</div>
              </div>
              <div className={styles.button2}>
                <img
                  className={styles.iconsolidplus}
                  alt=""
                  src="/admin/main/iconsolidplus.svg"
                />
                <div className={styles.text15}>???</div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.numberCardParent}>
              <div className={styles.numberCard}>
                <div className={styles.frameGroup}>
                  <div className={styles.creditsUsedWrapper}>
                    <div className={styles.creditsUsed}>Staff in company</div>
                  </div>
                  <div className={styles.div}>
                    {reduxState.organization.users.length}
                  </div>
                </div>
                <img
                  className={styles.iconoutlineusers}
                  alt=""
                  src="/admin/main/iconoutlineusers.svg"
                />
              </div>
              <div className={styles.numberCard1}>
                <div className={styles.creditsUsedParent}>
                  <div className={styles.creditsUsed1}>Published trucks</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  <span>{reduxState?.organization.trucks.length}</span>
                </div>
                <div className={styles.numberCardInner}>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.ofCreditsUsed}>
                  TBD truck is available
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.group}>
                <div className={styles.div6}>
                  Finish the registration and get bonuses!
                </div>
                <div className={styles.div7}>
                  What's left to finish registration{" "}
                </div>
                <img
                  className={styles.frameChild2}
                  alt=""
                  src="/admin/main/vector-23.svg"
                />
                <img
                  className={styles.frameChild3}
                  alt=""
                  src="/admin/main/vector-23.svg"
                />
              </div>
              <div className={styles.frameParent2}>
                <div className={styles.creditsUsedParent1}>
                  <div className={styles.creditsUsed4}>Articles</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir4}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div8}>8</div>
                <div className={styles.frameWrapper2}>
                  <div className={styles.frameChild4} />
                </div>
                <div className={styles.ofCreditsUsed3}>
                  82$ of credits used
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent3}>
            <div className={styles.completeTheRegistrationBefoParent}>
              <div className={styles.completeTheRegistration}>
                Complete the registration ✅
              </div>
              <div className={styles.heyThereThis1}></div>
            </div>
            <div className={styles.rectangleDiv} />

            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
            <div className={styles.frameParent4}>
              <div className={styles.stepcircleWithNumbersWithParent}>
                <div className={styles.stepcircleWithNumbersWith}>
                  <div className={styles.stepcircleWithNumbers}>
                    <img
                      className={styles.iconsolidcheck}
                      alt=""
                      src="/admin/main/iconsolidcheck.svg"
                    />
                  </div>
                  <div className={styles.content11}>
                    <div className={styles.title1}>Create account</div>
                    <div className={styles.details}></div>
                  </div>
                </div>
                <div className={styles.button3}>
                  <div className={styles.text16}>M default</div>
                </div>
              </div>
              <div className={styles.stepcircleWithNumbersWithGroup}>
                <div className={styles.stepcircleWithNumbersWith1}>
                  <div className={styles.stepcircleWithNumbers1}>
                    <div className={styles.title2}>2</div>
                    <div className={styles.dot} />
                  </div>
                  <div className={styles.content12}>
                    <div className={styles.title3}>
                      Go through credential and document check
                    </div>
                    <div className={styles.details1}>
                      Provide proof of ownership of the vehicle (e.g.,
                      registration registration papers).
                    </div>
                  </div>
                </div>
                <div className={`${styles.button4} hoverButtons`}>
                  <div className={styles.text17}>Add documents</div>
                  <img
                    className={styles.icon1}
                    alt=""
                    src="/admin/main/icon.svg"
                  />
                </div>
              </div>
              <div className={styles.stepcircleWithNumbersWithContainer}>
                <div className={styles.stepcircleWithNumbersWith2}>
                  <div className={styles.stepcircleWithNumbers2}>
                    <div className={styles.title4}>3</div>
                    <div className={styles.dot1} />
                  </div>
                  <div className={styles.content13}>
                    <div className={styles.title3}>Connect your card</div>
                    <div className={styles.details1}></div>
                  </div>
                </div>
                <div className={`${styles.button5} hoverButtons`}>
                  <div className={styles.text18}>Add card</div>
                  <img
                    className={styles.iconsolidarrowSmRight}
                    alt=""
                    src="/admin/main/icon.svg"
                  />
                </div>
              </div>
              <div className={styles.stepcircleWithNumbersWithParent1}>
                <div className={styles.stepcircleWithNumbersWith3}>
                  <div className={styles.stepcircleWithNumbers3}>
                    <div className={styles.title6}>4</div>
                    <div className={styles.dot2} />
                  </div>
                  <div className={styles.content14}>
                    <div className={styles.title3}>
                      Add a free truck / Buy a subscription and get start
                      posting
                    </div>
                  </div>
                </div>
                <div className={`${styles.button6} hoverButtons`}>
                  <div className={styles.text19}>Add truck</div>
                  <img
                    className={styles.iconsolidarrowSmRight1}
                    alt=""
                    src="/admin/main/icon.svg"
                  />
                </div>
              </div>
              <div className={styles.stepcircleWithNumbersWithParent2}>
                <div className={styles.stepcircleWithNumbersWith4}>
                  <div className={styles.stepcircleWithNumbers4}>
                    <div className={styles.title8}>5</div>
                    <div className={styles.dot3} />
                  </div>
                  <div className={styles.content15}>
                    <div className={styles.title3}>
                      Publish on our platform
                    </div>
                  </div>
                </div>
                <div className={styles.button7}>
                  <div className={styles.text20}>S default</div>
                </div>
              </div>
              <div className={styles.stepcircleWithNumbersWithParent3}>
                <div className={styles.stepcircleWithNumbersWith5}>
                  <div className={styles.stepcircleWithNumbers5}>
                    <div className={styles.title10}>6</div>
                    <div className={styles.dot4} />
                  </div>
                  <div className={styles.content16}>
                    <div className={styles.title3}>
                      Manage your trucks via our platform
                    </div>
                  </div>
                </div>
                <div className={`${styles.button8} hoverButtons`}>
                  <div className={styles.text21}>Manage</div>
                  <img
                    className={styles.iconsolidarrowSmRight2}
                    alt=""
                    src="/admin/main/icon.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
