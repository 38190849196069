import PricingPagesTier1 from "./PricingPagesTier1";
import Footer from "./Footer";
import styles from "./css/AddTruckInitial.module.css";
import { AddTruckFirstPageProps } from "./types";

const AddTruckFirstPage: React.FC<AddTruckFirstPageProps> = ({
  onNextButtonClick,
}) => {
  const handleNextClick = () => {
    onNextButtonClick();
  };

  return (
    <div className={styles.addTruck}>
      <main className={styles.rentFrame}>
        <div className={styles.pricingPagesText}>
          <h1 className={styles.publishTransport}>Publish transport</h1>
        </div>
        <section className={styles.shapesFrame}>
          <PricingPagesTier1 onNextButtonClick={handleNextClick} />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AddTruckFirstPage;
