import styles from '../styles/Registration/RegistrationLayout.module.css';
import RegistrationNavbar from './Navbars/RegistrationNavbar';
import DriverRegistrationSidebar from './Sidebars/DriverRegistrationSidebar';
import DriverRegistration from '../pages/Registration/DriverRegistration';

const DriverRegistrationLayout = () => {
  return (
    <>
      <section className={styles.layoutContainer}>
        <div className={styles.navbarContainer}>
          <RegistrationNavbar />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.sidebarContainer}>
            <DriverRegistrationSidebar />
          </div>
          <div className={styles.mainContent}>
            <DriverRegistration />
          </div>
        </div>
      </section>
    </>
  )
}

export default DriverRegistrationLayout;
