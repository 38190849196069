import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OwnProps, RootState } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import RegistrationInitialRenter from "../RegistrationInitialRenter";
import VechicleToRent from "../../components/Registration/VechicleToRent";
import AddOrganization from "../../components/Registration/AddOrganization";
import AddOrganizationList from "../../components/Registration/AddOrganizationList";
import AddOrganizationDetails2 from "../../components/Registration/AddOrganizationDetails2";
import AddOrganizationVerification from "../../components/Registration/AddOrganizationVerification";
import AddOrganizationVerification2 from "../../components/Registration/AddOrganizationVerification2";
import AddOrganizationFinal from "../../components/Registration/AddOrganizationFinal";
import { updateRegistrationData as handleUpdateRegistrationData } from "../../store/actions/registrationActions";

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const mapDispatchToProps = {
  handleUpdateRegistrationData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const DriverRegistration: React.FC<OwnProps & PropsFromRedux> = ({
  registrationData,
  handleUpdateRegistrationData,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleNextButtonClick = (step?: number) => {
    if (step !== undefined) {
      setCurrentStep(step);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackButtonClick = () => {
    if (currentStep === 1) {
      navigate("/registration/driver");
    }
    setCurrentStep(currentStep - 1);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <RegistrationInitialRenter
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 2:
        return (
          <>
            <VechicleToRent
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 3:
        return (
          <>
            <AddOrganization
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 4:
        return (
          <>
            <AddOrganizationList
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onSkipButtonClick={() => handleNextButtonClick(5)}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 5:
        return (
          <>
            <AddOrganizationDetails2
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              registrationData={registrationData}
              updateRegistrationData={handleUpdateRegistrationData}
            />
          </>
        );
      case 6:
        return (
          <>
            <AddOrganizationVerification
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );
      case 7:
        return (
          <>
            <AddOrganizationVerification2
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onSkipBackwards={() => handleNextButtonClick(4)}
            />
          </>
        );
      case 8:
        return (
          <>
            <AddOrganizationFinal
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {renderCurrentStep()}
    </>
  );
};

export default connector(DriverRegistration);
