import styles from '../../styles/Footers/Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerContent}>
          <div className={styles.footerLogo}>
            <img alt="Company logo" className={styles.logo} src="/Footer/Logo.svg" />
            <p className={styles.description}>
              Making the world a better place through constructing elegant hierarchies.
            </p>
            <div className={styles.socialLink}>
              <img src="/Footer/fbIcon.svg" alt="" />
              <img src="/Footer/instaIcon.svg" alt="" />
              <img src="/Footer/twitterIcon.svg" alt="" />
              <img src="/Footer/githubIcon.svg" alt="" />
              <img src="/Footer/dribbbleIcon.svg" alt="" />
            </div>
          </div>
          <div className={styles.footerLinks}>
            <div className={styles.footerColumn}>
              <div className={styles.column}>
                <h3 className={styles.sectionTitle}>SOLUTIONS</h3>
                <ul className={styles.links}>
                  <li>Vehicle rentals</li>
                  <li>Renting out vehicle</li>
                  <li>Commerce</li>
                  <li>Insights</li>
                </ul>
              </div>
              <div className={styles.column}>
                <h3 className={styles.sectionTitle}>TECHNOLOGY</h3>
                <ul className={styles.links}>
                  <li>Pricing</li>
                  <li>Documentation</li>
                  <li>Guides</li>
                  <li>API Status</li>
                </ul>
              </div>
            </div>
            <div className={styles.footerColumn}>
              <div className={styles.column}>
                <h3 className={styles.sectionTitle}>SAFETY &amp; TRUST</h3>
                <ul className={styles.links}>
                  <li>About</li>
                  <li>Blog</li>
                  <li>Jobs</li>
                  <li>Press</li>
                  <li>Partners</li>
                </ul>
              </div>
              <div className={styles.column}>
                <h3 className={styles.sectionTitle}>RESOURCES</h3>
                <ul className={styles.links}>
                  <li>About Roybam</li>
                  <li>Help center</li>
                  <li>Contact us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>© 2023 Roybam, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
