import styles from "./FieldSelector.module.css";
import Form from "react-bootstrap/Form";
import { useGlobalContext } from "../../../components/Global/GlobalState";

interface TruckData {
  imgSrc: string;
  headline: string;
}

interface MapPageSelectorProps {
  selectedPrecategory: string;
  allTruckData: TruckData[];
  selectedItem: string;
  handlePrecategory: (category: string) => void;
  handleItemClick: (category: string) => void;
  handleHeadlineClick: () => void;
}

const FieldSelector: React.FC<MapPageSelectorProps> = ({
  handleHeadlineClick,
}) => {
  const {
    setSearchByLocation,
  } = useGlobalContext();

  return (
    <div className={styles.fieldSelectorWrapper}>
      <div className={styles.formWrapper}>
        <Form.Control
          className={styles.form}
          size="lg"
          type="text"
          placeholder="🔍 &nbsp; California, .."
          onChange={(e) => setSearchByLocation(e.target.value)}
        />
      </div>
      <svg
        onClick={handleHeadlineClick}
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="55"
          height="55"
          rx="7.5"
          stroke="#CBD5E1"
        />
        <path
          d="M19 20C19 19.4477 19.4477 19 20 19H36C36.5523 19 37 19.4477 37 20V22.5858C37 22.851 36.8946 23.1054 36.7071 23.2929L30.2929 29.7071C30.1054 29.8946 30 30.149 30 30.4142V33L26 37V30.4142C26 30.149 25.8946 29.8946 25.7071 29.7071L19.2929 23.2929C19.1054 23.1054 19 22.851 19 22.5858V20Z"
          stroke="#0F172A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default FieldSelector;
