import React, {useState} from 'react';
import styles from "../../styles/Subscription/Subscription.module.css";
import AdminSidebar from "../Admin/AdminSidebar";
import AdminNavBar from "../Admin/AdminNavBar";
import {planDetails, subscriptionPlans} from "./Contants";
import SwitchButton from "../../components/Common/Switch/SwitchButton";
import CommonButton from "../../components/Common/Button/CommonButton";
interface SubscriptionPageProps {

}

const CheckMarkSvg =()=> {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
          fill="#10B981"/>
  </svg>
}

const InfoSvg =()=> {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.85638 7.5C7.31398 6.52901 8.54869 5.83333 10 5.83333C11.841 5.83333 13.3334 6.95262 13.3334 8.33333C13.3334 9.49953 12.2687 10.4792 10.8286 10.7555C10.3766 10.8422 10 11.2064 10 11.6667M10 14.1667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
      stroke="#94A3B8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}

const SubscriptionPage: React.FC<SubscriptionPageProps> = () => {
  const [isOn, setIsOn] = useState(false);

  const featureData = [
    {
      title: "hello",
      service1: <CheckMarkSvg/>,
      service2: <CheckMarkSvg/>,
      service3: <CheckMarkSvg/>,
      service4: <CheckMarkSvg/>,
      service5: <CheckMarkSvg/>,
    },
    {
      title: (
        <>
          hello&nbsp;
          <InfoSvg/>
        </>
      ),
      service1: "Unlimited",
      service2: <CheckMarkSvg/>,
      service3: <CheckMarkSvg/>,
      service4: <CheckMarkSvg/>,
      service5: <CheckMarkSvg/>,
    },
    {
      title:  <>
        Molestie lobortis massa.&nbsp;
        <InfoSvg/>
      </>,
      service1: "Unlimited",
      service2: <CheckMarkSvg/>,
      service3: <CheckMarkSvg/>,
      service4: <CheckMarkSvg/>,
      service5: <CheckMarkSvg/>,
    }, {
      title: <>
        Molestie lobortis massa.&nbsp;
        <div className={styles.newTag}>
          <div className={styles.newTagText}>New</div>
        </div>
      </>,
      service1: "Unlimited",
      service2: <CheckMarkSvg/>,
      service3: <CheckMarkSvg/>,
      service4: <CheckMarkSvg/>,
      service5: <CheckMarkSvg/>,
    },{
      title: <>
        Molestie lobortis massa.&nbsp;
        <div className={styles.commingSoonTag}>
          <div className={styles.commingSoonTagText}>Comming soon</div>
        </div>
      </>,
      service1: "Unlimited",
      service2: <CheckMarkSvg/>,
      service3: <CheckMarkSvg/>,
      service4: <CheckMarkSvg/>,
      service5: <CheckMarkSvg/>,
    },
  ];

  const handleSubscription=async ()=>{
// const stripe=loadStripe("pk_test_51QySFdQ6K2HRa1FnUF4EjqGv1gBgAulVHHAwK9tzshMMf8Ub8LzSMV4iUcnJwNsqxOdcjpu1a5DDjwnlInD2L9Xs00f0zhVxmx");
//
// const response = await fetch('/subscribe',{
//   method: "POST",
//   headers:{
//     "Content-Type": "application/json",
//   },
//   body: JSON.stringify({price:10,plan:"business"})
// })

    // const session = await response.json();
// const result = stripe.redirectToCheckout({
//   sessionId:session.id
// });
//
// if(result.error){
//   console.log(result.error);
// }
  }

  return (
    <>
      <div className={styles.subscriptionNavbar}>
        <AdminNavBar/>
      </div>
      <div className={styles.parentContainer}>
        <div className={styles.subscriptionSidebar}>
          <AdminSidebar/>
        </div>
        <div className={styles.parentDiv}>
          <div className={styles.subscriptionContainer}>
            <div className={styles.subscriptionInnerContainer}>
              <p className={styles.upgradeContainer}>Get upgrade with Roybam Today</p>
              <div className={styles.billingContainer}>
                <div className={styles.monthlyBillParentContainer}>
                  <div className={styles.monthyBillContainer}>Monthly Billing</div>
                  <SwitchButton isOn={isOn} onChange={() => setIsOn(!isOn)}/>
                </div>
                <div className={styles.monthlyBillParentContainer}>
                  <div className={styles.annualBill}>Annual bill</div>
                  <div className={styles.saveContainer}>
                    <div className={styles.save}>Save 25%</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputLabel}>
              <div className={styles.inputDiv}>
                <input type="text" className={styles.inputField} placeholder="Enter promo code"/>
              </div>
              <CommonButton label="Apply"/>
            </div>
          </div>
          {/*Subscription Plans*/}
          <div className={styles.subscriptionPlanContainer}>
            {/*Cards*/}
            {subscriptionPlans.map((plan, index) => (
              <div key={index} className={styles.freeSubscriptionPlan}>
                <div className={styles.freeSubscriptionPlanHeader} >
                  <div className={styles.freeSubscriptionPlanHeaderText} style={plan.titleBgColor|| { backgroundColor: "#ffffff" }}>{plan.title}</div>
                </div>
                <div className={styles.subscriptionPrice}>
                  <div className={styles.subscriptionPriceTitle}>{plan.price}</div>
                  <div className={styles.subscriptionPriceSubtitle}>{plan.subtitle}</div>
                </div>
                <div className={styles.planDetails}>
                  <div className={styles.planDetailHeader}>
                    <div className={styles.planDetailHeaderText}>{plan.description}</div>
                  </div>
                  {plan.features.map((feature, idx) => (
                    <div key={idx} className={styles.planDetailsDescription}>
                      <div className={styles.checkMark}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
                            fill="#10B981"
                          />
                        </svg>
                      </div>
                      <div className={styles.planDescription}>{feature}</div>
                    </div>
                  ))}
                </div>
                <CommonButton label={plan.buttonText} style={plan.titleTextColor} onClick={handleSubscription}/>
              </div>
            ))}
          </div>
          {/*Table*/}
          <div className={styles.featureContainer}>
            {/*Header*/}
            <div className={styles.featureHeader}>
              <div className={styles.featureTitle}>
                <div className={styles.featureTitleText}>
                  Features
                </div>
              </div>
              {planDetails.map((plan, index) => (
                <div key={index} className={styles.detailFreePlan}>
                  <div className={styles.detailFreePlanButton} style={plan.titleColor}>{plan.title}</div>
                  <div className={styles.detailFreePlanPrice}>
                    <div className={styles.featurePlanPrice}>{plan.price}&nbsp;</div>
                    <div className={styles.featurePlanDuration}>{plan.duration}</div>
                  </div>
                  <div className={styles.detailPlanBtn} >{plan.buttonText}</div>
                </div>
              ))}
            </div>
            {/*body*/}
            <>
              {featureData.map((feature, index) => (
                <div key={index}
                     className={styles.featureRowHeader}>

                  <div className={styles.featureTitle}>
                    <div className={styles.tableFeature}>
                      {feature.title}
                    </div>
                  </div>
                  <div className={styles.tableCheckMark}>
                    <div className={styles.tableFeatureContent}>{feature.service1}
                    </div>
                  </div>
                  <div className={styles.tableCheckMark}>
                    <div className={styles.tableFeatureContent}>{feature.service2}
                    </div>
                  </div>
                  <div className={styles.tableCheckMark}>
                    <div className={styles.tableFeatureContent}>{feature.service3}
                    </div>
                  </div>
                  <div className={styles.tableCheckMark}>
                    <div className={styles.tableFeatureContent}>{feature.service4}
                    </div>
                  </div>
                  <div className={styles.tableCheckMark}>
                    <div className={styles.tableFeatureContent}>{feature.service5}
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
          {/*TableEnd*/}
        </div>
      </div>
    </>
  );
}

export default SubscriptionPage;