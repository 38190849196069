import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate } from "react-router-dom";
import styles from "./MapPage.module.css";
import { useSelector } from "react-redux";
import { errorToast } from "../../components/Toast/ToastMessage";
import { useDispatch } from "react-redux";
import { setHoveredTruckId } from "../../store/actions/mapActions";

const TrucksList = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toRadians = (degrees: any) => (degrees * Math.PI) / 180;

  const calculateDistance = (
    lat1: any,
    lon1: any,
    lat2: any,
    lon2: any,
    truck: any
  ) => {
    const R = 3958.8; // Radius of the Earth in miles
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c).toFixed(0);
  };

  const handleMouseOver = (truckId: string) => {
    dispatch(setHoveredTruckId(truckId));
  };

  const handleMouseOut = () => {
    dispatch(setHoveredTruckId(""));
  };
  const currentUser = useSelector((state: any) => state.currentUser);
  const [selectedHearts, setSelectedHearts] = useState<string[]>([]);
  const currentUserID = currentUser._id;

  const [userLocation, setUserLocation] = useState(() => {
    const storedLocation = localStorage.getItem("userLocation");
    return storedLocation ? JSON.parse(storedLocation) : null;
  });

  useEffect(() => {
    const favorites =
      JSON.parse(localStorage.getItem("favorites") as string) || {};
    if (favorites[currentUserID]) {
      setSelectedHearts(favorites[currentUserID]);
    }

    console.log(userLocation);
  }, [currentUserID]);

  const handleHeartClick = (index: any) => {
    if (currentUserID == "") return errorToast("Please login first");

    let updatedFavorites;
    if (selectedHearts.includes(index)) {
      updatedFavorites = selectedHearts.filter((item: any) => item !== index);
    } else {
      updatedFavorites = [...selectedHearts, index];
    }

    setSelectedHearts(updatedFavorites);

    const allFavorites =
      JSON.parse(localStorage.getItem("favorites") as string) || {};
    allFavorites[currentUserID] = updatedFavorites;
    localStorage.setItem("favorites", JSON.stringify(allFavorites));
  };

  return (
    <div className={styles.mainTruckList}>
      {props.trucks.length == 0 && (
        <small className="text-theme">No trucks found</small>
      )}
      {props.trucks.map((truck: any, index: number) => {
        return (
          <div
            className={`${styles.truckContainer2} singleTruck`}
            key={truck._id}
            onMouseOver={(e) => handleMouseOver(truck._id)}
            onMouseOut={handleMouseOut}
            // onClick={() => window.open(`/truck/${truck._id}`, "_blank")}
          >
            <div className={`hoverButtons`} style={{ position: "relative" }}>
              {/* Carousel for images */}
              <Carousel
                className={props.styles.vecicleCard2}
                id="truck_container"
                interval={null}
              >
                {Object.entries(truck.images)
                  .filter(([key, value]) => value)
                  .map(([key, value]) => (
                    <Carousel.Item
                      key={`${truck._id}-${key}`}
                      onClick={() =>navigate(`/truck/${truck._id}`)
                        // window.open(`/truck/${truck._id}`, "_blank")
                      }
                    >
                      <div
                        className={`${props.styles.frameParent3} hoverButtons`}
                        style={{ cursor: "pointer" }}
                      >
                        {/* <Link to={`/truck/${truck._id}`} key={index}> */}
                        <img
                          src={`${value || "frame-24031@3x.png"}`}
                          className={`${props.styles.frameParent3} hoverButtons`}
                          style={{
                            width: "100%",
                            // height: "19vh",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                        {/* </Link> */}
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>

              {/* Static elements on top of the image */}
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: "99",
                  width: "100%",
                }}
              >
                <div
                  style={{ zIndex: "99", cursor: "pointer" }}
                  onClick={() => handleHeartClick(truck._id)}
                  className={`${props.styles.mdicardsHeartOutlineIcon}  ${
                    selectedHearts.includes(`${truck._id}`)
                      ? props.styles.selectedHeart
                      : ""
                  }`}
                >
                  <img
                    onKeyDown={() => {}}
                    role="button"
                    alt=""
                    style={{
                      zIndex: "99",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    src={
                      selectedHearts.includes(`${truck._id}`)
                        ? "generics/redHeartIcon.svg"
                        : "generics/mdicardsheartoutline.svg"
                    }
                  />
                </div>
                <div className={props.styles.badgesOnCardParent}>
                  <div className={props.styles.badgesOnCard2}>
                    <img
                      className={props.styles.iconoutlinesearch}
                      alt=""
                      src="/generics/iconoutlineroybam2.svg"
                    />
                  </div>
                  {/* <div className={props.styles.badgesOnCard3}>
                    <img
                      className={props.styles.iconoutlinesearch}
                      alt=""
                      src="/generics/rivipcrownline1.svg"
                    />
                  </div>
                  <div className={props.styles.badgesOnCard4}>
                    <img
                      className={props.styles.iconoutlinesearch}
                      alt=""
                      src="/generics/iconoutlinechevrondoubleup1.svg"
                    />
                  </div> */}
                </div>
              </div>
            </div>

            {/* Truck Details */}
            <div className={props.styles.component31}>
              <div
                className={props.styles.peterbilt579Parent}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5%",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className={props.styles.text}
                    // style={{ textTransform: "capitalize" }}
                  >
                    {truck.year}&nbsp;
                    {truck.manufacturer
                      ? truck.manufacturer.charAt(0).toUpperCase() +
                        truck.manufacturer.slice(1).toLowerCase()
                      : ""}
                    &nbsp;
                    {truck.model}
                  </div>
                  {/* <div className={props.styles.vecicleCardrectangle1271}>
                    <div
                      className={props.styles.vecicleCardrectangle1271Child}
                    />
                  </div> */}
                </div>
                <div
                  className={props.styles.component31Inner}
                  style={{ paddingRight: "5%" }}
                >
                  <div className={props.styles.dayWrapper}>
                    <div className={props.styles.milesAway}>
                      <span className={props.styles.span}>
                        ${truck.rentPerDay}
                      </span>
                      <b>&nbsp;/&nbsp;</b>
                      <span className={props.styles.span}>Week</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={props.styles.toledoOhio43612Parent}>
                {/* <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div> */}
                {/* <div className={props.styles.frameWrapper}>
                  <div className={props.styles.materialSymbolsstarParent}>
                    <img
                      className={props.styles.materialSymbolsstarIcon}
                      alt=""
                      src="/generics/materialsymbolsstar.svg"
                    />
                    <div className={props.styles.milesAway}>5.0 (11)</div>
                  </div>
                </div> */}
                {/* <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div> */}

                <div
                  className={props.styles.iconoutlinetruckParent}
                  style={{width: "100% !important"}}
                >
                  <div
                    className={props.styles.tandemTractor}
                    style={{
                      textTransform: "capitalize",
                      display: "flex",
                      alignItems: "center",
                      gap: "2%",
                      width: "100%",
                    }}
                  >
                    <div>{truck.vehicleType}</div>
                    <div className={props.styles.vecicleCardrectangle1271}>
                      <div
                        className={props.styles.vecicleCardrectangle1271Child}
                      />
                    </div>
                    <div>{truck.vehicleSubtype}</div>
                  </div>
                </div>
                {truck.vehicleType == "trailer" && (
                  <>
                    <div className={props.styles.iconoutlinetruckParent}>
                      <div className={props.styles.mileage}>Mileage:</div>
                      <img
                        className={props.styles.iconoutlinetruck}
                        alt=""
                        src="/generics/iconoutlinetruck.svg"
                      />
                      <div className={props.styles.k}>{`${
                        truck.mileage / 1000
                      }k `}</div>
                    </div>
                  </>
                )}
                <div className={props.styles.iconoutlinetruckParent}>
                  <div className={props.styles.mileage}>Location:</div>
                  {truck.state?.toUpperCase()}, {truck.city?.toUpperCase()}
                </div>

                {/* <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div> */}
                {userLocation && (
                  <div className={props.styles.iconoutlinetruckParent}>
                    <div className="d-flex">
                      <div className={props.styles.k}>
                        {calculateDistance(
                          userLocation?.latitude,
                          userLocation?.longitude,
                          truck?.position?.lat,
                          truck?.position?.lon,
                          truck
                        )}
                      </div>
                      <div className={props.styles.mileage}>
                        &nbsp;Miles Away
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className={props.styles.vecicleCardrectangle1271}>
                  <div className={props.styles.vecicleCardrectangle1271Child} />
                </div> */}

                <div className={props.styles.materialSymbolsstarParent}>
                  <img
                    className={props.styles.pointMapIcon}
                    alt=""
                    src="/generics/point-map.svg"
                  />
                </div>
              </div>
              {truck.status === "inrent" && (
                <p style={{color: "red"}}>
                  <b>In rent</b>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TrucksList;
