import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OwnProps, RootState } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import RegistrationOwnerFirst from "../RegistrationOwnerFirst";
import RegistrationOwnerShare from "../RegistrationOwnerShare";
import RegistrationOwnerDetails from "../RegistrationOwnerDetails";
import AddOrganizationList from "../../components/Registration/AddOrganizationList";
import RegistrationOwnerDetails2 from "../RegistrationOwnerDetails2";
import RegistrationOwnerPhoneVerification from "../RegistrationOwnerPhoneVerification";
import AddOrganizationVerification2 from "../../components/Registration/AddOrganizationVerification2";
import RegistrationOwnerFinal from "../RegistrationOwnerFinal";
import RegistrationOwnerFinalFiles from "../RegistrationOwnerFinalFiles";
import { updateRegistrationData as handleUpdateRegistrationData } from "../../store/actions/registrationActions";

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const mapDispatchToProps = {
  handleUpdateRegistrationData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const OwnerRegistration: React.FC<OwnProps & PropsFromRedux> = ({
  registrationData,
  handleUpdateRegistrationData,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleNextButtonClick = (step?: number) => {
    if (step !== undefined) {
      setCurrentStep(step);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackButtonClick = () => {
    if (currentStep === 1) {
      navigate("/registration/driver");
    }
    setCurrentStep(currentStep - 1);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <RegistrationOwnerFirst
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={() => { }}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 2:
        return (
          <>
            <RegistrationOwnerShare
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={() => { }}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 3:
        return (
          <>
            <RegistrationOwnerDetails
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={() => { }}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 4:
        return (
          <>
            <AddOrganizationList
              onSkipButtonClick={() => handleNextButtonClick(5)}
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 5:
        return (
          <>
            <RegistrationOwnerDetails2
              onBackButtonClick={handleBackButtonClick}
              onSkipButtonClick={() => handleNextButtonClick(6)}
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              registerData={registrationData}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
            />
          </>
        );
      case 6:
        return (
          <>
            <RegistrationOwnerPhoneVerification
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => { }}
              role="button"
              registerData={registrationData}
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );
      case 7:
        return (
          <>
            <AddOrganizationVerification2
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onSkipBackwards={() => handleNextButtonClick(4)}
            />
          </>
        );
      case 8:
        return (
          <>
            <RegistrationOwnerFinal
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );
      case 9:
        return (
          <>
            <RegistrationOwnerFinalFiles
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => { }}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {renderCurrentStep()}
    </>
  );
};

export default connector(OwnerRegistration);
