import { useState, useEffect, useRef } from "react";
import styles from "./css/AddTruckExpirationDate2.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { TruckRegistrationData, RootStateTruckAdd } from "../../store/types";
import { connect } from "react-redux";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL } from "../../utils/UrlConstants";
import { AddtruckExpirationDate2Props, SelectedFiles } from "./types";

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckExpirationDate2 = connect(
  mapStateToProps,
  mapDispatchToProps
);

const AddtruckExpirationDate2: React.FC<AddtruckExpirationDate2Props> = ({
  onNextButtonClick,
  updateTruckRegistrationData,
  onBackButtonClick,
  setFileNames,
  truckAddRegistrationData,
  startDate,
  endDate,
  insurranceStartDate,
  insurranceEndDate,
  inspectionDate,
  fileNames,
  idToUpdate,
  currentStep,
}) => {
  const [selectedImages, setSelectedImages] = useState<SelectedFiles>({
    registrationFile: null,
    insurranceFile: null,
    inspectionFile: null,
  });
  const formatDateToString = (date: Date | null | undefined): string => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${month}-${day}-${year}`;
  };
  const fileInputRef1 = useRef<HTMLInputElement | null>(null);
  const fileInputRef3 = useRef<HTMLInputElement | null>(null);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);

  const handleDivClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    const singleImageUpload = async () => {
      const formData = new FormData();

      Object.entries(selectedImages).forEach(([fieldName, file]) => {
        if (file) {
          formData.append(fieldName, file);
        }
      });

      try {
        const response = await fetch(
          `${REACT_APP_UPLOAD_API_BASE_URL}/upload/truck/files`,
          {
            method: "POST",
            headers: getAuthHeaders(),
            body: formData,
          }
        ).then((r) => r.json());
        if (response.fileLinks) {
          successToast("Images uploaded successfully");
          const updatedRegistrationData: Partial<TruckRegistrationData> = {
            ...truckAddRegistrationData,
            files: {
              ...(truckAddRegistrationData?.images || {}),
              vehicleRegistrationFile: response.fileLinks.registrationFile,
              vehicleInsuranceFile: response.fileLinks.insurranceFile,
              technicalInspectionFile: response.fileLinks.inspectionFile,
            },
          };

          updateTruckRegistrationData(updatedRegistrationData);
        } else {
          errorToast("Failed to upload image");
        }
      } catch (error: any) {
        errorToast(`Error uploading image: ${error.message}`);
      }
    };

    if (isFileChanged) singleImageUpload();
    setIsFileChanged(false);
  }, [selectedImages, isFileChanged]);

  const handleNextClick = async () => {

    if (!selectedImages.registrationFile && !idToUpdate?.files?.vehicleRegistrationFile) {
      errorToast(
        "Please upload registration file."
      );

      return;
    }

    onNextButtonClick();
  };

  useEffect(() => {
    handleStartDate();
  }, [startDate]);

  useEffect(() => {
    handleEndDate();
  }, [endDate]);

  useEffect(() => {
    handleinspectionDate();
  }, [inspectionDate]);

  useEffect(() => {
    handleInsurranceStartDate();
  }, [insurranceStartDate]);

  useEffect(() => {
    handleInsurranceEndDate();
  }, [insurranceEndDate]);

  const handleStartDate = () => {
    const formattedStartDate = formatDateToString(startDate);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      registrationStartDate: formattedStartDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleinspectionDate = () => {
    const formattedDate = formatDateToString(inspectionDate);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      inspectionDate: formattedDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEndDate = () => {
    const formattedDate = formatDateToString(endDate);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      registrationEndDate: formattedDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleInsurranceStartDate = () => {
    const newStartDate = formatDateToString(insurranceStartDate);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      insuranceStartDate: newStartDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleInsurranceEndDate = () => {
    const newEndDate = formatDateToString(insurranceEndDate);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      insuranceEndDate: newEndDate,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof SelectedFiles
  ) => {
    const file = event.target.files?.[0];

    if (!file) return;

    // Get file extension as a fallback for type validation
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (fileExtension !== "pdf") {
      errorToast("Please upload a valid PDF file.");
      return;
    }

    setSelectedImages((prevState) => ({
      ...prevState,
      [fieldName]: file,
    }));

    setFileNames((prevState: any) => ({
      ...prevState,
      [fieldName]: file.name,
    }));

    setIsFileChanged(true);
  };

  return (
    <div className={styles.registerATruckOrEdit5P}>
      <main className={styles.frameA}>
        {currentStep != 8 && (
          <div className={styles.frameH}>
            <div className={styles.textButton} onClick={onBackButtonClick}>
              <img className={styles.icon} loading="eager" alt="" src="/generics/icon.svg" />
              <div className={styles.inputFieldText}>Back</div>
            </div>
            <div className={styles.inputText}>5 of 6</div>
          </div>
        )}
        <div className={styles.userNameParent}>
          <h2 className={styles.userName}>
            Registration expiration date&nbsp;
          </h2>
          <div className={styles.inputText1}>
            This documentation is needed in order to list your vehicle.
            Supported formats: PDF.
          </div>
        </div>
        <section className={styles.nATheseframesseemtoberepeati}>
          <div className={styles.fileUploadFrame}>
            <div className={styles.frameTextInputParent}>
              <div className={styles.frameTextInput}>
                <div className={styles.inputText2}>Registration</div>
                <div
                  className={`${styles.addImageButtonWrapper} hoverButtons`}
                >
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                    onClick={() => handleDivClick(fileInputRef1)}
                  >
                    <input
                      ref={fileInputRef1}
                      type="file"
                      accept="application/pdf"
                      id="fileInput-registrationFile"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleFileChange(event, "registrationFile")
                      }
                    />
                    <div className={styles.addImageButton}>
                      <img
                        className={styles.iconoutlinedocument}
                        loading="eager"
                        alt=""
                        src="/generics/pdfdoc.svg"
                      />
                      <div className={styles.frameInputWithLabelInputT}>
                        {fileNames["registrationFile"] ? (
                          <div className={styles.fileName}>
                            {fileNames["registrationFile"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>
                              Add PDF file
                            </div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.files.vehicleRegistrationFile
                                ? idToUpdate?.files.vehicleRegistrationFile
                                  .split("/")
                                  .pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.inputFieldInputWithLabel} />
            <div className={styles.inputFieldInputWithLabel1}>
              <div className={styles.inputText12}>
                We will not use your insurance data in any way in the future,
                this is required until another driver rents your truck.
              </div>
            </div>
            <div className={styles.inputFieldInputWithLabel3} />
            <div className={styles.inputFieldInputWithLabel4}>
              <div className={styles.inputTextParent}>
                <div className={styles.inputText2}>
                  Technical inspection (Optional)
                </div>
                <div className={`${styles.frameWrapper} hoverButtons`}>
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                    onClick={() => handleDivClick(fileInputRef3)}
                  >
                    <input
                      ref={fileInputRef3}
                      type="file"
                      accept="application/pdf"
                      id="fileInput-inspectionFile"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleFileChange(event, "inspectionFile")
                      }
                    />
                    <div className={`${styles.iconoutlinedocumentParent}`}>
                      <img
                        className={styles.iconoutlinedocument2}
                        loading="eager"
                        alt=""
                        src="/generics/pdfdoc.svg"
                      />
                      <div className={styles.addImageParent}>
                        {fileNames["inspectionFile"] ? (
                          <div className={styles.fileName}>
                            {fileNames["inspectionFile"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>
                              Add PDF file
                            </div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.files.technicalInspectionFile
                                ? idToUpdate?.files.technicalInspectionFile
                                  .split("/")
                                  .pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className={styles.inputText12}>
                We will not use your insurance data in any way in the future,
                this is required until another driver rents your truck.
              </div>
            </div>
          </div>
          {currentStep != 8 && (
            <div className={styles.buttonParent}>
              <button className={styles.button4} style={{ display: "none" }}>
                <div className={styles.textButtonText}>Save as draft</div>
              </button>
              <div className={styles.addImageButtonFrame}>
                <button className={styles.button5} onClick={onBackButtonClick}>
                  <div className={styles.inputWithLabel6}>Back</div>
                </button>
                <button className={styles.button6} onClick={handleNextClick}>
                  <div className={styles.text2}>Next</div>
                </button>
              </div>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default connectorAddTruckExpirationDate2(AddtruckExpirationDate2);
