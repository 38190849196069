import { useEffect, useState } from "react";
import styles from "./css/AddTruckTypeAndDetails.module.css";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { RootStateTruckAdd, TruckRegistrationData } from "@/src/store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { states, truckEngines, subtypeValues as options, subtypeTrucks, subtypeTrailers, subtypeBoxTrucks, truckManufacturers, trailerManufacturers } from "../../lib/staticData";
import { errorToast } from "../../components/Toast/ToastMessage";
import { AddTruckTypeAndDetailsProps } from "./types";

export type PropsFromReduxAddTruckTypeAndDetails = ConnectedProps<
  typeof connectorAddTruckTypeAndDetails
>;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckTypeAndDetails = connect(
  mapStateToProps,
  mapDispatchToProps
);

const AddTruckTypeAndDetails: React.FC<AddTruckTypeAndDetailsProps> = ({
  onNextButtonClick,
  updateTruckRegistrationData,
  onBackButtonClick,
  setPlateState,
  setPlateNumber,
  setModel,
  setEngine,
  setYear,
  setYearValue,
  setMileage,
  setSelectedTruckSubtype,
  setSelectedTruckSubtype2,
  setManufacturerValue,
  setEngineValue,
  setManufaturer,
  truckAddRegistrationData,
  manufacturerValue,
  engineValue,
  manufacturer,
  plateState,
  plateNumber,
  model,
  engine,
  year,
  yearValue,
  plateStateValue,
  mileage,
  selectedTruckSubtype,
  selectedTruckSubtype2,
  idToUpdate,
  currentStep,
}) => {
  const handleNextClick = () => {
    if (validateForm()) {
      console.log("yes");
      onNextButtonClick();
    } else {
      errorToast("Please fill all fields");
    }
  };

  const reduxState = useSelector((state: any) => state);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState<boolean>(false);
  const [isDropdownOpenManufacturer, setIsDropdownOpenManufacturer] = useState<boolean>(false);
  const [isDropdownOpenEngine, setIsDropdownOpenEngine] = useState<boolean>(false);
  const [isDropdownOpenYear, setIsDropdownOpenYear] = useState<boolean>(false);
  const [isDropdownOpenPlateState, setIsDropdownOpenPlateState] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [subtypeValue, setSubtypeValue] = useState<string>("");
  const [requiredField, setRequiredField] = useState<string>("");

  const years = Array.from({ length: 50 }, (_, index) => 2026 - index);

  const validateForm = () => {
    if (
      (!manufacturer ||
        !plateState ||
        !plateNumber ||
        !yearValue ||
        !selectedTruckSubtype ||
        !selectedTruckSubtype2) &&
      !idToUpdate
    ) {
      const emptyField = !selectedTruckSubtype
        ? "selectedTruckSubtype"
        : !selectedTruckSubtype2
          ? "selectedTruckSubtype2"
          : !manufacturer
            ? "manufacturer"
            : !yearValue
              ? "year"
              : !plateState
                ? "plateState"
                : !plateNumber
                  ? "plateNumber"
                  : "";
      setRequiredField(emptyField);
      return false;
    }
    return true;
  };

  const toggleDropdown = () => {
    closeDropDowns();
    setIsDropdownOpen1(!isDropdownOpen1);
  };
  const toggleDropdownManufacturer = () => {
    closeDropDowns();
    setIsDropdownOpenManufacturer(!isDropdownOpenManufacturer);
  };

  const toggleDropdownEngine = () => {
    closeDropDowns();
    setIsDropdownOpenEngine(!isDropdownOpenEngine);
  };

  const toggleDropdownYear = () => {
    closeDropDowns();
    setIsDropdownOpenYear(!isDropdownOpenYear);
  };

  const toggleDropdownPlateState = () => {
    closeDropDowns();
    setIsDropdownOpenPlateState(!isDropdownOpenPlateState);
  };

  const closeDropDowns = () => {
    setIsDropdownOpenPlateState(false);
    setIsDropdownOpenYear(false);
    setIsDropdownOpenEngine(false);
    setIsDropdownOpenManufacturer(false);
    setIsDropdownOpen1(false);
  };

  const handleModel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newModel = e.target.value;
    setModel(newModel);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      model: newModel,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setMileage("");
      return;
    }
    const newMileage = e.target.value;
    setMileage(newMileage);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      mileage: parseFloat(newMileage),
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePlateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPlateNumber = e.target.value;
    setPlateNumber(newPlateNumber);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateNumber: newPlateNumber,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleManufacturer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newManufacturer = e.target.value;
    setManufacturerValue(value);
    setIsDropdownOpenManufacturer(true);
    setManufaturer(newManufacturer);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      manufacturer: newManufacturer,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setYearValue(value);
    setIsDropdownOpenYear(true);
    const newYear = parseInt(e.target.value, 10);
    setYear(newYear);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      year: newYear,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEngine = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEngineValue(value);
    setIsDropdownOpenEngine(true);
    const newEngine = e.target.value;
    setEngine(newEngine);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      engine: newEngine,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePlateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newPlateState = e.target.value;
    setPlateState(value);
    setIsDropdownOpenPlateState(true);
    setPlateState(newPlateState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateState: newPlateState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleSelectSubtype = (subtype: string) => {
    setSelectedTruckSubtype2("");
    setSelectedTruckSubtype((prevSubtype: any) => {
      if (prevSubtype === subtype) {
        const updatedRegistrationData: Partial<TruckRegistrationData> = {
          ...truckAddRegistrationData,
          vehicleType: "",
          vehicleSubtype: "",
        };
        updateTruckRegistrationData(updatedRegistrationData);
        return null;
      }

      const updatedRegistrationData: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        vehicleType: subtype,
        vehicleSubtype: "",
      };
      updateTruckRegistrationData(updatedRegistrationData);
      return subtype;
    });
  };

  const handleSubtypeVechicleSelect = (option: any) => {
    const newSubtype2 = option;
    setIsDropdownOpen1(!isDropdownOpen1);
    setSelectedTruckSubtype2(newSubtype2);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      vehicleSubtype: newSubtype2,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  useEffect(() => {
    //REMOVE LATER !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    selectedTruckSubtype == "trailer" ? setMileage("0") : null;
  }, []);

  const handleManufacturerSelect = (option: any) => {
    const newManufacturer = option;
    setSelectedOption(option);
    setIsDropdownOpenManufacturer(!isDropdownOpenManufacturer);
    setManufaturer(newManufacturer);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      manufacturer: newManufacturer,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEngineSelect = (option: any) => {
    setEngine(option);
    setIsDropdownOpenEngine(!isDropdownOpenEngine);

    const newEngine = option;
    setEngine(newEngine);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      engine: newEngine,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePlateStateSelected = (option: any) => {
    setPlateState(option);
    setIsDropdownOpenPlateState(!isDropdownOpenPlateState);
    const newPlateState = option;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateState: newPlateState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleYearSelect = (option: any) => {
    setYearValue(option);
    setIsDropdownOpenYear(!isDropdownOpenYear);
    const newYear = option;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      year: newYear,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(subtypeValue.toLowerCase())
  );

  let optionsToDisplay;

  switch (selectedTruckSubtype) {
    case "truck":
      optionsToDisplay = subtypeTrucks; // Array for trucks
      break;
    case "trailer":
      optionsToDisplay = subtypeTrailers; // Array for trailers
      break;
    case "boxtruck":
      optionsToDisplay = subtypeBoxTrucks; // Array for box trucks
      break;
    default:
      optionsToDisplay = filteredOptions; // Default case if none match
      break;
  }

  const vehicleType = reduxState.truckAddRegistrationData.vehicleType;
  const manufacturers =
    vehicleType === "trailer" ? trailerManufacturers : truckManufacturers;

  const filteredTruckOptions = manufacturers.filter((option) =>
    option.toLowerCase().includes(manufacturerValue.toLowerCase())
  );

  const filteredEngineOptions = truckEngines.filter((option) =>
    option.toLowerCase().includes(engineValue.toLowerCase())
  );

  const filteredYearOptions = years.filter((option) =>
    option.toString().includes(yearValue.toString())
  );

  const filteredPlateState = states.filter((option) =>
    option.toLowerCase().includes(plateStateValue.toLowerCase())
  );

  const handleSubtypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSubtypeValue(value);
    setIsDropdownOpen1(true);
  };

  return (
    <div className={styles.registerATruckOrEdit2P}>
      <section className={styles.frameParent}>
        <div className={styles.component35Wrapper}>
          <div className={styles.component35}>
            {currentStep != 8 && (
              <div className={styles.textButtonParent}>
                <div className={styles.textButton} onClick={onBackButtonClick}>
                  <img
                    className={styles.icon}
                    loading="eager"
                    alt=""
                    src="/generics/icon.svg"
                  />
                  <div className={styles.inputPrimary}>Back</div>
                </div>
                <div className={styles.inputText}>2 of 6</div>
              </div>
            )}
          </div>
          <div className={styles.userNameParent}>
            <h2 className={styles.userName}>Type & Details</h2>
            <div className={styles.inputText1}></div>
          </div>
        </div>
        <div className={styles.inputLabelWrapper}>
          <div className={styles.basicInput}>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputText2} style={{ color: requiredField == "selectedTruckSubtype" ? "red" : "" }}>
                Vehicle Type*
              </div>
              <div className={styles.tagParentContainer}>
                <div className={styles.tagParent}>
                  <div
                    className={`${styles.tag} hoverButtons  ${selectedTruckSubtype == "truck" ? styles.selectedTag : ""
                      }`}
                    onClick={() => handleSelectSubtype("truck")}
                  >
                    <ReactSVG
                      className={`${selectedTruckSubtype === "truck"
                        ? styles.earnRevenueFromYourIdleFl1_selected
                        : styles.earnRevenueFromYourIdleFl1
                        }`}
                      style={{ strokeWidth: "6", width: "60px", height: "40px" }}
                      beforeInjection={(svg) => {
                        svg.setAttribute("stroke-width", "6");
                      }}
                      src="/truck_types/17_thick.svg"
                    />
                    <div className={styles.reefer1}>Truck</div>
                  </div>
                  <div
                    className={`${styles.tag} hoverButtons ${selectedTruckSubtype?.includes("trailer")
                      ? styles.selectedTag
                      : ""
                      }`}
                    onClick={() => handleSelectSubtype("trailer")}
                  >
                    <ReactSVG
                      className={`${selectedTruckSubtype === "trailer"
                        ? styles.earnRevenueFromYourIdleFl1_selected
                        : styles.earnRevenueFromYourIdleFl1
                        }`}
                      style={{ strokeWidth: "6", width: "60px", height: "40px" }}
                      beforeInjection={(svg) => {
                        svg.setAttribute("stroke-width", "6");
                      }}
                      src="/truck_types/Trailer_thick.svg"
                    />
                    <div className={styles.reefer2}>Trailer</div>
                  </div>
                </div>
                <div
                  className={`${styles.tag} hoverButtons ${selectedTruckSubtype?.includes("boxtruck")
                    ? styles.selectedTag
                    : ""
                    }`}
                  onClick={() => handleSelectSubtype("boxtruck")}
                >
                  <ReactSVG
                    className={`${selectedTruckSubtype === "boxtruck"
                      ? styles.earnRevenueFromYourIdleFl1_selected
                      : styles.earnRevenueFromYourIdleFl1
                      }`}
                    style={{
                      strokeWidth: "6",
                      fill: "red",
                      width: "60px",
                      height: "40px",
                    }}
                    beforeInjection={(svg) => {
                      svg.setAttribute(
                        "fill",
                        selectedTruckSubtype === "boxtruck" ? "orange" : "none"
                      );

                      svg.setAttribute("stroke-width", "6");
                    }}
                    src="/truck_types/Box_Trucks_thick.svg"
                  />
                  <div className={styles.reefer4}>Box Truck</div>
                </div>
              </div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputText2}>Vehicle Subtype*</div>
              <div
                className={styles.basicInput2}
                style={{
                  background: selectedTruckSubtype ? "revert" : "#e7e7e7",
                  border:
                    requiredField == "selectedTruckSubtype2"
                      ? "1px solid red"
                      : "",
                }}
              >
                <input
                  className={styles.inputText7}
                  placeholder="Subtype"
                  type="text"
                  value={selectedTruckSubtype2 || ""}
                  onChange={handleSubtypeChange}
                  onClick={selectedTruckSubtype ? toggleDropdown : undefined}
                />

                <button className={styles.dropdownButton} onClick={selectedTruckSubtype ? toggleDropdown : undefined}>
                  <FontAwesomeIcon key={"1"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                </button>
                {isDropdownOpen1 && (
                  <Container>
                    {optionsToDisplay.map((option, index) => (
                      <Item
                        style={{ textTransform: "capitalize" }}
                        key={index}
                        className={`hoverDropdown ${selectedTruckSubtype2 == option ? "text-theme" : ""
                          } `}
                        onClick={() => handleSubtypeVechicleSelect(option)}
                      >
                        {option}
                      </Item>
                    ))}
                  </Container>
                )}
              </div>
            </div>
            <div className={styles.frameChild} />
            <div className={styles.inputWithLabelGroup}>
              <div className={styles.inputWithLabelContainer}>
                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputText2}>Manufacturer*</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownManufacturer}
                    style={{
                      border:
                        requiredField == "manufacturer" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Manufacturer"
                      type="text"
                      value={manufacturer || idToUpdate?.manufacturer}
                      onChange={handleManufacturer}
                    />
                    <button className={styles.dropdownButton} onClick={toggleDropdownManufacturer}>
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                    </button>
                    {isDropdownOpenManufacturer && (
                      <Container>
                        {filteredTruckOptions.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${manufacturer == option ? "text-theme" : ""
                              } `}
                            onClick={() => handleManufacturerSelect(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputText2}>Model</div>
                  <div className={styles.basicInput2}>
                    <input
                      className={styles.inputText7}
                      placeholder="Model"
                      type="text"
                      value={model}
                      onChange={handleModel}
                      maxLength={25}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.inputWithLabelContainer}>
                {selectedTruckSubtype != "trailer" ? (
                  <div className={styles.inputWithLabelParent}>
                    <div className={styles.inputText2}>Engine&nbsp;</div>
                    <div
                      className={styles.basicInput2}
                      onClick={toggleDropdownEngine}
                      style={{
                        border:
                          requiredField == "engine" ? "1px solid red" : "",
                      }}
                    >
                      <input
                        className={styles.inputText7}
                        placeholder="Engine"
                        type="text"
                        value={engine}
                        onChange={handleEngine}
                      />
                      <button className={styles.dropdownButton} onClick={toggleDropdownEngine}>
                        <FontAwesomeIcon key={"1"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                      </button>
                      {isDropdownOpenEngine && (
                        <Container>
                          {filteredEngineOptions.map((option, index) => (
                            <Item
                              key={index}
                              className={`hoverDropdown ${engine == option ? "text-theme" : ""
                                } `}
                              onClick={() => handleEngineSelect(option)}
                            >
                              {option}
                            </Item>
                          ))}
                        </Container>
                      )}
                    </div>
                  </div>
                ) : null}

                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputText2}>Year</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownYear}
                    style={{
                      border: requiredField == "year" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Year"
                      type="text"
                      value={yearValue === 0 ? "" : yearValue}
                      onChange={handleYear}
                    />
                    <button className={styles.dropdownButton} onClick={toggleDropdownYear}>
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                    </button>
                    {isDropdownOpenYear && (
                      <Container>
                        {filteredYearOptions.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${year == option ? "text-theme" : ""
                              } `}
                            onClick={() => handleYearSelect(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameChild} />
            <div className={styles.inputWithLabelGroup}>
              <div className={styles.inputWithLabelContainer}>
                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputText2}>Plate State</div>
                  <div
                    className={styles.basicInput2}
                    onClick={toggleDropdownPlateState}
                    style={{
                      border:
                        requiredField == "plateState" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Plate State"
                      type="text"
                      value={plateState || idToUpdate?.plateState}
                      onChange={handlePlateState}
                    />
                    <button className={styles.dropdownButton} onClick={toggleDropdownPlateState}>
                      <FontAwesomeIcon key={"1"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                    </button>
                    {isDropdownOpenPlateState && (
                      <Container>
                        {filteredPlateState.map((option, index) => (
                          <Item
                            key={index}
                            className={`hoverDropdown ${plateState == option ? "text-theme" : ""
                              } `}
                            onClick={() => handlePlateStateSelected(option)}
                          >
                            {option}
                          </Item>
                        ))}
                      </Container>
                    )}
                  </div>
                </div>
                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputText2}>Plate Number</div>
                  <div
                    className={styles.basicInput2}
                    style={{
                      border:
                        requiredField == "plateNumber" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      placeholder="Plate Number"
                      type="text"
                      value={plateNumber}
                      onChange={handlePlateNumber}
                      maxLength={25}
                    />
                  </div>
                </div>
              </div>
              {selectedTruckSubtype != "trailer" ? (
                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputText2}>Mileage*</div>
                  <div
                    className={styles.basicInput2}
                    style={{
                      border:
                        requiredField == "mileage" ? "1px solid red" : "",
                    }}
                  >
                    <input
                      className={styles.inputText7}
                      style={{ textAlign: "end" }}
                      placeholder="Mileage"
                      type="text"
                      value={
                        mileage ||
                        (idToUpdate?.mileage && isNaN(Number(mileage))
                          ? idToUpdate?.mileage
                          : "")
                      }
                      onChange={handleMileage}
                      maxLength={20}
                    />
                    <span style={{ paddingLeft: "5px" }}><b>Mi</b></span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {currentStep != 8 && (
            <div className={styles.buttonContainer}>
              <button className={styles.button6} style={{ display: "none" }}>
                <div className={styles.text3}>Save as draft</div>
              </button>
              <div className={styles.buttonParent1}>
                <button className={styles.button7} onClick={onBackButtonClick}>
                  <div className={styles.inputDropdownFrame}>Back</div>
                </button>
                <button className={styles.button8} onClick={handleNextClick}>
                  <div className={styles.text4}>Next</div>
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default connectorAddTruckTypeAndDetails(AddTruckTypeAndDetails);

const Container = styled.div`
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 24vh;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
