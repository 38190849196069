import React from 'react';
import styles from "./SubscriptionTable.module.css";
import { planDetails } from "./Constants";

const CheckMarkSvg =()=> {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
          fill="#10B981"/>
  </svg>
}

const InfoSvg =()=> {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.85638 7.5C7.31398 6.52901 8.54869 5.83333 10 5.83333C11.841 5.83333 13.3334 6.95262 13.3334 8.33333C13.3334 9.49953 12.2687 10.4792 10.8286 10.7555C10.3766 10.8422 10 11.2064 10 11.6667M10 14.1667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
      stroke="#94A3B8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}

const featureData = [
  {
    title: "hello",
    service1: <CheckMarkSvg/>,
    service2: <CheckMarkSvg/>,
    service3: <CheckMarkSvg/>,
    service4: <CheckMarkSvg/>,
    service5: <CheckMarkSvg/>,
  },
  {
    title: (
      <>
        hello&nbsp;
        <InfoSvg/>
      </>
    ),
    service1: "Unlimited",
    service2: <CheckMarkSvg/>,
    service3: <CheckMarkSvg/>,
    service4: <CheckMarkSvg/>,
    service5: <CheckMarkSvg/>,
  },
  {
    title:  <>
      {/*lobortis massa.*/}
      Molestie&nbsp;
      <InfoSvg/>
    </>,
    service1: "Unlimited",
    service2: <CheckMarkSvg/>,
    service3: <CheckMarkSvg/>,
    service4: <CheckMarkSvg/>,
    service5: <CheckMarkSvg/>,
  }, {
    title: <>
      {/*lobortis massa*/}
      Molestie.&nbsp;
      <div className={styles.newTag}>
        <div className={styles.newTagText}>New</div>
      </div>
    </>,
    service1: "Unlimited",
    service2: <CheckMarkSvg/>,
    service3: <CheckMarkSvg/>,
    service4: <CheckMarkSvg/>,
    service5: <CheckMarkSvg/>,
  },{
    title: <>
      {/*lobortis massa.*/}
      Molestie&nbsp;
      <div className={styles.commingSoonTag}>
        <div className={styles.commingSoonTagText}>Comming soon</div>
      </div>
    </>,
    service1: "Unlimited",
    service2: <CheckMarkSvg/>,
    service3: <CheckMarkSvg/>,
    service4: <CheckMarkSvg/>,
    service5: <CheckMarkSvg/>,
  },
];

const SubscriptionTable:React.FC=()=> {

  return (
    <div className={styles.featureContainer}>
      <div className={styles.tableWrapper}>

        {/*Header*/}
        <div className={styles.featureHeader}>
          <div className={styles.featureTitle}>
            <div className={styles.featureTitleText}>
              Features
            </div>
          </div>
          {planDetails.map((plan, index) => (
            <div key={index} className={styles.detailFreePlan}>
              <div className={styles.detailFreePlanButton} style={plan.titleColor}>{plan.title}</div>
              <div className={styles.detailFreePlanPrice}>
                <div className={styles.featurePlanPrice}>{plan.price}&nbsp;</div>
                <div className={styles.featurePlanDuration}>{plan.duration}</div>
              </div>
              <div className={styles.detailPlanBtn}>{plan.buttonText}</div>
            </div>
          ))}
        </div>
        {/*body*/}
        <>
          {featureData.map((feature, index) => (
            <div key={index}
                 className={styles.featureRowHeader}>

              <div className={styles.featureTitle}>
                <div className={styles.tableFeature}>
                  {feature.title}
                </div>
              </div>
              <div className={styles.tableCheckMark}>
                <div className={styles.tableFeatureContent}>{feature.service1}
                </div>
              </div>
              <div className={styles.tableCheckMark}>
                <div className={styles.tableFeatureContent}>{feature.service2}
                </div>
              </div>
              <div className={styles.tableCheckMark}>
                <div className={styles.tableFeatureContent}>{feature.service3}
                </div>
              </div>
              <div className={styles.tableCheckMark}>
                <div className={styles.tableFeatureContent}>{feature.service4}
                </div>
              </div>
              <div className={styles.tableCheckMark}>
                <div className={styles.tableFeatureContent}>{feature.service5}
                </div>
              </div>
            </div>
          ))}
        </>
      </div>
    </div>
      );
      }

      export default SubscriptionTable;