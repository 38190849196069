import React, { useEffect, useState } from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerPhoneVerification.module.css";
import { useSelector } from "react-redux";
import { RegistrationData, RootState } from "../store/types";

type VechicleToRentProps = {
  registerData?: RegistrationData;
  role: string;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onClick?: () => void;
};

const RegistrationOwnerPhoneVerification: React.FC<VechicleToRentProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const [selectedOption, setSelectedOption] = useState<"contact" | "company">(
    "contact"
  );
  const [confirmationSent, setConfirmationSent] = useState<boolean>(false);
  const [time, setTime] = useState<number>(80);
  const [expired, setExpired] = useState<boolean>(false);

  const handleRadioButtonChange = () => {
    setSelectedOption("contact");
  };

  const handleRadioButtonChange2 = () => {
    setSelectedOption("company");
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (confirmationSent) {
      timer = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
        } else {
          clearInterval(timer);
          setExpired(true);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [time, confirmationSent]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formatTime = (value: number) => (value < 10 ? `0${value}` : value);

  const handleReset = () => {
    setTime(80);
    setExpired(false);
  };

  const handleSendCodeClick = () => {
    setConfirmationSent(true);
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleNextButtonClick = () => {
    onNextButtonClick();
  };

  const reduxState = useSelector((state: RootState) => state);

  useEffect(() => {
    handleNextButtonClick();
  }, []);

  return (
    <div className={styles.vehicleOwner05}>
      <div className={styles.unsplashx60ygfg5soyParent}>
        <img
          className={styles.unsplashx60ygfg5soyIcon}
          alt=""
          src="/generics/unsplashx60ygfg5soy@2x.png"
        />
        <div className={styles.inputTextParent}>
          <div className={styles.inputText}>One place to manage everything</div>
          <div className={styles.inputText1}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
      </div>

      <div className={styles.vehicleOwner05Child} />
      <div className={styles.backButtonParent}>
        <div
          className={styles.textButton}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.textButtonGroup}>
            <div className={styles.textButton1}>
              <img className={styles.icon} alt="" src="/generics/icon2.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.frameParent}>
              <div className={styles.iconParent}>
                <img className={styles.icon} alt="" src="/generics/icon3.svg" />
                <div className={styles.userName}>Verification phone number</div>
              </div>
              <div className={styles.inputText3}>
                Thank you for registering on our website! We're excited to have
                you join our community. Before you can fully access your account
                and enjoy all the features, we kindly ask you to verify your
                phone number and e-mail address.
              </div>
              <img
                className={styles.groupChild}
                alt=""
                src="/generics/group-24561.svg"
              />
            </div>
            <div className={styles.frameChild} />
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText1}>
                We well send you a security code by SMS. Choose the number
              </div>
              <div className={styles.radiobuttonParent}>
                <div>
                  <label
                    className={styles.textDescription}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "7px",
                    }}
                  >
                    <input
                      style={{
                        height: "19px",
                        width: "19px",
                      }}
                      type="radio"
                      name="phoneNumber"
                      value="contact"
                      checked={selectedOption === "contact"}
                      onChange={handleRadioButtonChange}
                    />
                    Personal Number +7-1234-56-78
                  </label>
                </div>
                <div className={styles.radiobutton}>
                  <div>
                    <label
                      className={styles.textDescription}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <input
                        style={{
                          height: "19px",
                          width: "19px",
                        }}
                        type="radio"
                        name="phoneNumber"
                        value="company"
                        checked={selectedOption === "company"}
                        onChange={handleRadioButtonChange2}
                      />
                      Company Number +7-1234-56-78
                    </label>
                  </div>
                </div>
              </div>
              {confirmationSent ? (
                <div
                  className={`${
                    expired ? styles.button1 : styles.button2
                  } hoverButtons`}
                  style={expired ? {} : { pointerEvents: "none" }}
                >
                  <div
                    className={styles.text}
                    onKeyDown={() => {}}
                    role="button"
                    onClick={handleReset}
                  >
                    Resend code confirmation
                    {expired
                      ? " 0:00"
                      : ` ${formatTime(minutes)}:${formatTime(seconds)}`}
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.button1} hoverButtons`}
                  onKeyDown={() => {}}
                  role="button"
                  onClick={handleSendCodeClick}
                >
                  <div className={styles.text}>Send code confirmation</div>
                </div>
              )}
            </div>
            <div className={styles.frameChild} />
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Verification code</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputText6}
                    placeholder="000-000-00-0"
                    style={{ border: "none" }}
                  />
                </div>
              </div>
              <div className={styles.button3}>
                <div
                  className={`${styles.text} hoverButtons`}
                  onKeyDown={() => {}}
                  role="button"
                  onClick={handleNextButtonClick}
                >
                  Apply
                </div>
              </div>
            </div>
            <div className={`${styles.button4} hoverButtons`}>
              <div className={styles.text}>Next</div>
            </div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.textButtonGroup}>
            <div className={styles.textButton1}>
              <img className={styles.icon} alt="" src="/generics/icon2.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.iconGroup}>
              <img className={styles.icon} alt="" src="/generics/icon3.svg" />
              <div className={styles.userName}>Verification e-mail</div>
            </div>
            <div className={`${styles.button4} hoverButtons`}>
              <div className={styles.text} role="button" onClick={handleNextButtonClick}>Next</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationOwnerPhoneVerification;
