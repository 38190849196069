import React, { useRef, useState } from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerFinalFiles.module.css";
import { errorToast } from "../components/Toast/ToastMessage";
import { useNavigate } from "react-router-dom";
import { getAuthHeaders } from "../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL } from "../utils/UrlConstants";

type VechicleToRentProps = {
  onNextButtonClick?: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  role: string;
  onClick?: () => void;
};

const RegistrationOwnerFinalFiles: React.FC<VechicleToRentProps> = ({
  onBackButtonClick,
}) => {
  const navigate = useNavigate();
  const [fileOfIncorporation, setFileOfIncorporation] = useState<File | null>(
    null
  );
  const [w2, setW2] = useState<File | null>(null);
  const fileInputRef1 = useRef<HTMLInputElement | null>(null);
  const fileInputRef2 = useRef<HTMLInputElement | null>(null);
  const [noFilesUplodaed, setNoFilesUploaded] = useState<boolean | null>(null);

  const handleDivClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (type == "articleOfIncorporation") {
      setFileOfIncorporation(selectedFile);
    }

    if (type == "w2") {
      setW2(selectedFile);
    }
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleNextClick = async () => {
    if (fileOfIncorporation && w2) {
      try {
        setNoFilesUploaded(false);

        const apiUrl = `${REACT_APP_UPLOAD_API_BASE_URL}/upload/files/additionals`;

        const formData = new FormData();
        formData.append("articleFile", fileOfIncorporation);
        formData.append("w2File", w2);

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: getAuthHeaders(),
          body: formData,
        });
        const data = await response.json();

        if (!response.ok) {
          errorToast("File Upload Failed");
          console.error("File Upload Failed", response);
          setNoFilesUploaded(true);
        } else {
          navigate("/main");
        }

        console.log(data);
      } catch (error: any) {
        errorToast(`${error.message}`);
      }
    } else {
      setNoFilesUploaded(true);
    }
  };

  return (
    <div className={styles.frameParent}>
      <div className={styles.textButton} onClick={handleBackButtonClick} style={{ cursor: "pointer" }}>
        <img className={styles.icon} alt="" src="/generics/icon5.svg" />
        <div className={styles.reefer}>Back</div>
      </div>
      <div className={styles.userNameParent}>
        <div className={styles.userName}>Additional documents</div>
        <div className={styles.inputText1}></div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.textButtonGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.inputTextParent}>
              <div className={styles.reefer}>Article of incorporation</div>
              <div className={`${styles.addImage} hoverButtons`}>
                <div
                  className={styles.iconoutlinedocumentParent}
                  onKeyDown={() => { }}
                  role="button"
                  onClick={() => handleDivClick(fileInputRef1)}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef1}
                    style={{ display: "none" }}
                    onChange={(e) =>
                      handleFileChange(e, "articleOfIncorporation")
                    }
                  />
                  <img
                    className={styles.iconoutlinebell}
                    alt=""
                    src="/generics/iconoutlinedocument1.svg"
                  />
                  {fileOfIncorporation ? (
                    <>
                      <div className={styles.addImage1}>
                        {fileOfIncorporation.name}
                      </div>
                      <div className={styles.dragAndDrop}>
                        File Uploaded: {fileOfIncorporation.name}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.addImage1}>Add File</div>
                      <div className={styles.dragAndDrop}>
                        Click here to select file
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.inputTextParent}>
              <div className={styles.reefer}>W2 form (EIN-number)</div>
              <div className={`${styles.addImage} hoverButtons`}>
                <div
                  className={styles.iconoutlinedocumentParent}
                  onKeyDown={() => { }}
                  role="button"
                  onClick={() => handleDivClick(fileInputRef2)}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef2}
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "w2")}
                  />
                  <img
                    className={styles.iconoutlinebell}
                    alt=""
                    src="/generics/iconoutlinedocument1.svg"
                  />
                  {w2 ? (
                    <>
                      <div className={styles.addImage1}>{w2.name}</div>
                      <div className={styles.dragAndDrop}>
                        File Uploaded: {w2.name}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.addImage1}>Add File</div>
                      <div className={styles.dragAndDrop}>
                        Click here to select file
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className={styles.buttonParent1}>
            <div
              className={`${styles.button1} hoverButtons ${(!fileOfIncorporation || !w2) && styles.disabledButton
                }`}
              style={{
                backgroundColor: fileOfIncorporation && w2 ? "#fa6f32" : "",
                color: fileOfIncorporation && w2 ? "white" : "",
              }}
              onClick={handleNextClick}
            >
              <div className={styles.text}>Upload</div>
            </div>
          </div>
      </div>
      {noFilesUplodaed && (
        <div className={styles.errorMesage}>
          <span>Upload all files first!</span>
        </div>
      )}
    </div>
  );
};

export default RegistrationOwnerFinalFiles;
