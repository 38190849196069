import styles from '../../styles/BlogsPage/CommercialVehicles.module.css';
import { trucks, trailers, popularCities } from './Constants';

const CommercialVehicles = () => {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.commercialVehicles}>
            <h2 className={styles.sectionTitle}>COMMERCIAL VEHICLES</h2>
            <div className={styles.section}>
              <div className={styles.subSection}>
                <h3 className={styles.subTitle}>TRUCKS</h3>
                <ul className={styles.list}>
                  {trucks.map((truck, index) => (
                    <li key={index}>{truck}</li>
                  ))}
                </ul>
              </div>
              <div className={styles.subSection}>
                <h3 className={styles.subTitle}>TRAILERS</h3>
                <ul className={styles.list}>
                  {trailers.map((trailer, index) => (
                    <li key={index}>{trailer}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.popularCities}>
            <h2 className={styles.sectionTitle}>POPULAR CITIES</h2>
            <ul className={styles.grid}>
              {popularCities.map((city, index) => (
                <li key={index}>{city}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialVehicles;
