import { useState, useCallback, useEffect } from "react";
import styles from "./css/AddTruckSpecs.module.css";
import { useNavigate } from "react-router-dom";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { RootStateTruckAdd, TruckRegistrationData } from "@/src/store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { cargoOptions } from "../../lib/staticData";
import { errorToast } from "../../components/Toast/ToastMessage";
import { AddTruckSpecsProps } from "./types";
import { Item, Container } from "./styled";

export type PropsFromReduxAddTruckSpecs = ConnectedProps<
  typeof connectorAddTruckSpecs
>;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckSpecs = connect(mapStateToProps, mapDispatchToProps);

const AddTruckSpecs: React.FC<AddTruckSpecsProps> = ({
  gps,
  selectedTruckSubtype2,
  lengthValue,
  selectedTruckSubtype,
  currentStep,
  transmissionType,
  truckAddRegistrationData,
  dockHeight,
  liftGateType,
  allowTravelOutOfState,
  cargoValue,
  cargoSecurementTrack,
  idToUpdate,
  doorType,
  onNextButtonClick,
  updateTruckRegistrationData,
  onBackButtonClick,
  setDoorType,
  setLiftGateType,
  setDockHeight,
  setAllowTravelOutOfState,
  setGps,
  setTransmissionType,
  setLengthValue,
  setCargoSecurementTrack,
}) => {

  const navigate = useNavigate();
  const reduxState = useSelector((state: any) => state);
  const types = ["boxtruck", "dry van", "reefer", "box truck", "dryvan"];
  const verifyDoorType = types.some((type) => selectedTruckSubtype2.includes(type));
  const [requiredField, setRequiredField] = useState<string>("");
  const [isDropdownOpen1, setIsDropdownOpen1] = useState<boolean>(false);
  const [isCargoDropdown, setIsCargoDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (
      reduxState.truckAddRegistrationData?.vehicleType === "trailer" ||
      reduxState.truckAddRegistrationData?.vehicleType === "truck" ||
      reduxState.truckAddRegistrationData?.vehicleType === "boxtruck" ||
      idToUpdate?.vehicleType === "trailer" ||
      idToUpdate?.vehicleType === "boxtruck" ||
      idToUpdate?.vehicleType === "truck"
    ) {
    } else {
      handleNextClick(true);
    }
  }, []);

  const handleNextClick = (isSkippable?: boolean) => {
    if (!isSkippable) {
      if (validateForm()) {
        if (
          dockHeight &&
          !(Number(dockHeight) >= 36 && Number(dockHeight) <= 58)
        ) {
          errorToast("dock height should be between 36 to 58 inches");
          return;
        } else {
          onNextButtonClick();
        }
      } else {
        errorToast("Please fill all fields");
      }
    } else {
      onNextButtonClick();
    }
  };

  const handleDoorType = (state: string) => {
    setDoorType(state);
    const newDoorType = state;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      doorType: newDoorType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLiftGateType = (state: string) => {
    setLiftGateType(state);
    const newLiftGateType = state;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      liftGateType: newLiftGateType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleAllowTravelOutOfState = (state: boolean) => {
    const newAllowTravelOutOfState = state;
    setAllowTravelOutOfState(newAllowTravelOutOfState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      allowTravelOutOfState: newAllowTravelOutOfState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleGps = (state: boolean) => {
    setGps(state);
    const newGps = state;
    setGps(newGps);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      GPS: newGps,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleTransmissionType = (state: string) => {
    setTransmissionType(state);
    const newTransmissionType = state;
    setTransmissionType(newTransmissionType);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      transmissionType: newTransmissionType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const toggleDropdown = () => {
    closeDropDowns();
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const toggleCargoDropdown = () => {
    closeDropDowns();
    setIsCargoDropdown(!isCargoDropdown);
  };

  const closeDropDowns = () => {
    setIsDropdownOpen1(false);
    setIsCargoDropdown(false);
  };

  const lengthOptions =
    selectedTruckSubtype === "boxtruck"
      ? Array.from({ length: 22 }, (_, i) => (i + 5).toString())
      : ["40", "45", "48", "53"];

  const filteredCargo = cargoOptions.filter((option) =>
    option.toLowerCase().includes(cargoValue.toLowerCase())
  );

  const handleLength = (option: string) => {
    const newLength = { length: { feet: Number(option) } };
    setIsDropdownOpen1(!isDropdownOpen1);
    setLengthValue(newLength);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      length: { ...truckAddRegistrationData?.length, feet: Number(option) },
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleCargoValue = (option: string) => {
    const newCargo = option;
    setIsCargoDropdown(!isCargoDropdown);
    setCargoSecurementTrack(newCargo);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      cargoSecurementTrack: newCargo,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLengthValue(value);
    setIsDropdownOpen1(true);
  };

  const handleCargoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCargoSecurementTrack(value);
    setIsCargoDropdown(true);
  };

  const handleDockHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDockHeight = e.target.value;

    if (isNaN(Number(newDockHeight))) {
      setDockHeight("");
      return;
    }

    setDockHeight(newDockHeight);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      dockHeight: newDockHeight,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const onInputTextClick = useCallback(() => {
    navigate("/register-a-truck-or-edit-1-page-for-user");
  }, [navigate]);

  const validateForm = () => {
    if (
      !idToUpdate &&
      (!lengthValue ||
        (!transmissionType && !selectedTruckSubtype.includes("trailer")))
    ) {
      const emptyField = !lengthValue
        ? "lengthValue"
        : !transmissionType
          ? "transmissionType"
          : "";
      setRequiredField(emptyField);
      return false;
    }
    return true;
  };

  return (
    <div className={styles.registerATruckOrEdit3P}>
      <div className={styles.component35Parent}>
        <div className={styles.component35}>
          {currentStep != 8 && (
            <div className={styles.textButtonParent}>
              <div className={styles.textButton} onClick={onBackButtonClick}>
                <img className={styles.icon} alt="" src="/generics/icon.svg" />
                <div className={styles.text2}>Back</div>
              </div>
              <div className={styles.inputText} onClick={onInputTextClick}>
                3 of 6
              </div>
            </div>
          )}
          <div className={styles.userNameParent}>
            <div className={styles.userName}>
              Add Your Specs
            </div>
            <div className={styles.inputText1}></div>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.textButtonGroup}>
            <div className={styles.frameGroup}>
              {verifyDoorType && (
                <>
                  <div className={styles.inputWithLabelParent}>
                    <div className={styles.inputText2} style={{ color: requiredField == "doorType" ? "red" : "" }}>
                      Door type*
                    </div>
                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag} ${doorType === "swing" ? styles.selectedTag : ""
                          } hoverButtons`}
                        onClick={() => handleDoorType("swing")}
                      >

                        <span>Swing</span>
                      </div>
                      <div
                        className={`${styles.tag} ${doorType === "roll" ? styles.selectedTag : ""
                          } hoverButtons`}
                        onClick={() => handleDoorType("roll")}
                      >
                        <span>Roll</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputWithLabelParent}>
                    <div className={styles.inputText2} style={{ color: requiredField == "liftGateType" ? "red" : "" }}>
                      Lift gate*
                    </div>

                    <div className={styles.tagParent}>
                      <div
                        className={`${styles.tag} ${liftGateType.includes("railgate")
                          ? styles.selectedTag
                          : ""
                          } hoverButtons`}
                        onClick={() => handleLiftGateType("railgate")}
                      >
                        <span>Rail gate</span>
                      </div>
                      <div
                        className={`${styles.tag} ${liftGateType.includes("truckunder")
                          ? styles.selectedTag
                          : ""
                          } hoverButtons`}
                        onClick={() => handleLiftGateType("truckunder")}
                      >
                        <span>Truck under</span>
                      </div>
                      <div
                        className={`${styles.tag} ${liftGateType.includes("noliftgate")
                          ? styles.selectedTag
                          : ""
                          } hoverButtons`}
                        onClick={() => handleLiftGateType("noliftgate")}
                      >
                        <span>No lift gate</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={styles.inputWithLabelContainer}>
                {(selectedTruckSubtype.includes("boxtruck") ||
                  selectedTruckSubtype.includes("trailer")) && (
                    <div className={styles.inputWithLabelParent}>
                      <div className={styles.inputText2}>Length*</div>
                      <div
                        className={styles.dropdown1}
                        onClick={toggleDropdown}
                        style={{
                          border:
                            requiredField == "lengthValue"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <input
                          className={"w-100"}
                          placeholder="Length (feet)"
                          type="text"
                          value={
                            lengthValue?.length?.feet ||
                            idToUpdate?.length?.feet
                          }
                          onChange={handleLengthChange}
                        />
                        <FontAwesomeIcon key={"1"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                        {isDropdownOpen1 && (
                          <Container>
                            {lengthOptions.map((option, index) => (
                              <Item
                                key={index}
                                className={`hoverDropdown ${lengthValue == option ? "text-theme" : ""
                                  } `}
                                onClick={() => handleLength(option)}
                              >
                                {option}
                              </Item>
                            ))}
                          </Container>
                        )}
                      </div>
                    </div>
                  )}
                {!verifyDoorType && !(selectedTruckSubtype == "truck") && (
                  <div className={styles.inputWithLabelParent}>
                    <div className={styles.inputText2}>
                      Cargo Securement track
                    </div>
                    <div
                      className={styles.dropdown1}
                      onClick={toggleCargoDropdown}
                      style={{
                        border:
                          requiredField == "cargoSecurementTrack"
                            ? "1px solid red"
                            : "",
                      }}
                    >
                      <input
                        className={"w-100"}
                        placeholder="Cargo Securement track"
                        type="text"
                        value={
                          cargoSecurementTrack ||
                          idToUpdate?.cargoSecurementTrack
                        }
                        onChange={handleCargoChange}
                      />
                      <FontAwesomeIcon key={"2"} icon={faChevronDown} style={{ color: "#94A3B8" }} />
                      {isCargoDropdown && (
                        <Container>
                          {filteredCargo.map((option, index) => (
                            <Item
                              key={index}
                              className={`hoverDropdown ${cargoSecurementTrack == option
                                ? "text-theme"
                                : ""
                                } `}
                              onClick={() => handleCargoValue(option)}
                            >
                              {option}
                            </Item>
                          ))}
                        </Container>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.frameChild} />
            {selectedTruckSubtype.includes("boxtruck") && (
              <div className={styles.inputWithLabelParent}>
                <div className={styles.inputText2}>Dock Height</div>
                <div className={styles.dropdown1}>
                  <input
                    className="w-100"
                    placeholder="Dock height (inches)"
                    type="text"
                    value={
                      dockHeight ||
                      (idToUpdate?.dockHeight && isNaN(Number(dockHeight))
                        ? idToUpdate?.dockHeight
                        : "")
                    }
                    onChange={handleDockHeight}
                    maxLength={25}
                  />
                </div>
              </div>
            )}
            <div className={styles.frameGroup}>
              <div className={styles.inputWithLabelParentContainer}>
                <div className={styles.inputWithLabelParent}>
                  <div
                    className={styles.inputText2}
                    style={{
                      color:
                        requiredField == "allowTravelOutOfState" ? "red" : "",
                    }}
                  >
                    Renter can travel out of state*
                  </div>
                  <div className={styles.tagParent}>
                    <div
                      className={`${styles.tag} ${allowTravelOutOfState ? styles.selectedTag : ""
                        } hoverButtons`}
                      onClick={() => handleAllowTravelOutOfState(true)}
                    >
                      <span>Yes</span>
                    </div>
                    <div
                      className={`${styles.tag} ${!allowTravelOutOfState ? styles.selectedTag : ""
                        } hoverButtons`}
                      onClick={() => handleAllowTravelOutOfState(false)}
                    >
                      <span>No</span>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelParent}>
                  <div
                    className={styles.inputText2}
                    style={{ color: requiredField == "gps" ? "red" : "" }}
                  >
                    GPS
                  </div>
                  <div className={styles.tagParent}>
                    <div
                      className={`${styles.tag} ${gps ? styles.selectedTag : ""
                        } hoverButtons`}
                      onClick={() => handleGps(true)}
                    >
                      <span>Yes</span>
                    </div>
                    <div
                      className={`${styles.tag} ${!gps ? styles.selectedTag : ""
                        } hoverButtons`}
                      onClick={() => handleGps(false)}
                    >
                      <span>No</span>
                    </div>
                  </div>
                </div>
              </div>
              {!selectedTruckSubtype.includes("trailer") && (
                <div className={styles.inputWithLabelParent}>
                  <div
                    className={styles.inputText2}
                    style={{
                      color:
                        requiredField == "transmissionType" ? "red" : "",
                    }}
                  >
                    Transmission Type
                  </div>
                  <div className={styles.tagParent}>
                    <div
                      className={`${styles.tag} ${transmissionType === "automatic"
                        ? styles.selectedTag
                        : ""
                        } hoverButtons`}
                      onClick={() => handleTransmissionType("automatic")}
                    >
                      <span>Automatic</span>
                    </div>
                    <div
                      className={`${styles.tag} ${transmissionType === "manual"
                        ? styles.selectedTag
                        : ""
                        } hoverButtons`}
                      onClick={() => handleTransmissionType("manual")}
                    >
                      <span>Manual</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentStep != 8 && (
            <div className={styles.buttonContainer}>
              <div className={styles.button4} style={{ display: "none" }}>
                <div className={styles.text4}>Save as draft</div>
              </div>
              <div className={styles.buttonParent1}>
                <div className={`${styles.button5} hoverButtons`} onClick={onBackButtonClick}>
                  <div className={styles.text5}>Back</div>
                </div>
                <div className={`${styles.button6} hoverButtons`} onClick={() => handleNextClick(false)}>
                  <div className={styles.text6}>Next</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connectorAddTruckSpecs(AddTruckSpecs);
