import {useEffect, useState} from "react";
import axios from "axios";
import styles from "./css/AddTruckPrice.module.css";
import {RootStateTruckAdd, TruckRegistrationData} from "../../store/types";
import {updateTruckRegistrationData} from "../../store/actions/registrationActions";
import {connect, useSelector} from "react-redux";
import {errorToast} from "../../components/Toast/ToastMessage";
import {REACT_APP_ORG_API_BASE_URL} from "../../utils/UrlConstants";
import {FormControl, MenuItem, Select, SelectChangeEvent, Switch} from "@mui/material";
import {AddTruckPriceProps} from "./types";
import SwitchButton from "../../components/Common/Switch/SwitchButton";

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckPrice = connect(mapStateToProps, mapDispatchToProps);

const AddTruckPrice: React.FC<AddTruckPriceProps> = ({
                                                       onNextButtonClick,
                                                       updateTruckRegistrationData,
                                                       onBackButtonClick,
                                                       setCostRentPerDay,
                                                       setPrepaidMileage,
                                                       setLeaseCostRentPerDay,
                                                       setLeasePrepaidMileage,
                                                       setDeposite,
                                                       setEquipementPrice,
                                                       setInsurrancePrice,
                                                       setTaxes,
                                                       setEnableForLease,
                                                       setEnableForRent,
                                                       setLeaseForPurchase,
                                                       handleNextClick,
                                                       setLeaseForPurchasePrice,
                                                       setLeaseForPurchaseWeeks,
                                                       setTruckDescription,
                                                       truckAddRegistrationData,
                                                       costRentPerDay,
                                                       prepaidMileage,
                                                       leaseCostRentPerDay,
                                                       leasePrepaidMileage,
                                                       deposite,
                                                       equipementPrice,
                                                       insurrancePrice,
                                                       taxes,
                                                       enableForLease,
                                                       enableForRent,
                                                       idToUpdate,
                                                       leaseForPurchase,
                                                       currentStep,
                                                       leaseForPurchasePrice,
                                                       leaseForPurchaseWeeks,
                                                       truckDescription,
                                                     }) => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const [requiredField] = useState<string>("");
  const [organizationId] = useState(currentUser.organizations[0]);
  const [organizationName, setOrganizationName] = useState<string>("");
  const [orgDot, setOrgDot] = useState<string>("");
  const [rentValue, setRentValue] = useState(idToUpdate ? idToUpdate.rentPriceType : "weekly");
  const [leaseValue, setLeaseValue] = useState(idToUpdate ? idToUpdate.leasePriceType : "weekly");

  useEffect(() => {
    fetchOrganization(organizationId);
  }, [organizationId]);

  const handleRentValue = (event: SelectChangeEvent) => {
    setRentValue(event.target.value as string);
    idToUpdate
      ? (idToUpdate.rentPriceType = event.target.value as string)
      : null;
    console.log(idToUpdate);
    const newRentValue = event.target.value as string;
    setRentValue(newRentValue);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      rentPriceType: newRentValue,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseValue = (event: SelectChangeEvent) => {
    setLeaseValue(event.target.value as string);
    idToUpdate
      ? (idToUpdate.leasePriceType = event.target.value as string)
      : null;
    const newLeaseValue = event.target.value as string;
    setLeaseValue(newLeaseValue);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      leasePriceType: newLeaseValue,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const fetchOrganization = async (id: string) => {
    const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
    try {
      const response = await axios.get(url);
      console.log(response.data.organization);
      setOrganizationName(response.data.organization.name);
      setOrgDot(response.data.organization.dotNumber);

      const updatedRegistrationData: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        organization: {
          name: response.data.organization.name,
          dotNumber: response.data.organization.dotNumber,
        },
      };

      updateTruckRegistrationData(updatedRegistrationData);
    } catch (error) {
      errorToast("Error fetching organization");
      console.error("Error fetching organization:", error);
    }
  };

  const handleNextStep = async () => {
    onNextButtonClick();
  };

  const handleCostRentPerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setCostRentPerDay(0);
      return;
    }
    const newCostRentPerDay = parseFloat(e.target.value);
    setCostRentPerDay(newCostRentPerDay);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      rentPerDay: newCostRentPerDay,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePrepaidMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9.]/g, "")
    setPrepaidMileage(inputValue);
    if (parseFloat(inputValue) > 1) {
      errorToast("Maximum Prepaid Mileage must be 1 or lower");
      setPrepaidMileage(1);
    }
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      rentPrepaidMileage: +inputValue,
    };
    updateTruckRegistrationData(updatedRegistrationData);
  };
  const handleLeaseCostPerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setLeaseCostRentPerDay(0);
      return;
    }
    const newLLeaseCostDay = parseFloat(e.target.value);
    setLeaseCostRentPerDay(newLLeaseCostDay);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      leasePerDay: newLLeaseCostDay,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleTruckDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTruckDescription(e.target.value);
    const newLeaseDescription = e.target.value;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      LeaseForPurchase: {
        ...(truckAddRegistrationData?.LeaseForPurchase || {}),
        description: newLeaseDescription,
        pricePerWeek: leaseForPurchasePrice,
        numberOfWeeks: leaseForPurchaseWeeks,
      },
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseForPurchasePrice = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isNaN(Number(e.target.value))) {
      setLeaseForPurchasePrice(0);
      return;
    }

    const newLeaseForPurchasePrice = parseFloat(e.target.value);
    setLeaseForPurchasePrice(newLeaseForPurchasePrice);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      LeaseForPurchase: {
        ...(truckAddRegistrationData?.LeaseForPurchase || {}),
        pricePerWeek: newLeaseForPurchasePrice,

        numberOfWeeks: leaseForPurchaseWeeks,
      },
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseForPurchaseWeeks = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isNaN(Number(e.target.value))) {
      setLeaseForPurchaseWeeks(0);
      return;
    }

    const newLeaseForPurchaseWeeks = parseFloat(e.target.value);
    setLeaseForPurchaseWeeks(newLeaseForPurchaseWeeks);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      LeaseForPurchase: {
        ...(truckAddRegistrationData?.LeaseForPurchase || {}),
        numberOfWeeks: newLeaseForPurchaseWeeks,
        pricePerWeek: leaseForPurchasePrice,
      },
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9.]/g, "");
    if (parseFloat(inputValue) > 1) {
      setLeasePrepaidMileage(1);
      errorToast("Maximum Prepaid Mileage must be 1 or lower");
      return;
    }
    setLeasePrepaidMileage(inputValue);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      leasePrepaidMileage: +inputValue,
    };
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleDeposite = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setDeposite(0);
      return;
    }
    const newDeposite = parseFloat(e.target.value);
    setDeposite(newDeposite);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      deposit: newDeposite,
    };
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEquipementPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseFloat(e.target.value))) {
      setEquipementPrice(0);
      return;
    }
    const newEquipement = parseFloat(e.target.value);
    setEquipementPrice(newEquipement);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      equipmentPrice: newEquipement,
    };
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleInsurrancePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setInsurrancePrice(0);
      return;
    }
    const newInsurrance = parseFloat(e.target.value);
    setInsurrancePrice(newInsurrance);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      insurancePrice: newInsurrance,
    };
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleTaxes = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setTaxes(0);
      return;
    }
    const newTaxes = parseFloat(e.target.value);
    setTaxes(newTaxes);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      taxes: newTaxes,
    };
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEnableForRent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rentToggle = e.target.checked;
    setEnableForRent(rentToggle);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      enableForRent: rentToggle,
    };

    if (idToUpdate) {
      idToUpdate.enableForRent = rentToggle;
    }

    if (!rentToggle) {
      setCostRentPerDay(0);
      setPrepaidMileage(0);
      const updatedRentValues: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        rentPerDay: 0,
        rentPrepaidMileage: 0,
      };
      updateTruckRegistrationData(updatedRentValues);
    }
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEnableForLease = (e: React.ChangeEvent<HTMLInputElement>) => {
    const leaseToggle = e.target.checked;
    setEnableForLease(leaseToggle);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      enableForLease: leaseToggle,
    };

    if (idToUpdate) {
      idToUpdate.enableForLease = leaseToggle;
    }

    if (!leaseToggle) {
      setLeaseCostRentPerDay(0);
      setLeasePrepaidMileage(0);
      const updatedLeaseValues: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        leasePerDay: 0,
        leasePrepaidMileage: 0,
      };
      updateTruckRegistrationData(updatedLeaseValues);
    }
    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseForPurchase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const leaseForPurchaseToggle = e.target.checked;
    setLeaseForPurchase(leaseForPurchaseToggle);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      enableForLeaseForPurchase: leaseForPurchaseToggle,
    };

    if (idToUpdate) {
      idToUpdate.enableForLeaseForPurchase = leaseForPurchaseToggle;
    }
    updateTruckRegistrationData(updatedRegistrationData);
  };

  return (
    <div className={styles.registerATruckOrEdit6P}>
      <div className={styles.component35Parent}>
        <div className={styles.component35}>
          {currentStep != 8 && (
            <div className={styles.textButtonParent}>
              <div className={styles.textButton} onClick={onBackButtonClick}>
                <img className={styles.icon} alt="" src="/generics/icon.svg"/>
                <div className={styles.text2}>Back</div>
              </div>
              <div className={styles.inputText}>6 of 6</div>
            </div>
          )}
          <div className={styles.userNameParent}>
            <div className={styles.userName}>Setting the price</div>
            <div className={styles.inputText1}>
              Sign up with your work Google account or use the form.
            </div>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.textButtonGroup}>
            <div className={styles.textButton1}>
              <img className={styles.icon1} alt="" src="/generics/icon.svg"/>
              <div className={styles.text3}>Back&nbsp;</div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel7}>
                <div className={styles.inputText16}>For rent</div>
                <div className={styles.inputTextParent}>
                  <div className={styles.inputText9}>
                    People can rent the truck based on the stipulated period.
                  </div>
                  {/*<div*/}
                  {/*  className={`${styles.toggle} ${enableForRent ? styles.toggleChecked : ""*/}
                  {/*  }`}*/}
                  {/*>*/}
                  {/*  <input*/}
                  {/*    className={styles.checkbox2}*/}
                  {/*    type="checkbox"*/}
                  {/*    style={{*/}
                  {/*      width: "100%",*/}
                  {/*      border: "0",*/}
                  {/*    }}*/}
                  {/*    onChange={handleEnableForRent}*/}
                  {/*    checked={*/}
                  {/*      enableForRent ||*/}
                  {/*      (idToUpdate?.enableForRent && enableForRent)*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*  <div className={styles.on}>On</div>*/}
                  {/*</div>*/}
                  <SwitchButton isOn={enableForRent// || (idToUpdate?.enableForRent && enableForRent)
                  } onChange={handleEnableForRent} label="On"/>

                </div>
              </div>
              {(enableForRent ||
                (idToUpdate?.enableForRent && enableForRent)) && (
                <>
                  <div className={styles.frameDiv}>
                    <div className={styles.inputWithLabel7}>
                      <div
                        className={styles.inputText16}
                        style={{display: "flex", alignItems: "center"}}
                      >
                        Cost per rent per&nbsp;
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={rentValue }
                            value={
                              rentValue ||
                              (idToUpdate?.rentPriceType && rentValue)
                            }
                            onChange={handleRentValue}
                            label=""
                            sx={{
                              height: "18px",

                              padding: "0",
                              "& .MuiOutlinedInput-notchedOutline": {},
                              "& .MuiSelect-select": {
                                paddingLeft: "12px",
                                color: "#475569",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <MenuItem value={"daily"}>daily</MenuItem>
                            <MenuItem value={"weekly"}>weekly</MenuItem>
                            <MenuItem value={"monthly"}>monthly</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        className={styles.basicInput5}
                        style={{
                          border:
                            requiredField == "costRentPerDay"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <img
                          className={styles.iconoutlinecurrencyDollar5}
                          alt=""
                          src="/generics/iconoutlinecurrencydollar.svg"
                        />
                        <input
                          type="text"
                          className={styles.inputText17}
                          placeholder="Cost per rent"
                          style={{border: "none"}}
                          value={
                            costRentPerDay || 0
                          }
                          onChange={handleCostRentPerDay}
                          maxLength={25}
                        />
                      </div>
                    </div>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>Prepaid Mileage</div>
                      <div
                        className={styles.basicInput5}
                        style={{
                          border:
                            requiredField == "prepaidMileage"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <img
                          className={styles.iconoutlinecurrencyDollar5}
                          alt=""
                          src="/generics/iconoutlinecurrencydollar.svg"
                        />
                        <input
                          type="text"
                          step="0.01"
                          className={styles.inputText17}
                          placeholder="0.00$/Mi"
                          style={{border: "none"}}
                          value={prepaidMileage}
                          onChange={(e) => handlePrepaidMileage(e)}
                          inputMode="decimal"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.frameChild}/>
            <div className={styles.inputWithLabelContainer}>
              <div className={styles.inputWithLabel7}>
                <div className={styles.inputText16}>For lease</div>
                <div className={styles.inputTextGroup}>
                  <div className={styles.inputText9}>
                    You can lease the truck based on the stipulated period.
                  </div>

                  <SwitchButton isOn={enableForLease// || (idToUpdate?.enableForLease && enableForLease)
                  } onChange={handleEnableForLease} label="On"/>
                </div>
              </div>
              {(enableForLease ||
                (idToUpdate?.enableForLease && enableForLease)) && (
                <>
                  <div className={styles.frameDiv}>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>
                        Cost per rent per&nbsp;
                        <FormControl>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              leaseValue ||
                              (idToUpdate?.leasePriceType && leaseValue)
                            }
                            onChange={handleLeaseValue}
                            label=""
                            sx={{
                              height: "18px",

                              padding: "0",
                              "& .MuiOutlinedInput-notchedOutline": {},
                              "& .MuiSelect-select": {
                                paddingLeft: "12px",
                                color: "#475569",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <MenuItem value={"daily"}>daily</MenuItem>
                            <MenuItem value={"weekly"}>weekly</MenuItem>
                            <MenuItem value={"monthly"}>monthly</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        className={styles.basicInput5}
                        style={{
                          border:
                            requiredField == "leaseCostRentPerDay"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <img
                          className={styles.iconoutlinecurrencyDollar5}
                          alt=""
                          src="/generics/iconoutlinecurrencydollar.svg"
                        />
                        <input
                          type="text"
                          className={styles.inputText17}
                          placeholder="Cost per lease"
                          style={{border: "none"}}
                          value={
                            leaseCostRentPerDay || 0
                          }
                          onChange={handleLeaseCostPerDay}
                          maxLength={25}
                        />
                      </div>
                    </div>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>
                        Prepaid Mileage
                      </div>
                      <div
                        className={styles.basicInput5}
                        style={{
                          border:
                            requiredField == "leasePrepaidMileage"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <img
                          className={styles.iconoutlinecurrencyDollar5}
                          alt=""
                          src="/generics/iconoutlinecurrencydollar.svg"
                        />
                        <input
                          type="text"
                          step="0.01"
                          className={styles.inputText17}
                          placeholder="0.00$/Mi"
                          value={leasePrepaidMileage || 0}
                          onChange={handleLeaseMileage}
                          maxLength={25}
                          inputMode="decimal"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className={styles.frameChild}/>

            <div className={styles.inputWithLabelContainer}>
              <div className={styles.inputWithLabel3}>
                <div className={styles.inputText16}>Lease to purchase</div>
                <div className={styles.inputTextGroup}>
                  <div className={styles.inputText9}>
                    Set your truck as available for purchase.
                  </div>

                  <SwitchButton isOn={leaseForPurchase// || (idToUpdate?.enableForLeaseForPurchase)
                  } onChange={handleLeaseForPurchase} label="On"/>
                </div>
              </div>
              {leaseForPurchase && (
                <>
                  <div className={styles.frameDiv}>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>Price per week</div>
                      <div style={{display: "flex", flexDirection: "row", width: "100%", gap: "0.5rem"}}>
                        <div className={styles.basicInput5}
                             style={{
                               width: "inherit",
                               border:
                                 requiredField == "leaseForPurchase"
                                   ? "1px solid red"
                                   : "",
                             }}
                        >
                          <img className={styles.iconoutlinecurrencyDollar5} alt=""
                               src="/generics/iconoutlinecurrencydollar.svg"/>
                          <input
                            type="text"
                            className={styles.inputText17}
                            placeholder="Price"
                            style={{border: "none"}}
                            onChange={handleLeaseForPurchasePrice}
                            maxLength={25}
                            value={leaseForPurchasePrice || 0}
                          />
                        </div>
                        <div
                          className={styles.basicInput5}
                          style={{
                            width: "30%",
                            border:
                              requiredField == "leaseForPurchase"
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          <input
                            type="text"
                            className={styles.inputText17}
                            placeholder="# of weeks"
                            style={{border: "none"}}
                            onChange={handleLeaseForPurchaseWeeks}
                            value={leaseForPurchaseWeeks || 0}
                            maxLength={25}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputWithLabel7}>
                    <div className={styles.inputText16}>
                      Describe your truck
                    </div>
                    <div className={styles.basicInput5}
                         style={{border: requiredField == "truckDescription" ? "1px solid red" : ""}}>
                      {" "}
                      <textarea
                        className={styles.inputLeftContent2Description2}
                        placeholder="Describe your truck"
                        style={{
                          border: "none",
                          resize: "none",
                        }}
                        onChange={handleTruckDescription}
                        value={
                          truckDescription ||
                          idToUpdate?.LeaseForPurchase.description
                        }
                        maxLength={500}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            {currentUser.role == "renter" ? (
              <></>
            ) : (
              <>
                <div className={styles.frameItem}/>
                <div className={styles.inputWithLabel7}>
                  <div className={styles.inputText16}>Deposite</div>
                  <div className={styles.basicInput5}
                       style={{border: requiredField == "deposite" ? "1px solid red" : ""}}>
                    <img className={styles.iconoutlinecurrencyDollar5} alt=""
                         src="/generics/iconoutlinecurrencydollar.svg"/>
                    <input
                      type="text"
                      className={styles.inputText17}
                      placeholder="Deposite"
                      style={{border: "none"}}
                      value={deposite || 0}
                      onChange={handleDeposite}
                      maxLength={25}
                    />
                  </div>
                </div>
                <div className={styles.frameInner}/>
                <div className={styles.inputWithLabelParentContainer}>
                  <div className={styles.inputWithLabelParent1}>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>Equipment Price</div>
                      <div className={styles.basicInput5}
                           style={{border: requiredField == "equipementPrice" ? "1px solid red" : ""}}>
                        <img className={styles.iconoutlinecurrencyDollar5} alt=""
                             src="/generics/iconoutlinecurrencydollar.svg"/>
                        <input
                          type="text"
                          className={styles.inputText17}
                          placeholder="Equipment Price"
                          style={{border: "none"}}
                          value={equipementPrice || 0}
                          onChange={handleEquipementPrice}
                          maxLength={25}
                        />
                      </div>
                    </div>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>Insurance Price</div>
                      <div className={styles.basicInput5}
                           style={{border: requiredField == "insurrancePrice" ? "1px solid red" : ""}}>
                        <img className={styles.iconoutlinecurrencyDollar5} alt=""
                             src="/generics/iconoutlinecurrencydollar.svg"/>
                        <input
                          type="text"
                          className={styles.inputText17}
                          placeholder="Insurance Price"
                          style={{border: "none"}}
                          value={insurrancePrice || 0}
                          onChange={handleInsurrancePrice}
                          maxLength={25}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputWithLabel7}>
                    <div className={styles.inputText16}>Taxes</div>
                    <div className={styles.basicInput5}
                         style={{border: requiredField == "taxes" ? "1px solid red" : ""}}>
                      <img className={styles.iconoutlinecurrencyDollar5} alt=""
                           src="/generics/iconoutlinecurrencydollar.svg"/>
                      <input
                        type="text"
                        className={styles.inputText17}
                        placeholder="Taxes"
                        style={{border: "none"}}
                        value={taxes || 0}
                        onChange={handleTaxes}
                        maxLength={25}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={styles.buttonContainer}>
            <div className={styles.button4} style={{display: "none"}}>
              <div className={styles.text4}>Save as draft</div>
            </div>
            <div className={styles.buttonParent1}>
              {currentStep != 8 && (
                <div
                  className={`${styles.button5} hoverButtons`}
                  onClick={onBackButtonClick}
                >
                  <div className={styles.text5}>Back</div>
                </div>
              )}
              <div
                className={`${styles.button6} hoverButtons`}
                onClick={currentStep != 8 ? handleNextStep : handleNextClick}
              >
                <div className={styles.text6}>
                  {currentStep != 8 ? "Next" : "Finish"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connectorAddTruckPrice(AddTruckPrice);
