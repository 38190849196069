import React, { useEffect, useState } from "react";
import styles from "../../css/Registration_Driver/AddOrganizationList.module.css";
import { RootState } from "../../store/types";
import { updateRegistrationData } from "../../store/actions/registrationActions";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import { errorToast } from "../Toast/ToastMessage";
import { setOrganization } from "../../store/actions/organizationAction";
import { getNoAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_ORG_API_BASE_URL, REACT_APP_UPLOAD_API_BASE_URL } from "../../utils/UrlConstants";
import { AddOrganizationListProps } from "./types";
import { Company } from "./types";

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorVechicleToRent = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorVechicleToRent
>;

const AddOrganizationList: React.FC<
  AddOrganizationListProps & PropsFromReduxVechicleToRent
> = ({
  onSkipButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
}) => {
    const dispatch = useDispatch();
    const userRegistrationState = useSelector((state: RootState) => state.registrationData);
    const [requiredField, setRequiredField] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>(userRegistrationState?.organizations[0]?.name || "");
    const [newCompanyPhone] = useState<string>("");
    const [email, setEmail] = useState<string>(userRegistrationState?.email);
    const [address] = useState<string>("");
    const [newCompanyDot] = useState<string>("");
    const [dataCompaniesOriginal, setDataCompaniesOriginal] = useState<Company[]>([]);
    const [dataCompanies, setDataCompanies] = useState<Company[] | any>(dataCompaniesOriginal);
    const [showOriginalContent, setShowOriginalContent] = useState<boolean>(true);

    useEffect(() => {
      let isInitialRender = true;
      fetchData("");
      return () => {
        isInitialRender = false;
      };
    }, []);

    const handleCompanyNameChange = (
      input: React.ChangeEvent<HTMLInputElement> | string
    ) => {
      let inputValue: string;
      if (typeof input === "string") {
        inputValue = input;
      } else {
        inputValue = input.target.value;
      }
      setCompanyName(inputValue);
      fetchData(inputValue);

      if (inputValue === "") {
        setDataCompanies(dataCompaniesOriginal);
      } else {
        const filteredCompanies = dataCompaniesOriginal.filter((item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        setDataCompanies(filteredCompanies);
      }
      const updatedOrganizations = [...userRegistrationState.organizations];

      updatedOrganizations[0] = {
        ...updatedOrganizations[0],
        name: inputValue,
      };
      updateRegistrationData({
        ...userRegistrationState,
        organizations: updatedOrganizations,
      });
    };

    const handleAccordionClick = async (
      name: string,
      address: string,
      companyEmail: string,
      phone: string,
      dot: string,
      item: any
    ) => {
      setCompanyName(name);
      setEmail(companyEmail);
      const updatedOrganizations = [
        {
          ...userRegistrationState.organizations[0],
          name: name,
          dotNumber: dot,
        },
      ];

      const result = await createOrganization(item);
      if (result) {
        let physicalAddress = result.organization.physicalAddress
        setOrganization({});

        dispatch(setOrganization({
          phone: phone,
          physicalAddress: {
            street: `${physicalAddress.street}`,
            city: `${physicalAddress.city}`,
            state: `${physicalAddress.state}`,
            zip: `${physicalAddress.zip}`,
            country: `${physicalAddress.country}`,
          }
        }));

        updateRegistrationData({
          ...registrationData,
          organizations: updatedOrganizations,
          email: companyEmail,
        });

        onSkipButtonClick();
      }
    };

    const handleNextButtonClick = async (step?: number) => {
      if (!companyName) {
        const emptyField = !companyName ? "companyName" : "";
        setRequiredField(emptyField);
        return;
      }

      const requestBody = {
        name: companyName,
        physicalAddress: {
          street: address,
          city: address,
          state: address,
          zip: "123",
          country: address,
        },
        mailingAddress: {
          street: address,
          city: address,
          state: address,
          zip: "123",
          country: address,
        },
        email: email,
        phone: newCompanyPhone,
        dotNumber: newCompanyDot,
      };
      const result = await createOrganization(requestBody);
      if (result) {
        onSkipButtonClick();
      }
    };

    const createOrganization = async (requestBody: any) => {
      const apiUrl = `${REACT_APP_ORG_API_BASE_URL}/organization/create`;

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: getNoAuthHeaders(),
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (!response.ok) {
          const errorMessage = data.error || "Please select a valid organization";
          errorToast(errorMessage);
          setCompanyName("");
          return false;
        }

        return data;
      } catch (error) {
        errorToast(`${error}`);
        throw error;
      }
    };

    const handleAddItClick = () => {
      setShowOriginalContent(false);
    };

    const handleBackButtonClick = () => {
      onBackButtonClick();
    };

    const fetchData = async (search: string) => {
      try {
        const apiUrl = `${REACT_APP_UPLOAD_API_BASE_URL}/upload/files/fmcsa/search?search=${search || companyName
          }`;

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: getNoAuthHeaders(),
        });

        if (!response.ok) {
          console.error("failed", JSON.stringify(response));
          return;
        }

        const data = await response.json();
        setDataCompaniesOriginal(Array.from(data));
        setDataCompanies(Array.from(data));
        console.log(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const originalContent = (
      <div className={styles.backButtonParent}>
        <div className={styles.textButton} onClick={handleBackButtonClick} style={{ cursor: "pointer" }}>
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.signUpStep}>Company details {"(2/3 step)"}</div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>Your organization details</div>
          <div className={styles.inputText3}>
            Please provide your Organization information accurately, it will be
            used in all your communications on the platform.&nbsp;
          </div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.inputWithLabelParent}>
            <div className={styles.inputWithLabel}>
              <div className={styles.reefer}>Company name&nbsp;</div>
              <div className={styles.basicInput} style={requiredField === "companyName" ? { border: "1px solid red" } : {}}>
                <input
                  type="text"
                  className={styles.inputField}
                  value={companyName}
                  placeholder="Company name"
                  style={{ border: "none", paddingLeft: "0" }}
                  onChange={handleCompanyNameChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.resultsFoundParent}>
            <div className={styles.resultsFound}>
              {dataCompanies.length} results found
            </div>
            <div className={styles.companyNotOnTheListParent} role="button" onClick={handleAddItClick}>
              <div className={styles.resultsFound}>Company not on the list?</div>
              <div className={styles.addIt}>+Add it</div>
            </div>
          </div>
          <div className={styles.frameParent} id={"listItems"}>
            {dataCompanies.map((item: any) => {
              return (
                <div key={item} className={styles.accordion} role="button"
                  onClick={() => handleAccordionClick(item.name, item.physicalAddress?.street, item.email, item.phone, item.dotNumber, item)}>
                  <div className={styles.headlineParent}>
                    <div className={styles.headline}>{item.name}</div>
                  </div>
                  <div className={styles.inputText1} style={{ textAlign: "left" }}>
                    {item.physicalAddress?.street}
                    {", "}
                    {item.physicalAddress.state}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={`${styles.button2} hoverButtons`}>
            <div className={styles.reefer}>
              <span>Company not on the list?&nbsp;</span>
              <span className={styles.addIt1} role="button" onClick={handleAddItClick}>+Add it</span>
            </div>
          </div>
        </div>
      </div>
    );

    return <>{originalContent}</>;
  };

export default connectorVechicleToRent(AddOrganizationList);
