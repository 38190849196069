import styles from "./css/UserAdministration.module.css";
import { LocationModalBodyProps } from "./types";

const LocationModalBody: React.FC<LocationModalBodyProps> = ({
  selectedTruck,
  setSelectedTruck,
  updateLocation,
}) => {

  return (
    <div className={styles.addLocationParent} style={{ display: "flex", flexDirection: "column" }}>
      State
      <input
        className={styles.inputTextField}
        placeholder="State"
        id="outlined-basic"
        value={selectedTruck?.state || ""}
        onChange={(e) => {
          setSelectedTruck((prevState: any) => ({
            ...prevState,
            state: e.target.value,
          }));
        }}
      />
      <br className="mt-2" />
      City
      <input
        className={styles.inputTextField}
        placeholder="City"
        id="outlined-basic"
        value={selectedTruck?.city || ""}
        onChange={(e) => {
          setSelectedTruck((prevState: any) => ({
            ...prevState,
            city: e.target.value,
          }));
        }}
      />
      <br className="mt-2" />
      Zip Code
      <input
        className={styles.inputTextField}
        placeholder="Zip Code"
        id="outlined-basic"
        value={selectedTruck?.zipCode || ""}
        onChange={(e) => {
          setSelectedTruck((prevState: any) => ({
            ...prevState,
            zipCode: e.target.value,
          }));
        }}
      />
      <br className="mt-2" />

      <div
        onClick={() => {
          if (selectedTruck) {
            updateLocation(
              selectedTruck?._id ?? "",
              selectedTruck?.state ?? "",
              selectedTruck?.city ?? "",
              selectedTruck?.zipCode ?? ""
            );
          }
        }}
        className={`${styles.button2} hoverButtons`}
        style={{ height: "50px" }}
      >
        <div className={styles.text17}>Update Location</div>
      </div>
    </div>
  )
}

export default LocationModalBody;
