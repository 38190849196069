import { ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
import NoAuthRestrictedRoute from './components/Route/NoAuthRestrictedRoute';
import ProtectedRoute from './components/Route/ProtectedRoute';
import HomePage from './pages/Landing/HomePage';
import MapPageNavbar from './pages/Map_Page/MapPageNavbar';
import MapPage from './pages/Map_Page/MapPage';
import Footer from './pages/Add_Truck/Footer';
import ProductPage from './pages/Selected_Truck/ProductPage';
import OrganizationPage from "./pages/Organization_Page/OrganizationPage";
import BlogsPage from './pages/Blogspage/BlogsPage';
import ArticlePage from './pages/Blogspage/ArticlePage/ArticlePage';
import ForOwners from './pages/Landing/ForOwners/ForOwners';
import DriverRegistrationLayout from './Layouts/DriverRegistrationLayout';
import OwnerRegistrationLayout from './Layouts/OwnerRegistrationLayout';
import AddTruckInitial from './pages/Add_Truck/AddTruckInitial';
import UserAdministration from './pages/Admin/UserAdministration';
import MainAdministration from "./pages/Admin/MainAdministration";
import TruckManagement from './pages/Admin/TruckManagement';
import OrganizationsManagement from './pages/Admin/OrganizationsManagement';
import Reservations from './pages/Admin/ads/Reservations';
import Profile from './pages/Admin/settings/Profile';
import NotFoundPage from "./pages/NotFound/NotFound";
import SubscriptionPage from "./pages/Subscription/SubscriptionPage";

// Layout component for pages with navbar and footer
const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <MapPageNavbar />
    {children}
    <Footer />
  </>
);

const PagesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/main" element={<MainLayout><MapPage /></MainLayout>} />
      <Route path="/truck/:id" element={<MainLayout><ProductPage /></MainLayout>} />
      <Route path="/organization/:id" element={<MainLayout><OrganizationPage /></MainLayout>} />

      {/* No auth Restricted routes */}
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="blogs/article/:articleId" element={<ArticlePage />} />
      <Route path="/forowners" element={<MainLayout><ForOwners /></MainLayout>} />
      <Route path="/registration/driver" element={<NoAuthRestrictedRoute cmp={DriverRegistrationLayout} />} />
      <Route path="/registration/owner" element={<NoAuthRestrictedRoute cmp={OwnerRegistrationLayout} />} />

      {/* PROTECTED ROUTES */}
      <Route path="/add_truck" element={<ProtectedRoute cmp={AddTruckInitial} />} />
      <Route path="/admin/user_administration" element={<ProtectedRoute cmp={UserAdministration} />} />
      <Route path="/admin" element={<ProtectedRoute cmp={MainAdministration} />} />
      <Route path="/admin/truck_management" element={<ProtectedRoute cmp={TruckManagement} />} />
      <Route path="/admin/organizations_management" element={<ProtectedRoute cmp={OrganizationsManagement} />} />
      <Route path="/admin/ads/reservations" element={<ProtectedRoute cmp={Reservations} />} />
      <Route path="/admin/settings/profile" element={<ProtectedRoute cmp={Profile} />} />
      <Route path="/admin/subscription" element={<ProtectedRoute cmp={SubscriptionPage} />} />

      {/* 404 Not Found */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default PagesRoutes;
