import React, { useState } from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerDetails.module.css";
import { RegistrationData, RootState } from "../store/types";
import { updateRegistrationData } from "../store/actions/registrationActions";
import { connect } from "react-redux";

export type AddOrganizationProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData: RegistrationData;
  onClick?: () => void;
};

type DotByStateType = {
  [key: string]: boolean;
};

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const RegistrationOwnerDetails: React.FC<
  AddOrganizationProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps
> = ({
  onNextButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
    const [companyName, setCompanyName] = useState<string>("");
    const [isOrganizationsUpdated, setIsOrganizationsUpdated] = useState<boolean>(false);

    const handleNextClick = () => {
      onNextButtonClick?.();
    };

    const handleCompanyName = (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      const newName = e.target.value;

      setCompanyName(newName);
      if (newName == "") setIsOrganizationsUpdated(false);
      else setIsOrganizationsUpdated(true);

      const updatedOrganizations = [...registrationData.organizations];

      updatedOrganizations[index] = {
        ...updatedOrganizations[index],
        name: newName,
      };
      updateRegistrationData({
        ...registrationData,
        organizations: updatedOrganizations,
      });
    };

    const handleBackButtonClick = () => {
      onBackButtonClick();
    };

    return (
      <div className={styles.backButtonParent}>
        <div className={styles.textButton} onClick={handleBackButtonClick} style={{ cursor: "pointer" }}>
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.signUpStep}>Company details {"(vehicle owner 2/3 step)"}</div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>Your organization details</div>
          <div className={styles.inputText3}>Please provide your Organization information accurately, it will be used in all your communications on the platform.&nbsp;</div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.frameWrapper}>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company name&nbsp;</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    value={companyName}
                    placeholder="Company Name"
                    style={{ border: "none", width: "90%" }}
                    onChange={(e) => handleCompanyName(e, 0)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.button1} hoverButtons`}
            style={{
              backgroundColor: companyName !== "" ? "#fa6f32" : "#f1f5f9",
              color: companyName !== "" ? "white" : "initial",
            }}
            role="button"
            onClick={handleNextClick}
          >
            <div className={styles.text}>Search company</div>
          </div>
        </div>
      </div>
    );
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationOwnerDetails);
