import React, { ReactElement } from "react";
import Modal from 'react-bootstrap/Modal';
import styles from './GenericModal.module.css';
import { GenericModalProps } from "../../lib/types";

const GenericModal: React.FC<GenericModalProps> = ({
  setShowModal,
  onClickSubmit,
  title,
  submitButtonText,
  cancelButtonText,
  showModal,
  cmpProps,
  showFooter,
  cmp,
}: GenericModalProps): ReactElement => {
  const Cmp = cmp;

  return (
    <>
      <Modal
        className={`${styles.updatePasswordModal} password-modal`}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton style={{ borderBottom: `${title.includes('Edit Truck') ? '0px' : '1px !important'}` }}>
          <Modal.Title id="example-modal-sizes-title-lg">
            <b className={styles.modalTitle}>{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Cmp {...cmpProps} />
        {
          showFooter && (
            <>
              <div className={styles.horizontalLine}></div>
              <div className={`${styles.formGroup}`}>
                { cancelButtonText !== '' && (
                  <label className={`${styles.passwordLabel}`} htmlFor="confirmPassword" onClick={()=>setShowModal(!showModal)} >
                    <button className={`${styles.btn} ${styles.btnRight} ${styles.btnLight}`}>
                      {cancelButtonText}
                    </button>
                  </label>
                )}
                <button className={`${styles.btn} ${styles.btnRight} ${cancelButtonText == '' ? 'w-100' : ''}`} onClick={onClickSubmit} >
                  {submitButtonText}
                </button>
              </div>
            </>
          )
        }
      </Modal>
    </>
  );
};

export default GenericModal;
