import React, { useState } from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerDetails2.module.css";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { RegistrationData, RootState } from "../store/types";
import { updateRegistrationData } from "../store/actions/registrationActions";
import { errorToast } from "../components/Toast/ToastMessage";
import { getAuthHeaders } from "../utils/GetHeaders";
import { setTokens } from "../utils/SetTokens";
import { REACT_APP_AUTH_SVC_API_BASE_URL, REACT_APP_OTP_API_BASE_URL } from "../utils/UrlConstants";

type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onSkipButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData?: RegistrationData;
  onClick?: () => void;
};

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorVechicleToRent = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorVechicleToRent
>;

const RegistrationOwnerDetails2: React.FC<
  VechicleToRentProps & PropsFromReduxVechicleToRent
> = ({
  onNextButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
    const reduxStateRegistrationData = useSelector(
      (state: any) => state.registrationData
    );
    const organizationAddressState = useSelector(
      (state: any) => state.organization.physicalAddress
    );
    const [requiredField, setRequiredField] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [DOT, setDOT] = useState<string>(
      reduxStateRegistrationData.organizations[0].dotNumber
    );
    const [phone, setPhone] = useState<string>(reduxStateRegistrationData.phone);
    const currentUrl = window.location.href;

    const handleNextClick = async () => {
      if (!email) {
        errorToast("Email is required");
        return;
      }
      if (!email || !phone) {
        const emptyField = !email ? "email" : !phone ? "phone" : "";
        setRequiredField(emptyField);
        return;
      }

      const apiUrl = `${REACT_APP_AUTH_SVC_API_BASE_URL}/register`;

      const headers = {
        "Content-Type": "application/json",
      };

      fetch(apiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify(reduxStateRegistrationData),
      })
        .then(async (response: any) => {
          if (response.status === 201) {
            const data = await response.json();
            updateRegistrationData({
              ...reduxStateRegistrationData,
              role: `${currentUrl.includes("registration_driver") ? "driver" : "renter"
                }`,
            });
            setTokens(
              response.headers.get("x-access-token"),
              response.headers.get("x-refresh-token")
            );
            handleSendCodeClick();
            onNextButtonClick();
          } else if (response.status === 201) {
            return response.json();
          } else if (response.status === 400) {
            return response.json().then((data: any) => {
              errorToast(`Bad request: ${data.error}`);
            });
          } else if (response.status === 500) {
            return response.json().then((data: any) => {
              errorToast(`${data.error}`);
            });
          } else {
            errorToast(
              `HTTP error ${response.status} ${response.statusText} ${response.body}`
            );
          }
        })
        .then((data) => {
          console.log("Response from server:", data);
        })
        .catch((error) => {
          errorToast(`${error.message}`);
          console.error("Error:", error.message);
        });
    };

    const handleSendCodeClick = async () => {
      try {
        const email = reduxStateRegistrationData.email.toLowerCase();
        const apiUrl = `${REACT_APP_OTP_API_BASE_URL}/otp/send/email`;

        const requestBody = {
          email: email,
        };

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: getAuthHeaders(),
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          errorToast("Incorrect One Time Password");
          console.error("Login failed", response);
          return false;
        }

        const data = await response.json();
        if (data.exists === false) {
          errorToast("Email does not exist");
        } else if (data.exists === true) {
          errorToast("Email already exists");
        } else {
          console.log("Success:", data);
        }
      } catch (error) {
        errorToast(`${error}`);
        console.error("Error:", error);
      }
    };

    const handleBackButtonClick = () => {
      onBackButtonClick();
    };

    const handleEmail = (email: string) => {
      const newEmail = email;
      setEmail(newEmail);

      updateRegistrationData({
        ...reduxStateRegistrationData,
        email: newEmail,
      });
    };

    return (
      <div className={styles.backButtonParent}>
        <div className={`${styles.textButton} ${styles.backButton}`} onClick={handleBackButtonClick} style={{ cursor: "pointer" }}>
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.signUpStep}>Company details {"(vehicle owner 2/3 step)"}</div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>Your organization details</div>
          <div className={styles.inputText3}>
            Please provide your Organization information accurately, it will
            be used in all your communications on the platform.&nbsp;
          </div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.frameParent}>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company name&nbsp;</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder="Company name"
                    style={{ border: "none" }}
                    value={reduxStateRegistrationData.organizations[0].name}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Address</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder={`${organizationAddressState.street}, ${organizationAddressState.state}`}
                    style={{ border: "none" }}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Your user e-mail</div>
                <div className={styles.basicInput} style={{ background: "white" }}>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder="Your user e-mail"
                    style={{ border: "none", background: "white" }}
                    onChange={(e) => handleEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.inputWithLabel3}>
                <div className={styles.inputTextWrapper}>
                  <div className={styles.reefer}>DOT number</div>
                </div>
                <div className={styles.basicInput}>
                  <input type="text" className={styles.inputField} placeholder="000000" style={{ border: "none" }} value={DOT} disabled />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.button1} hoverButtons`} onClick={handleNextClick}>
            <div className={styles.text} role="button"> Next</div>
          </div>
        </div>
      </div>
    );
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationOwnerDetails2);
