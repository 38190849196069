import { useState, useEffect } from "react";
import styles from "../../css/Login/Login.module.css";
import stylesAlt from "../../css/Login/LoginNoRegister.module.css";
import Loader from "../Loader/Loader";
import { successToast, errorToast } from '../../components/Toast/ToastMessage';
import { getNoAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_AUTH_SVC_API_BASE_URL, REACT_APP_LONG_COUNTDOWN } from '../../utils/UrlConstants';
import { useGlobalContext } from "../Global/GlobalState";

const ForgotPassword: React.FC = () => {
  const { setOpenForgot, setOpenLogin } = useGlobalContext();
  const [loginSuccess, setLoginSuccess] = useState<boolean | null>(null);
  const [forgotPassword, setForgotPassword] = useState<boolean | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState<string | null>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(parseInt(REACT_APP_LONG_COUNTDOWN || '60'));

  useEffect(() => {
    if(buttonDisabled){
      const timer = setInterval(() => {
        setCountdown((prevCountdown :any) => prevCountdown - 1);
      }, 1000);
  
      if (countdown === 0) {
        clearInterval(timer);
        setButtonDisabled(false);
      }
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, buttonDisabled]);

  const handleResetPassword = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_AUTH_SVC_API_BASE_URL}/password/forgot`,
        {
          method: "POST",
          headers: getNoAuthHeaders(),
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      if (response.ok) {
        successToast("Email sent successfully");
        setCountdown(parseInt(REACT_APP_LONG_COUNTDOWN || '60'));
        setOpenLogin(false);
        setButtonDisabled(true);
        setOpenForgot(false);
        setEmail('');
      } else {
        errorToast("Enter a valid email");
      }
    } catch (error) {
      errorToast("Error during password forgot");
    }
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleResetPassword();
    }
  };

  return (
    <div className={`${styles.forgotPassword}`}>
    {
      loading ? (
        <Loader />
      ): (
        <div className="w-100">
          <div className={`${stylesAlt.dialogWindowNoRegister}`}>
            <div className={stylesAlt.rectangleParent}>
              <div className={`${stylesAlt.frameChild} border-top`} />
              <div className={stylesAlt.userName1}>Enter your email </div>
              <div className={stylesAlt.inputWithLabelParent}>
                <div className={stylesAlt.inputWithLabel}>
                  <div className={stylesAlt.inputText}>Phone number</div>
                  <div
                    className={stylesAlt.basicInput}
                    style={{
                      border:
                        loginSuccess === false
                          ? "1px solid red"
                          : "1px solid #94a3b8",
                    }}
                  >
                    <input
                      type="text"
                      className={stylesAlt.inputText1}
                      value={email}
                      placeholder="example@gmail.com"
                      style={{ border: "none" }}
                      onChange={handleEmail}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
              {
                buttonDisabled ? (
                  <>
                    <div className={styles.text1}>You can re apply reset password link within <b>{countdown}</b> seconds</div>
                    <button
                      className={`${styles.button1} hoverButtons ${styles.buttonDisabled}`}
                      onClick={handleResetPassword}
                      onKeyDown={handleKeyDown}
                      tabIndex={0}
                      disabled={true}
                    >
                      <div className={styles.text1}>Send reset link</div>
                    </button>
                  </>
                ):(
                  <button
                    className={`${styles.button1} hoverButtons`}
                    onClick={handleResetPassword}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    disabled={false}
                  >
                    <div className={styles.text1}>Send reset link</div>
                  </button>
                )
              }
            </div>
          </div>
        </div>
      )
    }

      {loginSuccess === false && (
        <div className={stylesAlt.errorParent}>
          <div>Login or password is incorrect!</div>
        </div>
      )}
      {forgotPassword === false && (
        <div className={stylesAlt.errorParent}>
          <p>{forgotPasswordMessage}</p>

          <p>Please, check your email</p>
        </div>
      )}
      {forgotPassword === true && (
        <div
          className={stylesAlt.errorParent}
          style={{ backgroundColor: "#32CD32" }}
        >
          <p>{forgotPasswordMessage}</p>

          <p>Please, check your email</p>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
