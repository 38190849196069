 const planFeaturesStarter = [
  "Up to 25 docs / month",
  "Up to 6 views",
  "Up to 4 integrations",
  "Unlimited makers & collaborators",
  "Inbox",
];
 const planFeaturesPro = [
  "Up to 25 docs / month",
  "Up to 6 views",
  "Up to 4 integrations",
  "Unlimited makers & collaborators",
  "Inbox",
];
 const planFeaturesCustom = [
  "Up to 25 docs / month",
  "Up to 6 views",
  "Up to 4 integrations",
  "Unlimited makers & collaborators",
  "Inbox",
];
export const subscriptionPlans = [
  // {
  //   title: "Starter",
  //   titleBgColor: {backgroundColor: '#FEEDE5',color: '#D97706'},
  //   // titleTextColor:{backgroundColor: '#FA6F32',color: '#FFF',alignSelf: "stretch"},
  //   titleTextColor:{alignSelf: "stretch"},
  //   price: "Free",
  //   subtitle: "Forever",
  //   description: "Molestie lobortis massa.",
  //   features: planFeaturesFree,
  //   buttonText: "Sign up",
  // },
  {
    title: "Rental Plan",
    titleBgColor: {backgroundColor: '#FEEDE5',color: '#DC4705'},
    // titleTextColor:{border: '1px solid #CBD5E1', backgroundColor: '#fff',color: '#475569',alignSelf: "stretch"},
    titleTextColor:{alignSelf: "stretch"},

    price: "$25 / mo",
    subtitle: " Per Truck",
    description: "Everything in Starter, plus:",
    features: planFeaturesStarter,
    buttonText: "Sign up",
  },
  {
    title: "Lease Plan",
    titleBgColor: {backgroundColor: '#FEEDE5',color: '#DC4705'},
    // titleTextColor:{backgroundColor: '#FA6F32',color: '#FFF',alignSelf: "stretch"},
    titleTextColor:{alignSelf: "stretch"},
    price: "$45 / mo ",
    subtitle: "Per Truck",
    description: "Everything in Pro, plus:",
    features: planFeaturesPro,
    buttonText: "Sign up",
  },
  {
    title: "Business",
    titleBgColor: {backgroundColor: '#ECF0FF',color: '#3C85EB'},
    // titleTextColor:{backgroundColor: '#F1F5F9',color: '#0F172A',alignSelf: "stretch"},
    titleTextColor:{alignSelf: "stretch"},
    price: "Custom",
    subtitle: "Forever",
    description: "Everything in Pro, plus:",
    features: planFeaturesCustom,
    buttonText: "Contact Sales",
  },
];