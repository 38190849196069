import React from "react";
import styles from "../Map_Page/MapPage.module.css";
import AdminNavBarLeadingContent from "./AdminNavBarLeadingContent";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../components/Global/GlobalState";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminNavBar: React.FC = () => {
  const { setSideBarToggle } = useGlobalContext();
  const currentUser = useSelector((state: any) => state.currentUser);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid={true}>
          <Link to={currentUser && currentUser.role == 'renter' ? '/admin/truck_management' : '/main'}>
            <img
              className={styles.theLogoMakerBuiltFor1000}
              alt=""
              src="/generics//Royban.png"
              role="button"
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=> setSideBarToggle(false)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-2">
              <AdminNavBarLeadingContent isCollapsed={true} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavBar;
