import { useEffect, useState } from "react";
import axios from "axios";
import Avvvatars from "avvvatars-react";
import styles from "./css/UserAdministration.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocalConvenienceStoreOutlinedIcon from "@mui/icons-material/LocalConvenienceStoreOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setOrganization } from "../../store/actions/organizationAction";
import { REACT_APP_ORG_API_BASE_URL } from "../../utils/UrlConstants";
import { useGlobalContext } from "../../components/Global/GlobalState";
import { ICollapsedsidebar } from "./types";

const AdminNavBarLeadingContent: React.FC<ICollapsedsidebar> = ({
  isCollapsed,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state: any) => state);
  const userOrganization: string = reduxState.currentUser.organizations[0];
  const currentUser = useSelector((state: any) => state.currentUser);
  const { sideBarToggle, setSideBarToggle } = useGlobalContext();
  const [currentUrl] = useState<string>(window.location.href);

  useEffect(() => {
    const fetchOrganization = async (id: string) => {
      const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          dispatch(setOrganization(response.data.organization));
        }
      } catch (error) {
        console.error("Error fetching organization:", error);
      }
    };

    if (userOrganization) {
      fetchOrganization(userOrganization);
    }
  }, [userOrganization, dispatch]);

  return (
    <>
      <div
        className="bg-white d-flex flex-column justify-content-between vh-100 z-3"
        style={{ fontSize: "14px", color: "#4b5563", fontFamily: "Inter" }}
       >
        <div>
          {!isCollapsed && (
            <div className="py-3 px-3">
              {!sideBarToggle ? (
                <div className="d-flex justify-content-between align-items-center">
                  <Link
                    to={
                      currentUser && currentUser.role == "renter"
                        ? "/admin/truck_management"
                        : "/main"
                    }
                   >
                    <img
                      className={styles.theLogoMakerBuiltFor1000}
                      src="/logos/Truck4 logo-1.png"
                    />
                  </Link>
                  <div
                    className={styles.iconoutlinearrowHide}
                    onClick={() => setSideBarToggle(!sideBarToggle)}
                  >
                    <img className={styles.icon} alt="" src="/admin/icon.svg" />
                    <div className={styles.iconoutlinearrowHideChild} />
                  </div>
                </div>
              ) : (
                <div
                  className="cursor-pointer"
                  onClick={() => setSideBarToggle(!sideBarToggle)}
                >
                  <MenuIcon sx={{ color: "#8C939D" }} />
                </div>
              )}
            </div>
          )}

          <div className="d-flex flex-column gap-1 px-2">
            <Link
              className={`${styles.navigationLinks} ${
                currentUrl.includes("/admin") && !currentUrl.includes("admin/")
                  ? "bg-gray"
                  : ""
              }`}
              to="/admin"
              title="Main Page"
             >
              <img src="/admin/home.svg" />
              {!sideBarToggle && <span>Main Page</span>}
            </Link>
            <Link
              className={`${styles.navigationLinks} ${
                currentUrl.includes("/admin/ads/reservations") ? "bg-gray" : ""
              }`}
              to="/admin/ads/reservations"
              title="Bookings"
            >
              <MonetizationOnOutlinedIcon sx={{ color: "#8C939D" }} />
              {!sideBarToggle && <span>Bookings</span>}
            </Link>
            <span className="border-bottom" />

            <Link
              className={`${styles.navigationLinks} ${
                currentUrl.includes("/admin/truck_management") ? "bg-gray" : ""
              }`}
              to="/admin/truck_management"
              title="Asset Management"
            >
              <LocalShippingOutlinedIcon sx={{ color: "#8C939D" }} />
              {!sideBarToggle && <span>Asset Management</span>}
            </Link>

            <Link
              className={`${styles.navigationLinks} ${
                currentUrl.includes("/admin/blogs") ? "bg-gray" : ""
              }`}
              to="/blogs"
              title="Blogs"
            >
              <ArticleOutlinedIcon sx={{ color: "#8C939D" }} />
              {!sideBarToggle && <span>Blogs Page</span>}
            </Link>

            {reduxState.currentUser.role == "admin" && (
              <Link
                className={`${styles.navigationLinks} ${
                  currentUrl.includes("/admin/organizations_management")
                    ? "bg-gray"
                    : ""
                }`}
                to="/admin/organizations_management"
                title="Organizations"
              >
                <CorporateFareIcon sx={{ color: "#8C939D" }} />
                {!sideBarToggle && <span>Organizations</span>}
              </Link>
            )}

            {!sideBarToggle ? (
              <div>
                <Accordion
                  defaultExpanded={
                    currentUrl.includes("settings") ||
                    currentUrl.includes("organization") ||
                    currentUrl.includes("user_administration")
                      ? true
                      : false
                  }
                  style={{ boxShadow: "unset", width: "100%", padding: "0" }}
                >
                  <AccordionSummary
                    style={{ padding: "unset" }}
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    title="Settings"
                  >
                    <div className="d-flex align-items-center gap-2 p-2">
                      <SettingsOutlinedIcon sx={{ color: "#8C939D" }} />
                      <span>Settings</span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                    <Link
                      className={styles.sidebarLinks}
                      to={`/admin/settings/profile`}
                      title="Profile"
                    >
                      <div
                        className={`${
                          currentUrl.includes("admin/settings/profile")
                            ? "bg-gray"
                            : ""
                        } ${styles.settingSubTab} mt-1`}
                      >
                        <span
                          className={`${styles.line} ${styles.firstLine}`}
                        ></span>
                        <div className={`${styles.content10}`}>
                          <div className={styles.text11}>Profile</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      className={styles.sidebarLinks}
                      to={`/organization/${userOrganization}`}
                      title="Company"
                    >
                      <div
                        className={`${
                          currentUrl.includes("/organization/") ? "bg-gray" : ""
                        } ${styles.settingSubTab} mt-1`}
                      >
                        <span className={`${styles.line}`}></span>
                        <div className={styles.content10}>
                          <div className={styles.text11}>Company</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      className={styles.sidebarLinks}
                      to="/admin/user_administration"
                      title="User Administration"
                    >
                      <div
                        className={`${
                          currentUrl.includes("admin/user_administration")
                            ? "bg-gray"
                            : ""
                        } ${styles.settingSubTab} mt-1`}
                      >
                        <span
                          className={`${styles.line} ${styles.lastLine}`}
                        ></span>
                        <div className={styles.content10}>
                          <div className={styles.text11}>
                            User Administration
                          </div>
                        </div>
                      </div>
                    </Link>
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <div>
                <Link
                  className={styles.sidebarLinks}
                  to={`/admin/settings/profile`}
                  title="Profile"
                >
                  <div
                    className={`${
                      currentUrl.includes("admin/settings/profile")
                        ? "bg-gray"
                        : ""
                    } ${styles.settingSubTab} mt-1`}
                  >
                    <div className={`${styles.content10}`}>
                      <AccountBoxOutlinedIcon sx={{ color: "#8C939D" }} />
                    </div>
                  </div>
                </Link>
                <Link
                  className={styles.sidebarLinks}
                  to={`/organization/${userOrganization}`}
                  title="Company"
                >
                  <div
                    className={`${
                      currentUrl.includes("/organization") ? "bg-gray" : ""
                    } ${styles.settingSubTab} mt-1`}
                  >
                    <div className={styles.content10}>
                      <LocalConvenienceStoreOutlinedIcon
                        sx={{ color: "#8C939D" }}
                      />
                    </div>
                  </div>
                </Link>
                <Link
                  className={styles.sidebarLinks}
                  to="/admin/user_administration"
                  title="User Administration"
                >
                  <div
                    className={`${
                      currentUrl.includes("admin/user_administration")
                        ? "bg-gray"
                        : ""
                    } ${styles.settingSubTab} mt-1`}
                  >
                    <div className={styles.content10}>
                      <ManageAccountsOutlinedIcon sx={{ color: "#8C939D" }} />
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <Link
              className={`${styles.navigationLinks} ${
                currentUrl.includes("/admin/subscription") ? "bg-gray" : ""
              }`}
              to="/admin/subscription"
              // title="Bookings"
             >
              <div
                className="d-flex justify-content-between align-items-center cursor-pointer"
                title="Subscription Free plan"
              >

                <div className="d-flex gap-2 pe-2">
                  <img src="/admin/iconoutlinelightningbolt.svg"/>
                  {!sideBarToggle && <span>Subscription</span>}
                </div>
                {!sideBarToggle && (
                  <div className={`${styles.tag} hoverButtons`}>
                    <span className={styles.text13}>Free plan</span>
                  </div>
                )}
              </div>
            </Link>
          </div>



        </div>

        <div onClick={undefined}>
          <div className="border-top"/>
          <Link
            className={styles.navigationLinks}
            to={`/organization/${userOrganization}`}
            title="User Info"
          >
            <Avvvatars size={44} value={reduxState.currentUser?.email}/>
            {!sideBarToggle && (
              <div className="text-start">
                <p className="mb-1">
                  <strong>
                    {reduxState.currentUser.firstName +
                      " " +
                      reduxState.currentUser.lastName}
                  </strong>
                </p>
                <p className="mb-1 text-muted">
                  <small>{reduxState.organization.name}</small>
                </p>
                <div
                  className="rounded-pill text-center py-2"
                  style={{ backgroundColor: "lightblue", width: "4rem" }}
                >
                  <small>{reduxState.currentUser.role.toUpperCase()}</small>
                </div>
              </div>
            )}
          </Link>
        </div>
      </div>
    </>
  );
};

export default AdminNavBarLeadingContent;
