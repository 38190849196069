import styles from '../../styles/Registration/OwnerRegistrationSidebar.module.css';

const OwnerRegistrationSidebar = () => {
  return (
    <>
      <section className={styles.sidebarContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>
            One place to manage everything
          </h2>
          <p className={styles.description}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </p>
        </div>
        <img className={styles.image} alt="RegistrationSidebarOwner.png" src="/Registration/RegistrationSidebarOwner.png" />
      </section>
    </>
  )
}

export default OwnerRegistrationSidebar;
