import { useState } from "react";
import styles from "../../css/Login/Login.module.css";
import stylesAlt from "../../css/Login/LoginNoRegister.module.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { errorToast, successToast } from "../Toast/ToastMessage";
import { getNoAuthHeaders } from "../../utils/GetHeaders";
import { setTokens } from "../../utils/SetTokens";
import { REACT_APP_AUTH_SVC_API_BASE_URL } from "../../utils/UrlConstants";
import { useGlobalContext } from "../Global/GlobalState";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setOpenForgot, setOpenLogin } = useGlobalContext();
  const [loginSuccess, setLoginSuccess] = useState<boolean | null>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleNextClick();
    }
  };

  const handleNextClick = async () => {
    try {
      const response: any = await fetch(
        `${REACT_APP_AUTH_SVC_API_BASE_URL}/login`,
        {
          method: "POST",
          headers: getNoAuthHeaders(),
          body: JSON.stringify({
            email: email.toLowerCase(),
            password: password,
          }),
        }
      );

      if (response.ok) {
        setLoginSuccess(true);
        setTokens(
          response.headers.get("x-access-token"),
          response.headers.get("x-refresh-token")
        );
        setOpenLogin(false);
        setOpenForgot(false);
        successToast("Logged in successfully");
        navigate("/admin/truck_management");
      } else {
        errorToast("Email or password is incorrect!");
        setLoginSuccess(false);
      }
    } catch (error) {
      errorToast("Error during login");
      setLoginSuccess(false);
    }
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenSignUp = () => {
    navigate("/registration/driver");
    setOpenLogin(false);
  }

  return (
    <div className={`${stylesAlt.dialogWindowNoRegister}`}>
      <div className={stylesAlt.rectangleParent}>
        <div className={stylesAlt.frameChild} />

        <div className={stylesAlt.userName1}>Welcome to Truck4</div>
        <div className={stylesAlt.inputWithLabelParent}>
          <div className={stylesAlt.inputWithLabel}>
            <div className={stylesAlt.inputText}>Phone number</div>
            <div
              className={stylesAlt.basicInput}
              style={{
                border:
                  loginSuccess === false
                    ? "1px solid red"
                    : "1px solid #94a3b8",
              }}
            >
              <input
                type="text"
                className={stylesAlt.inputText1}
                value={email}
                placeholder="example@gmail.com"
                style={{ border: "none", paddingLeft: "1vh" }}
                onChange={handleEmail}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className={stylesAlt.inputWithLabel}>
            <div className={stylesAlt.inputText}>Phone number</div>
            <div
              className={stylesAlt.basicInput}
              style={{
                border:
                  loginSuccess === false
                    ? "1px solid red"
                    : "1px solid #94a3b8",
              }}
            >
              <input
                className={stylesAlt.inputText1}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                style={{
                  border: "none",
                  height: "100%",
                  top: "0",
                  background: "transparent",
                  paddingLeft: "1vh",
                }}
                onChange={handlePassword}
                onKeyDown={handleKeyDown}
              />
              {showPassword ? (
                <VisibilityOffIcon
                  onClick={handleTogglePasswordVisibility}
                  className={`${stylesAlt.iconoutlineeye} hoverButtons`}
                />
              ) : (
                <VisibilityIcon
                  onClick={handleTogglePasswordVisibility}
                  className={`${stylesAlt.iconoutlineeye} hoverButtons`}
                />
              )}
            </div>
          </div>
          <div
            className={`w-100 text-center ${stylesAlt.inputText4} hoverButtons`}
            style={{ width: "fit-content" }}
            onClick={() => {
              setOpenLogin(false);
              setOpenForgot(true);
            }}
          >
            Forgot your password?
          </div>
          <div className={stylesAlt.inputText4} style={{ textAlign: "center" }} onClick={handleOpenSignUp} >
            Don't have an account yet? Sign up!
          </div>
        </div>

        <div
          className={`${styles.button1} hoverButtons`}
          onClick={handleNextClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <div className={styles.text1}>Log in</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
