import React, {useEffect, useState} from "react";
import styles from "./MapPage.module.css";
import styles2 from "../Map_Page/Filter/MapPageFilter.module.css";
import {MapPageFilter} from "./Filter/MapPageFilter";
import AdminSidebar from "../Admin/AdminSidebar";
import MapComponent from "./MapComponent";
import ArrowBackIosIcon from "@mui/icons-material/KeyboardArrowDown";
import {manuallyImportedTrailerDataOnly, manuallyImportedTruckData, manuallyImportedTruckDataOnly,} from "./Constants";
import TrucksList from "./TrucksList";
import MapPageSelector from "./MapPageSelector";
import FieldSelector from "./Adaptive_Map_Page/FieldSelector";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import {REACT_APP_PAGINATION_OFFSET, REACT_APP_TRUCK_API_BASE_URL,} from "../../utils/UrlConstants";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,} from "@mui/material";
import {useGlobalContext} from "../../components/Global/GlobalState";
import Drawer from "@mui/material/Drawer";

const MapPage: React.FC = () => {
  const {
    searchString,
    searchByLocation,
    truckSearch,
    setTruckSearch,
    setSearchString,
    setSearchByLocation,
  } = useGlobalContext();

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [trucks, setTrucks] = useState([]);
  const [fullDataCount, setfullDataCount] = useState<number>(0);
  const [offersCount, setOffersCount] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedPrecategory, setSelectedPrecategory] = useState<string>("");
  const [isPortalOpen, setIsPortalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [liftGate, setliftGate] = useState<string>("");
  const [bodyType, setbodyType] = useState<string>("");
  const [doorType, setdoorType] = useState<string>("");
  const [length, setlength] = useState<string>("");
  const [transmission, settransmission] = useState<string>("");
  const [goal, setGoal] = useState<string>("");
  const [others, setothers] = useState<string>("");
  const [menufacturer, setmenufacturer] = useState<string>("");
  const [minimumPrice, setMinimumPrice] = useState<number>(0);
  const [maximumPrice, setMaximumPrice] = useState<number>(0);
  const [selectedTruck, setSelectedTruck] = useState<string | null>(null);
  const [yearMin, setYearMin] = useState<number>(0);
  const [yearMax, setYearMax] = useState<number>(0);
  const [mileageFrom, setMileageFrom] = useState<number>(0);
  const [mileageTo, setMileageTo] = useState<number>(0);
  const [resetSelected, setResetSelected] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState("latest");
  const [onlyLeaseForPurhase, setOnlyLeaseForPurhase] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(true);
  const [loading, setloading] = useState<boolean>(true);
  const [vehicleType, setVehicleType] = useState<string>("");
  const [vehicleSubType, setVehicleSubType] = useState<string>("");
  const [mileage, setMileage] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [feet, setFeet] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const allTruckData = [...manuallyImportedTruckData];
  const allOnlyTruckData = [...manuallyImportedTruckDataOnly];
  const allOnlyTrailerData = [...manuallyImportedTrailerDataOnly];
  //PAGINATION
  const offset: number = parseInt(REACT_APP_PAGINATION_OFFSET || "8");
  const startIdx = (currentPage - 1) * offset;

  useEffect(() => {
    if (searchString != "") {
      setSelectedTruck(null);
      setResetSelected(!resetSelected);
      setVehicleType("");
      setVehicleSubType("");
    }
  }, [searchString]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    fetchTrucks({sortBy: sortBy});
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    if (searchString != "") {
      fetchTrucks({
        filters: generateFilterParams(),
        leaseForPurchaseOnly: onlyLeaseForPurhase,
        sortBy: sortBy
      });
      setCurrentPage(1)
    }
  }, [searchString, truckSearch]);

  useEffect(() => {
      fetchTrucks({
        filters: generateFilterParams(),
        leaseForPurchaseOnly: onlyLeaseForPurhase,
        sortBy: sortBy
      });
      setCurrentPage(1)


  }, [ searchByLocation]);


  const fetchTrucks = async (query: any = null) => {
    setTruckSearch("");
    setloading(true);
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks?status=active&offset=${offset}&page=${currentPage}`;
    if (query != null) {
      url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks?status=active&offset=${offset}&page=${query.page || 1}
        &${query?.filters?.replace(/,/g, "") != "" ? query.filters : ""}
        &priceMin=${minimumPrice != 0 ? minimumPrice : ""}
        &priceMax=${maximumPrice != 0 ? maximumPrice : ""}
        &mileageMin=${mileageFrom != 0 ? mileageFrom : ""}
        &mileageMax=${mileageTo != 0 ? mileageTo : ""}
        &mileage=${mileage}
        &year=${year}
        &yearMin=${yearMin != 0 ? yearMin : ""}
        &yearMax=${yearMax != 0 ? yearMax : ""}
        &location=${searchByLocation}
        ${!query.vehicleSubtype && searchString ? `&searchString=${searchString}` : ""}
        ${truckSearch ? `&searchString=${truckSearch}` : ""}`;
    }

    url = url?.replace(/ /g, "")?.replace(/,,/g, "")?.replace(/&&/g, "&");
    url = query && query.vehicleSubtype ? `${url}&searchString=${query.vehicleSubtype}` : url;
    url = query && query.vehicleType ? `${url}&searchString=${query.vehicleType}` : url;
    url = query && query.leaseForPurchaseOnly ? `${url}&goal=LeaseToPurchase` : url;
    url = query && query.sortBy !== "" ? `${url}&sort=${query.sortBy}` : url;

    try {
      const response = await axios.get(url);
      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
      setShowToast(true);
    } catch (error) {
      setTrucks([]);
      setfullDataCount(0);
      setloading(false);
      console.error("Error fetching trucks:", error);
    }
  };

  const resetFilters = () => {
    setSelectedTruck(null);
    setMinimumPrice(0);
    setMaximumPrice(0);
    setYearMin(0);
    setYearMax(0);
    setMileageFrom(0);
    setMileageTo(0);
    setmenufacturer("");
    setliftGate("");
    setbodyType("");
    setdoorType("");
    setlength("");
    settransmission("");
    setothers("");
    setGoal("");
    setMileage("");
    setYear("");
    setSearchString("");
    setTruckSearch("");
    setSearchByLocation("");
    setOnlyLeaseForPurhase(false);
    setVehicleType("");
    setVehicleSubType("");
    setResetSelected(!resetSelected);
    setSortBy("");
    fetchTrucks({sortBy: "latest"});
  };

  const getSubCatTrucks = (subCat: string) => {
    setVehicleSubType(subCat);
    fetchTrucks({
      leaseForPurchaseOnly: onlyLeaseForPurhase,
      vehicleSubtype: subCat,
      vehicleType: vehicleType,
      filters: generateFilterParams(),
      sortBy: sortBy,
    });
    setCurrentPage(1)
  };

  const getCatTrucks = (cat: string) => {
    setVehicleType(cat);
    fetchTrucks({
      leaseForPurchaseOnly: onlyLeaseForPurhase,
      vehicleType: cat,
      filters: generateFilterParams(),
      vehicleSubtype: vehicleSubType,
      sortBy: sortBy,
    });
  };

  const generateFilterParams = () => {
    const removeSpacesAndCommas = (str: string) =>
      str?.replace(/ /g, "")?.replace(/,,/g, "");
    const buildParam = (paramName: string, paramValue: string) =>
      paramValue ? `&${paramName}=${paramValue}` : "";

    let filters = "";
    filters += buildParam(
      "liftGateType",
      removeSpacesAndCommas(liftGate) == ","
        ? ""
        : removeSpacesAndCommas(liftGate)
    );
    filters += buildParam(
      "vehicleType",
      removeSpacesAndCommas(bodyType) == ","
        ? ""
        : removeSpacesAndCommas(bodyType)
    );
    filters += buildParam(
      "doorType",
      removeSpacesAndCommas(doorType) == ","
        ? ""
        : removeSpacesAndCommas(doorType)
    );
    filters += buildParam(
      "length",
      removeSpacesAndCommas(length) == "," ? "" : removeSpacesAndCommas(length)
    );
    filters += buildParam(
      "transmissionType",
      removeSpacesAndCommas(transmission) == ","
        ? ""
        : removeSpacesAndCommas(transmission)
    );
    filters += buildParam(
      "others",
      removeSpacesAndCommas(others) == "," ? "" : removeSpacesAndCommas(others)
    );
    filters += buildParam(
      "manufacturer",
      removeSpacesAndCommas(menufacturer) == ","
        ? ""
        : removeSpacesAndCommas(menufacturer)
    );
    filters += buildParam(
      "goal",
      removeSpacesAndCommas(goal) == "," ? "" : removeSpacesAndCommas(goal)
    );

    return filters;
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handlePassedOffersCount = (count: number) => {
    setOffersCount(count);
  };

  const handleHeadlineClick = () => {
    setIsPortalOpen(!isPortalOpen);
  };

  const handleItemClick = (title: string) => {
    setSelectedItem(title);
  };

  const handlePrecategory = (title: string) => {
    setSelectedPrecategory(title);
  };

  const closeFilter = () => {
    setIsPortalOpen(false);
    fetchTrucks({
      leaseForPurchaseOnly: onlyLeaseForPurhase,
      pageSize: "",
      filters: generateFilterParams(),
      sortBy: sortBy,
      vehicleType: vehicleType,
      vehicleSubtype: vehicleSubType,
    });
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    fetchTrucks({
      leaseForPurchaseOnly: onlyLeaseForPurhase,
      page: page,
      filters: generateFilterParams(),
      sortBy: sortBy,
    });
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchTrucks({
        leaseForPurchaseOnly: onlyLeaseForPurhase,
        page: currentPage - 1,
        filters: generateFilterParams(),
        sortBy: sortBy,
      });
    }
  };

  const handleNextClick = () => {
    if (currentPage < Math.ceil(fullDataCount / offset)) {
      setCurrentPage(currentPage + 1);
      fetchTrucks({
        leaseForPurchaseOnly: onlyLeaseForPurhase,
        page: currentPage + 1,
        filters: generateFilterParams(),
        sortBy: sortBy,
      });
    }
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
    fetchTrucks({
      sortBy: event.target.value,
      leaseForPurchaseOnly: onlyLeaseForPurhase,
      filters: generateFilterParams(),
      vehicleType: vehicleType,
      vehicleSubtype: vehicleSubType,
    });
  };

  return (
    <>
      {isPortalOpen && (
        <>
          <MapPageFilter
            setSelectedTruck={setSelectedTruck}
            setSelectedPrecategory={setSelectedPrecategory}
            setSelectedItem={setSelectedItem}
            setVehicleType={setVehicleType}
            setVehicleSubType={setVehicleSubType}
            closeFilter={closeFilter}
            fetchTrucks={fetchTrucks}
            passedOffersCount={handlePassedOffersCount}
            setResetSelect={setResetSelected}
            setliftGate={setliftGate}
            setbodyType={setbodyType}
            setdoorType={setdoorType}
            setlength={setlength}
            settransmission={settransmission}
            setothers={setothers}
            setGoal={setGoal}
            setYearMin={setYearMin}
            setYearMax={setYearMax}
            yearMin={yearMin}
            yearMax={yearMax}
            setMinimumPrice={setMinimumPrice}
            setMaximumPrice={setMaximumPrice}
            setmenufacturer={setmenufacturer}
            setMileageFrom={setMileageFrom}
            setMileageTo={setMileageTo}
            setIsPortalOpen={setIsPortalOpen}
            setMileage={setMileage}
            setYear={setYear}
            setFeet={setFeet}
            feet={feet}
            year={year}
            mileage={mileage}
            bodyType={bodyType}
            liftGate={liftGate}
            doorType={doorType}
            length={length}
            transmission={transmission}
            others={others}
            goal={goal}
            resetSelected={resetSelected}
            minimumPrice={minimumPrice}
            maximumPrice={maximumPrice}
            menufacturer={menufacturer}
            mileageFrom={mileageFrom}
            mileageTo={mileageTo}
          />
        </>
      )}

      <Drawer open={open} onClose={toggleDrawer(false)}>
        <AdminSidebar isCollapsed={true}/>
      </Drawer>

      <div className={styles.mapPage} style={{paddingBottom: "7vh"}}>
        {windowWidth < 850 ? (
          <>
            <FieldSelector
              selectedPrecategory={selectedPrecategory}
              handlePrecategory={handlePrecategory}
              allTruckData={allTruckData}
              selectedItem={selectedItem}
              handleItemClick={handleItemClick}
              handleHeadlineClick={handleHeadlineClick}
            />
          </>
        ) : (
          <div className={styles.borderBottom}>
          <div className={styles.emptyDivParent}>
            {/*<div className={styles.frameChild23}/>*/}
            <div
              className={styles.rectangleParent}
              style={{display: "flex", flexDirection: "row"}}
            >
              <MapPageSelector
                selectedTruck={selectedTruck}
                setSelectedTruck={setSelectedTruck}
                selectedPrecategory={selectedPrecategory}
                handlePrecategory={handlePrecategory}
                allTruckData={allTruckData}
                allTruckDataOnly={allOnlyTruckData}
                allTrailerDataOnly={allOnlyTrailerData}
                selectedItem={selectedItem}
                handleItemClick={handleItemClick}
                getSubCatTrucks={getSubCatTrucks}
                getCatTrucks={getCatTrucks}
                isResetSelected={resetSelected}
                setResetSelected={setResetSelected}
              />

              <div
                className={`${styles.button11} hoverButtons`}
                onClick={handleHeadlineClick}
              >
                <img
                  className={styles.iconoutlinesearch}
                  alt=""
                  src="/generics/iconoutlinefilter.svg"
                />
                <div className={styles.headline}>
                  <span className={styles.filterSpan}>Filter</span>
                </div>
              </div>
            </div>
            {/*<div className={styles.frameChild23}/>*/}
          </div>
          </div>
        )}

        <div className={styles.mainContent}>
          <div className={styles.topMap}>
            <MapComponent
              styles={styles}
              trucks={trucks}
              id={styles.topMap}
              width="100vw"
            />
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameGroup}>
              <div className={styles.frameDiv}>
                <div className={styles.resultsFound}>
                  {fullDataCount} results found
                  {truckSearch ? ` for ${truckSearch}s` : ""}
                  {truckSearch ? (
                    <span
                      className={styles.clickHereToResetFilters}
                      onClick={resetFilters}
                    >
                      {" "}
                      Click here to reset filters
                    </span>
                  ) : null}
                </div>
                <div style={{ display: "flex", gap: "1vh",margin:"auto"}}>
                  <div
                    className={`${styles2.tag11} hoverButtons ${
                      styles2.tagParent
                    }  ${
                      onlyLeaseForPurhase
                        ? `${styles2.tag27} ${styles2.tag11}`
                        : ""
                    }`}
                    onClick={() => {
                      setOnlyLeaseForPurhase(!onlyLeaseForPurhase);
                      fetchTrucks({
                        leaseForPurchaseOnly: !onlyLeaseForPurhase,
                        sortBy: sortBy,

                        filters: generateFilterParams(),
                        vehicleType: vehicleType,
                        vehicleSubtype: vehicleSubType,
                      });
                    }}
                  >
                    <img
                      className={styles2.earnRevenueFromYourIdleFl}
                      alt=""
                      src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                    />
                    <div className={styles2.reefer} >Show lease to purchase</div>
                    {onlyLeaseForPurhase && (
                      <img
                        className={styles2.iconsolidx1}
                        alt=""
                        src="close.svg"
                      />
                    )}
                  </div>
                  <div
                    style={{padding: 0}}
                    className={`${styles.button7} hoverButtons`}
                  >
                    <FormControl className={styles.formControl}>
                      <InputLabel
                        className={styles.headline}
                        id="demo-simple-select-autowidth-label"
                        sx={{
                          transition: "opacity 0.2s ease, visibility 0.2s ease",
                          ...(focused && {
                            opacity: 0,
                            visibility: "hidden",
                          }),
                          visibility: "hidden",
                        }}
                      >
                        Sort by
                      </InputLabel>
                      <Select
                        IconComponent={ArrowBackIosIcon}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        inputProps={{"aria-label": "Without label"}}
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={sortBy}
                        onChange={handleSortChange}
                        autoWidth
                        className={styles.select}
                        displayEmpty
                        sx={{
                          textAlign: "center",
                          fontWeight: 600,
                          color: "#475569",
                          fontSize: "14px",
                          minWidth: 100,
                          border: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover": {
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem value="" style={{display: "none"}} disabled>
                          Sort by
                        </MenuItem>
                        <MenuItem style={{fontSize: "14px"}} value={"latest"}>
                          Newest
                        </MenuItem>
                        <MenuItem style={{fontSize: "14px"}} value={"oldest"}>
                          Oldest
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Loader/>
            ) : (
              <div className={`${styles.vecicleCardGroup}`}>
                <TrucksList
                  trucks={trucks}
                  startIdx={startIdx}
                  styles={styles}
                />
              </div>
            )}

            {showToast && (
              <>
                <div className={styles.frameChild14}/>
                <div className={styles.toast}>
                  <div className={styles.iconoutlinetruckParent2}>
                    <img
                      className={styles.iconoutlinebell}
                      alt=""
                      src="/generics/iconoutlinetruck2.svg"
                    />
                    <div className={styles.moreDistantButNoLessImpoParent}>
                      <div className={styles.tandemTractor}>
                        More distant, but not less important
                      </div>
                      <div className={styles.clearTheFilter}>
                        Clear the filter to view all transport options
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterContainer}>
                    <div
                      className={`${styles.button10}  hoverButtons`}
                      onClick={resetFilters}
                    >
                      <div className={styles.headline}>Clear filters</div>
                    </div>
                    <CancelIcon onClick={hideToast}/>
                  </div>
                </div>
              </>
            )}
            <div className={styles.centeredPageNumbersdesktop}>
              <div className={styles.topBorder}/>
              <div className={styles.links}>
                <div className={styles.moreDistantButNoLessImpoParent}>
                  <div className={styles.topBorder1}/>
                  <div
                    className={`${styles.content} hoverButtons`}
                    onClick={handlePreviousClick}
                  >
                    <img
                      className={styles.iconoutlinesearch}
                      alt=""
                      src="/generics/arrow-narrow-left1.svg"
                    />
                    <div className={`${styles.previous} `}>Previous</div>
                  </div>
                </div>
                <div className={styles.numberLinks}>
                  {[...Array(Math.ceil(fullDataCount / offset))].map(
                    (_, index) => (
                      <div
                        key={index}
                        className={`${styles.moreDistantButNoLessImpoParent} ${
                          currentPage === index + 1 ? styles.activePage : ""
                        }`}
                        onClick={() => handlePageClick(index + 1)}
                      >
                        <div
                          className={
                            styles[
                              `topBorder${currentPage === index + 1 ? 3 : 1}`
                              ]
                          }
                        />
                        <div className={styles.content1}>
                          <div
                            className={`${styles.previous} hoverButtons`}
                            style={{
                              color:
                                currentPage === index + 1
                                  ? "#fb814c"
                                  : "inherit",
                            }}
                          >
                            {index + 1}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className={styles.moreDistantButNoLessImpoParent}>
                  <div className={styles.topBorder1}/>
                  <div
                    className={`${styles.content8} hoverButtons`}
                    onClick={handleNextClick}
                  >
                    <div className={`${styles.previous} hoverButtons`}>
                      Next
                    </div>
                    <img
                      className={styles.iconoutlinesearch}
                      alt=""
                      src="/generics/arrow-narrow-right1.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomMap}>
            <MapComponent
              styles={styles}
              trucks={trucks}
              id={styles.bottomMap}
              width="53vw"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MapPage;
