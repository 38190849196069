export const plateStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const truckEngines = [
  "Cummins",
  "Caterpillar",
  "Detroit Diesel",
  "International",
  "Volvo",
  "Mack",
  "Mercedes-Benz",
  "Paccar",
  "Isuzu",
  "Hino",
  "Scania",
  "MAN",
  "Perkins",
  "Navistar",
  "Deutz",
  "Fuso",
  "UD Trucks",
  "Tata Motors",
  "Cummins Westport",
  "Yanmar",
  "John Deere",
  "Duramax",
  "Power Stroke",
  "MaxxForce",
  "DD13",
  "DD15",
  "DD16",
  "MP7",
  "MP8",
  "MP10",
  "MX-13",
  "X12",
  "X15",
];

export const subtypeValues = [
  "lowboy semi-trailer",
  "drop deck semi-trailer",
  "box trucks dry Van trucks / straight trucks moving cutaway-cube",
  "flatbed semi-trailer -48ft -53ft",
  "dry van semi-trailer",
  "hot shot trucks mounted un-mounted",
  "other trucks",
  "other trailers",
  "expeditor trucks",
  "car hauler trucks",
  "reefer / refrigerated trucks",
  "flatbed trucks",
  "sleeper trucks",
  "glider kit trucks",
  "day cab trucks",
  "car carrier trailers semi-trailers -enclosed -open",
  "reefer semi-trailer",
  "beverage trucks mounted un-mounted",
  "trailer",
  "truck",
];

export const subtypeTrucks = [
  "sleeper trucks",
  "day cab trucks",
  "car hauler trucks",
  "glider kit trucks",
  "flatbed trucks",
  "hot shot trucks mounted un-mounted",
  "other trucks",
];

export const subtypeTrailers = [
  "dry van semi-trailer",
  "reefer semi-trailer",
  "flatbed semi-trailer -48ft -53ft",
  "drop deck semi-trailer",
  "lowboy semi-trailer",
  "car carrier trailers semi-trailers -enclosed -open",
  "conestoga",
  "step deck",
  "other trailers",
];

export const subtypeBoxTrucks = [
  "box trucks dry Van trucks / straight trucks moving cutaway-cube",
  "reefer / refrigerated trucks",
  "beverage trucks mounted un-mounted",
  "expeditor trucks",
];

export const subtypeTruckValues = [];

export const subtypeTrailerValues = [];

export const truckManufacturers = [
  "Autocar",
  "Nikola",
  "BYD",
  "Peterbilt",
  "Caterpillar",
  "Renault",
  "Chevrolet",
  "Scania",
  "Ford",
  "Shacman",
  "Freightliner",
  "Sinotruk",
  "GMC",
  "Sterling",
  "International",
  "Unspecified",
  "Iveco",
  "Volvo",
  "Kenworth",
  "Western Star",
  "Mack",
  "White",
  "Man",
  "Mercedes-Benz",
  "XCMG",
];

export const trailerManufacturers = [
  "WABASH",
  "GREAT DANE",
  "HYUNDAI",
  "UTILITY",
  "VANGUARD",
  "STOUGHTON",
  "TRAILMOBILE",
  "STRICK",
  "FREUHAUF",
  "MANAC",
  "ALLOY",
  "MULTIQUIP",
  "ALLVAN",
  "NEVILLE BUILT",
  "AMERICAN",
  "OSHKOSH",
  "ATRO",
  "PEERLESS",
  "BARKER",
  "PINES",
  "BIG TEX",
  "PINES TRAILER",
  "BRIDGEVILLE",
  "PITTS",
  "BROWN",
  "PJ",
  "BWS",
  "PULLMAN",
  "CLARK",
  "RAGLAN",
  "COMET",
  "RAMIREZ",
  "DE LUCIO",
  "RELIANCE",
  "DE LUCIO USA",
  "ROAD",
  "DI-MOND",
  "ROAD SYSTEMS",
  "DORSEY",
  "ROUSSY",
  "DUNHAM",
  "RSI",
];

export const lengthOptions = ["small", "large"];

export const cargoOptions = ["railgate", "truckunder", "noliftgate"];

export const options = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

