import React from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerFinal.module.css";
import { Link } from "react-router-dom";

type VechicleToRentProps = {
  onNextButtonClick?: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  role: string;
  onClick?: () => void;
};

const RegistrationOwnerFinal: React.FC<VechicleToRentProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {

  const handleNextClick = () => {
    onNextButtonClick?.();
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  return (
    <div className={styles.vehicleOwner07}>
      <div className={styles.modal}>
        <div className={styles.iconParent}>
          <img className={styles.icon1} alt="" src="/generics/iconsolidcheckcircle.svg" />
          <div className={styles.userName}>
            Thank you for successfully registering on our website! One more step
          </div>
        </div>
        <div className={styles.inputTextContainer}>
          <div className={styles.inputText3}>
            To verify and publish the truck, you need to fill out a few more
            forms.
          </div>
          <div className={styles.inputText4}>Redirecting in 11 sec</div>
        </div>
        <div className={styles.buttonParent}>
          <div className={`${styles.button1} hoverButtons`}>
            <Link to="/main" style={{ color: "inherit" }}>
              <div className={styles.text}>I'll do it later</div>
            </Link>
          </div>
          <div className={`${styles.button2} hoverButtons`}>
            <div className={styles.text} role="button" onClick={handleNextClick}>
              Continue Verification
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationOwnerFinal;
