import styles from '../../styles/Registration/DriverRegistrationSidebar.module.css';

const DriverRegistrationSidebar = () => {
  return (
    <>
      <section className={styles.sidebarContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>
            Hire internationally with complete confidence
          </h2>
          <p className={styles.description}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </p>
        </div>
        <img className={styles.image} alt="RegistrationSidebarRenter.png" src="/Registration/RegistrationSidebarRenter.png" />
      </section>
    </>
  )
}

export default DriverRegistrationSidebar;
