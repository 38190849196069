import React from 'react';
import {subscriptionPlans} from "./Constants";
import CommonButton from "../Button/CommonButton";
import styles from "./SubscriptionCard.module.css";
const SubscriptionCards:React.FC=()=> {
  return (
  <div className={styles.subscriptionPlanContainer}>
    {subscriptionPlans.map((plan, index) => (
      <div key={index} className={styles.freeSubscriptionPlan}>
        <div className={styles.freeSubscriptionPlanHeader} >
          <div className={styles.freeSubscriptionPlanHeaderText} style={plan.titleBgColor|| { backgroundColor: "#ffffff" }}>{plan.title}</div>
        </div>
        <div className={styles.subscriptionPrice}>
          <div className={styles.subscriptionPriceTitle}>{plan.price}</div>
          <div className={styles.subscriptionPriceSubtitle}>{plan.subtitle}</div>
        </div>
        <div className={styles.planDetails}>
          <div className={styles.planDetailHeader}>
            <div className={styles.planDetailHeaderText}>{plan.description}</div>
          </div>
          {plan.features.map((feature, idx) => (
            <div key={idx} className={styles.planDetailsDescription}>
              <div className={styles.checkMark}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
                    fill="#10B981"
                  />
                </svg>
              </div>
              <div className={styles.planDescription}>{feature}</div>
            </div>
          ))}
        </div>
        <CommonButton label={plan.buttonText} style={plan.titleTextColor}/>
      </div>
    ))}
  </div>  );
}

export default SubscriptionCards;