import { combineReducers } from "redux";
import registrationReducer from "./registrationReducer";
import truckAddRegistrationReducer from "./truckAddReducer";
import updateUserToken from "./acessToken";
import currentUserData from "./userReducer";
import setOrganization from "./organizationReducer";
import mapActions from "../actions/mapActions";

const rootReducer = combineReducers({
  registrationData: registrationReducer,
  truckAddRegistrationData: truckAddRegistrationReducer,
  userTokenReducer: updateUserToken,
  currentUser: currentUserData,
  organization: setOrganization,
  mapActions: mapActions,
});

export const truckAddReducer = {
  truckAddRegistrationData: truckAddRegistrationReducer,
};

export const userTokenReducer = {
  userTokenReducer: updateUserToken,
};

export const currentUser = {
  currentUser: currentUserData,
};

export const organization = {
  organization: setOrganization,
};

export const mapActionsReducer = {
  mapActions: mapActions,
};

export default rootReducer;
