import React, { useEffect, useState } from "react";
import axios from "axios";
import styles2 from "../../Map_Page/MapPage.module.css";
import AdminSidebar from "../AdminSidebar";
import AdminNavBar from "../AdminNavBar";
import styles from "./Reservations.module.css";
import carouselStyles from "../../Map_Page/MapPage.module.css";
import Carousel from "react-bootstrap/Carousel";
import { REACT_APP_TRUCK_API_BASE_URL } from "../../../utils/UrlConstants";
import { errorToast, successToast } from "../../../components/Toast/ToastMessage";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthHeaders } from "../../../utils/GetHeaders";
import { formatDate } from "./util";

const Reservations: React.FC = () => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const [truckList, settruckList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [bookedList, setBookings] = useState<any>([]);
  const [allTrucksId, setAllTrucksId] = useState<any>([]);

  useEffect(() => {
    fetchTrucks();
    getBookings();
  }, []);

  const fetchTrucks = async (query: any = null) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks`;

    try {
      const response = await axios.get(
        url?.replace(/ /g, "")?.replace(/,,/g, "")?.replace(/&&/g, "&")
      );

      console.log("all trucks vvv");
      console.log(response.data.trucks._id);

      const truckIds = response.data.trucks.map((truck: any) => truck._id);
      setAllTrucksId(truckIds);
    } catch (error) {
      errorToast("Error fetching trucks");
      console.error("Error fetching trucks:", error);
    }
  };

  const getBookings = async () => {
    setLoading(true);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/bookings`;

    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: {
          ...getAuthHeaders(),
          "Content-Type": "application/json",
        },
      });

      console.log("Booked stuff vvvvv");
      console.log(response);

      if (response.status === 200) {
        const reversed = response.data.bookings.reverse();
        setBookings(reversed);
      }
    } catch (error: any) {
      console.log(error.response?.status);
      if (error.response?.status === 401) {
        console.log(error.response);
      }
      if (error.response?.status === 403) {
        console.log(error.response);
        return;
      }
      if (error.response?.status === 404) {
        console.log(error.response);
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const bookingAcceptTruck = async (id: string) => {
    setLoading(true);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/bookings/${id}/accept`;

    try {
      const response = await axios.patch(
        url,
        {},
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
      successToast("Booking accepted");

      if (response.data.error) {
        errorToast(response.data.error);
      } else {
        settruckList(response.data.bookings);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        errorToast("Unauthorized 401");
      } else {
        errorToast(error?.response?.data?.error || "An error occured");
      }
    }
  };

  const bookingRejectTruck = async (id: string, isActiveNow?: boolean) => {
    if (isActiveNow) {
      const userConfirmed = window.confirm(
        "This booking is currently active. Are you sure you want to cancel it?"
      );

      if (!userConfirmed) {
        return;
      }
    }

    setLoading(true);
    const url = `${REACT_APP_TRUCK_API_BASE_URL}/bookings/${id}/cancel-by-owner`;

    try {
      const response = await axios.patch(
        url,
        {},
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
      successToast("Booking declined");

      if (response.data.error) {
        errorToast(response.data.error);
      } else {
        settruckList(response.data.bookings);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        errorToast("Unauthorized 401");
      } else {
        errorToast(error?.response?.data?.error || "An error occured");
      }
    }
  };

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div className="d-flex justify-content-between" style={{ width: "100%", height: "100vh" }}>
        <div className={styles.adminSideBar}>
          <AdminSidebar />
        </div>
        <div className={styles.allContent}>
          <div className={styles.head}>
            <span className={styles.headText}>Bookings</span>
          </div>

          <div className="container-fluid bg-white rounded-3 py-3">
            <div className="row row-cols-xs-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-3 g-0 py-2 px-2">
              <div className="d-flex">
                <div className={styles.box}>
                  <span>Assets in rent</span>
                  <h4>
                    {bookedList?.filter((b: any) => {
                      if (b.status == "active") return b;
                    }).length || 0}
                  </h4>
                </div>
              </div>
              <div className="d-flex">
                <div className={styles.box}>
                  <span>
                    Bookings{" "}
                    <abbr title="Trucks declined in the past two weeks">
                      declined
                    </abbr>
                  </span>
                  <h4>
                    {bookedList?.filter((b: any) => {
                      if (b.status == "cancelled") return b;
                    }).length || 0}
                  </h4>
                </div>
              </div>
              <div className="d-flex">
                <div className={styles.box}>
                  <span>Bookings pending</span>
                  <h4>
                    {bookedList?.filter((b: any) => {
                      if (b.status == "requested") return b;
                    }).length || 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          {bookedList?.map((booking: any, index: number) => {
            const bookedTruck = booking.truckId;
            return (
              <div className={`${styles.imageContainer}`} key={index}>
                <div className={`${styles.verticleCard} col-3`}>
                  <Carousel
                    className={`${carouselStyles?.vecicleCard2} ${styles.verticleCard}`}
                    id="truck_container"
                    interval={null}
                  >
                    {bookedTruck && bookedTruck.images ? (
                      Object.entries(bookedTruck.images).map(
                        ([key, image], imgIndex) => {
                          console.log("Truck");
                          return (
                            <Carousel.Item
                              key={`${booking.truckId}-${imgIndex}`}
                            >
                              <div
                                className={`${styles2.frameParent3 || "frame-24031@3x.png"
                                  } hoverButtons`}
                                style={{ cursor: "pointer" }}
                              >
                                <Link to={`/truck/${bookedTruck?._id}`}>
                                  <img
                                    src={
                                      typeof image === "string"
                                        ? image
                                        : "frame-24031@3x.png"
                                    }
                                    className={`${styles2.frameParent3} hoverButtons`}
                                    style={{ width: "100%" }}
                                    alt={key}
                                  />
                                </Link>
                              </div>
                            </Carousel.Item>
                          );
                        }
                      )
                    ) : (
                      <Carousel.Item>
                        <div>No images available</div>
                      </Carousel.Item>
                    )}
                  </Carousel>
                </div>
                <div
                  className={`${styles.imageContainerRight} col-lg-9 col-sm-6`}
                >
                  <div className="d-block">
                    <span
                      className={styles.imageContainerRightText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {bookedTruck?.year} &nbsp;
                      {bookedTruck?.manufacturer + " "}
                      {bookedTruck?.model} &nbsp;
                    </span>
                    <span className={styles.dot}></span>
                    &nbsp;
                    <span
                      className={styles.imageContainerRightText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {bookedTruck?.vehicleSubtype}
                    </span>
                    &nbsp;
                    <span className={styles.dot}></span>
                    &nbsp;
                    <span className={styles.imageContainerRightText}>
                      ${bookedTruck?.rentPerDay} / day{" "}
                    </span>
                    <span
                      className={styles.activeButton}
                      style={{ textTransform: "capitalize" }}
                    >
                      {booking?.status == "active"
                        ? "in rent"
                        : booking?.status}
                    </span>
                  </div>
                  <span style={{ textTransform: "capitalize" }}>
                    Book type: <b>{booking?.agreementType}&nbsp;</b>
                  </span>
                  <br />
                  <span>Truck ID: {bookedTruck?._id}</span>
                  <div className={styles.border}></div>
                  <span className={styles.imageContainerLightColorText}>
                    Start Date:{" "}
                    {booking?.startDate
                      ? formatDate(booking?.startDate)
                      : "Not specified"}
                  </span>{" "}
                  <br />
                  <span className={styles.imageContainerLightColorText}>
                    End Date:{" "}
                    {booking?.endDate
                      ? formatDate(booking?.endDate)
                      : "Not specified"}
                  </span>{" "}
                  <br />
                  <span
                    className={styles.imageContainerLightColorText}
                    style={{ textTransform: "capitalize" }}
                  >
                    Location: {booking?.truckId?.state?.toUpperCase()},{" "}
                    {booking?.truckId?.city}
                    ,&nbsp;
                    {booking?.truckId?.zipCode}
                  </span>{" "}
                  <div className={styles.border}></div>
                  <span className={styles.imageContainerLightColorText}>
                    Post by:
                  </span>
                  <br />
                  {currentUser.role == "renter" &&
                    booking.status == "requested" && (
                      <span className="d-block ">
                        <span className="d-block ">
                          <span
                            className={`${styles.imageContainerAccept} ${styles.declineTypography} hoverButtons`}
                            onClick={() => bookingAcceptTruck(booking._id)}
                          >
                            <svg
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-1"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
                                fill="white"
                              />
                            </svg>
                            Accept
                          </span>
                          <span
                            className={`${styles.imageContainerDecline} ${styles.declineTypography} hoverButtons`}
                            onClick={() => bookingRejectTruck(booking._id)}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-1"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                                fill="#0F172A"
                              />
                            </svg>
                            Decline
                          </span>
                        </span>
                      </span>
                    )}
                  {currentUser.role == "renter" &&
                    booking.status == "active" && (
                      <span className="d-block ">
                        <span
                          className={`${styles.imageContainerDecline} ${styles.declineTypography} hoverButtons`}
                          onClick={() => bookingRejectTruck(booking._id, true)}
                          style={{ backgroundColor: "#fa6b6b" }}
                        >
                          <svg
                            style={{ verticalAlign: "inherit" }}
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-1"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                              fill="#0F172A"
                            />
                          </svg>
                          <span>Cancel</span>
                        </span>
                      </span>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div >
    </>
  );
};

export default Reservations;
