import React, { useState } from "react";
import axios from 'axios';
import AddOrganizationModalBody from './AddOrganizationModalBody';
import GenericModal from '../../../components/Modal/GenericModal';
import { options } from "../../../lib/staticData";
import { AddOrganizationModalProps } from "./types";
import { Company } from "./types";
import { successToast, errorToast } from "../../../components/Toast/ToastMessage";
import { getAuthHeaders, getNoAuthHeaders } from "../../../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL, REACT_APP_AUTH_SVC_API_BASE_URL, REACT_APP_ORG_API_BASE_URL } from '../../../utils/UrlConstants';

const AddOrganizationModal: React.FC<AddOrganizationModalProps> = ({
  lgOrgShow,
  setLgOrgShow,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [requiredField] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [showOriginalContent, setShowOriginalContent] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCompaniesOriginal, setDataCompaniesOriginal] = useState<Company[]>([]);
  const [dataCompanies, setDataCompanies] = useState<Company[] | any>(dataCompaniesOriginal);

  const fetchData = async (search: string) => {
    try {
      setLoading(true)
      const apiUrl = `${REACT_APP_UPLOAD_API_BASE_URL}/upload/files/fmcsa/search?search=${search || companyName}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: getNoAuthHeaders(),
      });

      if (!response.ok) {
        console.error("failed", JSON.stringify(response));
        return;
      }
      
      const data = await response.json();
      const filteredData= data.filter((element:any):any => element.email !== "");

      setDataCompaniesOriginal(Array.from(filteredData));
      setDataCompanies(Array.from(filteredData));

    } catch (error) {
      console.error("Error:", error);
    }finally {
      setLoading(false)
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsDropdownOpen(true);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setInputValue(option);
  };

  const handleAddItClick = () => {
    setShowOriginalContent(false);
  };

  const handleCompanyNameChange = (
    input: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    // setLoading(true);
    let inputValue: string;
    if (typeof input === "string") {
      inputValue = input;
    } else {
      inputValue = input.target.value;
    }

    setCompanyName(inputValue);
    fetchData(inputValue);
    if (inputValue === "") {
      setDataCompanies(dataCompaniesOriginal);
      // setLoading(false);
    } else {
      const filteredCompanies = dataCompaniesOriginal.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const filteredData= filteredCompanies.filter((element:any):any => element.email !== "");
      setDataCompanies(filteredData);
      // setLoading(false);
    }

  };

  const handleAccordionClick = async (
    name: string,
    dot: string,
    item: any
  ) => {
    // setLoading(true);
    const updatedOrganization = {
      "organizations": [
        {
          "dotNumber": dot,
          "name": name
        },
      ],
    }
    const result = await createOrganization(item);
    if(result){
      try {
        const url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me/update`;
        const response :any = await axios.put(url, updatedOrganization, {
          headers: getAuthHeaders(),
        });
        if (response.status === 200) {
          localStorage.setItem('currentUserUpdated', 'true')
          successToast("Organization updated successfully!")
          setLgOrgShow(false);
        } else {
          errorToast("Error while updating organization!")
        }
      } catch (error) {
        errorToast("Error while updating organization!")
      }
    }
  };

  const createOrganization = async (requestBody :any) => {
    const apiUrl = `${REACT_APP_ORG_API_BASE_URL}/organization/create`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: getNoAuthHeaders(),
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.error || "Please select a valid organization";
        errorToast(errorMessage);
        setCompanyName("");
        return false;
      }  
      
      return data;
    } catch (error) {
      errorToast(`${error}`);
    }
  };

  const handleNextButtonClick = async () => {
    try {
    } catch (error) {
    }
  };

  return (
    <GenericModal
      onClickSubmit={handleAccordionClick}
      setShowModal={setLgOrgShow}
      cmp={AddOrganizationModalBody}
      showModal={lgOrgShow}
      showFooter={false}
      title="Update Organization"
      cmpProps={{
        filteredOptions,
        inputValue,
        isDropdownOpen,
        requiredField,
        companyName,
        dataCompanies,
        loading,
        handleInputChange,
        toggleDropdown,
        handleOptionSelect,
        handleCompanyNameChange,
        handleNextButtonClick,
        handleAddItClick,
        handleAccordionClick,
      }}
    />
  )
}

export default AddOrganizationModal;
