import styles from "./MapPageFilter.module.css";
import {Col, Modal} from "react-bootstrap";
import {truckManufacturers} from "../../../lib/staticData";
import {successToast} from "../../../components/Toast/ToastMessage";

interface MapPageFilterProps {
  closeFilter: () => void;
  fetchTrucks: () => void;
  passedOffersCount: (count: number) => void;
  setliftGate: (value: string) => void;
  setbodyType: (value: string) => void;
  setdoorType: (value: string) => void;
  setlength: (value: string) => void;
  settransmission: (value: string) => void;
  setothers: (value: string) => void;
  setGoal: (value: string) => void;
  setMinimumPrice: (value: number) => void;
  setMaximumPrice: (value: number) => void;
  setmenufacturer: (value: string) => void;
  setMileageFrom: (value: number) => void;
  setMileageTo: (value: number) => void;
  setResetSelect: (value: boolean) => void;
  setIsPortalOpen: (value: boolean) => void;
  setMileage: (value: string) => void;
  setYear: (value: string) => void;
  setFeet: (value: string) => void;
  feet: string;
  year: string;
  mileage: string;
  liftGate: string;
  bodyType: string;
  doorType: string;
  length: string;
  transmission: string;
  others: string;
  goal: string;
  minimumPrice: number;
  maximumPrice: number;
  menufacturer: string;
  mileageFrom: number;
  mileageTo: number;
  resetSelected: boolean;
  setYearMin: (value: number) => void;
  setYearMax: (value: number) => void;
  yearMin: number;
  yearMax:number;
  setVehicleSubType:(value: string) => void;
  setVehicleType:(value: string) => void;
  setSelectedPrecategory:(value:string)=> void;
  setSelectedItem:(value:string)=> void;
  setSelectedTruck:(value:string|null)=> void;
}

type SetStateFunction = (value: string) => void;

export const MapPageFilter: React.FC<MapPageFilterProps> = ({
                                                              closeFilter,
                                                              fetchTrucks,
                                                              setliftGate,
                                                              setbodyType,
                                                              setdoorType,
                                                              setlength,
                                                              settransmission,
                                                              setothers,
                                                              setGoal,
                                                              setMinimumPrice,
                                                              setMaximumPrice,
                                                              setmenufacturer,
                                                              setMileageFrom,
                                                              setMileageTo,
                                                              setResetSelect,
                                                              setIsPortalOpen,
                                                              setMileage,
                                                              setYear,
                                                              year,
                                                              mileage,
                                                              liftGate,
                                                              bodyType,
                                                              doorType,
                                                              length,
                                                              transmission,
                                                              others,
                                                              goal,
                                                              minimumPrice,
                                                              maximumPrice,
                                                              menufacturer,
                                                              mileageFrom,
                                                              mileageTo,
                                                              resetSelected,
                                                              setVehicleSubType,
                                                              setVehicleType,
                                                              setSelectedPrecategory,
                                                              setSelectedItem,
                                                              setSelectedTruck,
                                                              setYearMin,setYearMax,yearMin,yearMax
                                                            }) => {
  const handleClose = () => {
    closeFilter();
  };

  const resetAllCheckboxes = () => {
    const checkboxes = document.querySelectorAll(`.${styles.checkbox1}`);

    checkboxes.forEach((checkbox) => {
      const checkboxInput = checkbox as HTMLInputElement;
      checkboxInput.checked = false;
    });
  };

  const setFilters = (
    condition: boolean,
    filterStr: string,
    setState: SetStateFunction,
    filterName: string
  ) => {
    const mileageFilters = ["Up to 100k", "Up to 200k", "Over 300k"];
    const isMileageFilter = mileageFilters.includes(filterStr);
    const yearFilters = ["Up to 2010", "Up to 2015", "Up to 2020", "Up to 2023"];
    const isYearFilter = yearFilters.includes(filterStr);
    if (isMileageFilter) {
      if (!condition) {
        setState(`${filterStr}` );
      } else {
        setState(`${filterName.replace(`${filterStr}`, "")}`);
      }
    }else if (isYearFilter) {
      if (!condition) {
        setState(`${filterStr}` );
      } else {
        setState(`${filterName.replace(`${filterStr}`, "")}`);
      }
    } else {
    if (!condition) {
      setState(`${filterName ? `${filterName}, ${filterStr}` : `${filterStr}`}`);
    } else {
      setState(`${filterName.replace(`${filterStr}`, "")}`);
    }
    }
  };

  const resetAllStates = () => {
    setSelectedTruck(null);
    setVehicleSubType("");
    setVehicleType("");
    setSelectedPrecategory("");
    setSelectedItem("");
    setMinimumPrice(0);
    setMaximumPrice(0);
    setMileageFrom(0);
    setMileageTo(0);
    setYearMax(0);
    setYearMin(0);
    resetAllCheckboxes();
    setmenufacturer("");
    setliftGate("");
    setbodyType("");
    setdoorType("");
    setlength("");
    settransmission("");
    setothers("");
    setGoal("");
    setResetSelect(!resetSelected);
    setMileage("");
    setYear("");
    setIsPortalOpen(false);
    fetchTrucks();
    successToast("Filters Cleared");
  };

  return (
    <>
      <div>
        <Modal
          show={true}
          onHide={() => setIsPortalOpen(false)}
          size="lg"
          style={{borderRadius: "20px", zIndex: "100000"}}
          className={`password-modal`}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <b className={styles.modalTitle}>Filters</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <Col>
              <div className={styles.rectangleGroup}>
                <div className={styles.frameParent6}>
                  <div className={styles.userNameParent}>
                    <div className={`${styles.userName1}`}>Price range</div>
                    <div className={`${styles.userName2} mb-4`}>
                      Average price per truck: 140$
                    </div>
                  </div>
                  <img
                    className={styles.image80Icon}
                    alt=""
                    src="/generics/image-80@2x.png"
                  />
                  <div className={styles.frameWrapper4}>
                    <div className={`${styles.inputWithLabelParent} `}>
                      <div className={`${styles.inputWithLabel} `}>
                        <div className={styles.reefer}>Minimum price</div>
                        <div className={`${styles.inputWithPrefixessuffixes}`}>
                          <div
                            className={styles.basicInput}
                            style={{
                              border: "none",
                              height: "99%",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div className={styles.inputContainer}>
                              <span className={styles.dollarSign}>$</span>
                              <input
                                className={`${styles.inputText} ${styles.moneyField}`}
                                placeholder={minimumPrice === 0 ? "From" : ""}
                                type="number"
                                value={
                                  minimumPrice === 0 ? "From" : minimumPrice
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setMinimumPrice(Number(event.target.value));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.div8}`}>-</div>
                      <div className={`${styles.inputWithLabel} `}>
                        <div className={styles.reefer}>Maximum price</div>
                        <div className={styles.inputWithPrefixessuffixes}>
                          <div
                            className={styles.basicInput}
                            style={{
                              border: "none",
                              height: "99%",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div className={styles.inputContainer}>
                              <span className={styles.dollarSign}>$</span>
                              <input
                                className={`${styles.inputText} ${styles.moneyField}`}
                                placeholder={maximumPrice === 0 ? "To" : ""}
                                type="number"
                                value={maximumPrice === 0 ? "To" : maximumPrice}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setMaximumPrice(Number(event.target.value));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.parentRectangleDiv}>
                <div className={styles.rectangleDiv}/>
              </div>
              <div className={`${styles.inputTextParent1}`}>
                <div>
                  <h5>
                    <div className={`${styles.inputText11}`}>Goal</div>
                  </h5>
                </div>
                <div className={styles.gapContainer}>
                  {["For rent", "For lease", "Lease to purchase"].map(
                    (v: string, i: number) => (
                      <div
                        key={i}
                        className={`${styles.tag11} hoverButtons ${
                          styles.tagParent
                        } float-left ${
                          goal.includes(v)
                            ? `${styles.tag27} ${styles.tag11}`
                            : ""
                        }`}
                      >
                        <div
                          className={styles.reefer}
                          onClick={() => {
                            setFilters(goal.includes(v), v, setGoal, goal);
                          }}
                        >
                          {v}
                          {goal.includes(v) && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="close.svg"
                            />
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className={styles.parentRectangleDiv}>
                <div className={styles.rectangleDiv}/>
                <div className={styles.rectangleDiv2}/>
              </div>
              <div className={styles.inputTextParent}>
                <div className={styles.userNameGroup}>
                  <h4 style={{fontWeight: 600, fontSize: "20px"}}>Body</h4>

                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>
                          Vehicle Type
                        </div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {
                        ["Truck", "Trailer", "Box Truck"].map(
                          (v: string, i: number) => (
                            <div
                              key={i}
                              className={`${styles.tag11} hoverButtons ${
                                styles.tagParent
                              } float-left ${
                                bodyType.includes(v)
                                  ? `${styles.tag27} ${styles.tag11}`
                                  : ""
                              }`}
                              onClick={() => {
                                setFilters(
                                  bodyType.includes(v),
                                  v,
                                  setbodyType,
                                  bodyType
                                );
                              }}
                            >
                              <div className={styles.reefer}>{v}</div>
                              {bodyType.includes(v) && (
                                <img
                                  className={styles.iconsolidx1}
                                  alt=""
                                  src="close.svg"
                                />
                              )}
                            </div>
                          )
                        )
                      }
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Door Type</div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {["swing", "roll"].map((v: string, i: number) => (
                        <div
                          key={i}
                          className={`${styles.tag11} float-left ${
                            doorType.includes(v)
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setFilters(
                              doorType.includes(v),
                              v,
                              setdoorType,
                              doorType
                            );
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div
                            className={styles.reefer}
                            style={{textTransform: "capitalize"}}
                          >
                            {v}
                          </div>
                          {doorType.includes(v) && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="close.svg"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Lift Gate Type</div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {["Rail Gate", "Truck Under", "No Lift Gate"].map(
                        (v: string, i: number) => (
                          <div
                            key={i}
                            className={`${styles.tag11} float-left ${
                              liftGate.includes(v)
                                ? `${styles.tag27} ${styles.tag11}`
                                : ""
                            }`}
                            onClick={() => {
                              setFilters(
                                liftGate.includes(v),
                                v,
                                setliftGate,
                                liftGate
                              );
                            }}
                          >
                            <img
                              className={styles.earnRevenueFromYourIdleFl}
                              alt=""
                              src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                            />
                            <div className={styles.reefer}>{v}</div>
                            {liftGate.includes(v) && (
                              <img
                                className={styles.iconsolidx1}
                                alt=""
                                src="close.svg"
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Length (feet)</div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {["40", "45", "48", "53"].map((v: string, i: number) => (
                        <div
                          key={i}
                          className={`${styles.tag11} float-left ${
                            length.includes(v)
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setFilters(
                              length.includes(v),
                              v,
                              setlength,
                              length
                            );
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>{v}</div>
                          {length.includes(v) && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="close.svg"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Chassis</div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {["2x2", "2x4", "2x6", "6x4"].map(
                        (v: string, i: number) => (
                          <div
                            key={i}
                            className={`${styles.tag11} float-left ${
                              transmission.includes(v)
                                ? `${styles.tag27} ${styles.tag11}`
                                : ""
                            }`}
                            onClick={() => {
                              setFilters(
                                transmission.includes(v),
                                v,
                                settransmission,
                                transmission
                              );
                            }}
                          >
                            <img
                              className={styles.earnRevenueFromYourIdleFl}
                              alt=""
                              src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                            />
                            <div className={styles.reefer}>{v}</div>
                            {transmission.includes(v) && (
                              <img
                                className={styles.iconsolidx1}
                                alt=""
                                src="close.svg"
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={`${styles.inputText11}`}>Mileage</div>
                      </h5>
                    </div>
                    <div>
                      <div className={styles.frameParent8}>
                        <div className={styles.basicInputContainer}>
                          <div className={styles.basicInput}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={mileageFrom == 0 ? "From" : ""}
                              type="number"
                              value={mileageFrom === 0 ? "From" : mileageFrom}
                              onChange={(event: any) => {
                                setMileageFrom(event.target.value);
                              }}
                            />
                          </div>
                          <div className={styles.basicInput1}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={mileageTo === 0 ? "To" : ""}
                              type="number"
                              value={mileageTo === 0 ? "To" : mileageTo}
                              onChange={(event: any) => {
                                setMileageTo(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.tandemTractor}>k</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}></div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {["Up to 100k", "Up to 200k", "Over 300k"].map(
                        (v: string, i: number) => (
                          <div
                            key={i}
                            className={`${
                              styles.tag11
                            } float-left ${
                              mileage.includes(v)
                                ? `${styles.tag27} ${styles.tag11}`
                                : ""
                            }`}
                            onClick={() => {
                              setFilters(
                                mileage.includes(v),
                                v,
                                setMileage,
                                mileage
                              );
                            }}
                          >
                            <img
                              className={styles.earnRevenueFromYourIdleFl}
                              alt=""
                              src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                            />
                            <div className={styles.reefer}>{v}</div>
                            {mileage.includes(v) && (
                              <img
                                className={styles.iconsolidx1}
                                alt=""
                                src="close.svg"
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={`${styles.inputText11}`}>Year</div>
                      </h5>
                    </div>
                    <div>
                      <div className={styles.frameParent8}>
                        <div className={styles.basicInputContainer}>
                          <div className={styles.basicInput}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={yearMin === 0 ? "From" : ""}
                              type="number"
                              value={yearMin === 0 ? "From" : yearMin}
                              onChange={(event: any) => {
                                setYearMin(event.target.value);
                              }}
                            ></input>
                          </div>
                          <div className={styles.basicInput1}>
                            <input
                              className={`${styles.inputText}`}
                              placeholder={yearMax === 0 ? "To" : ""}
                              type="number"
                              value={yearMax === 0 ? "To" : yearMax}
                              onChange={(event: any) => {
                                setYearMax(event.target.value);
                              }}
                            ></input>
                          </div>
                        </div>
                        <div className={styles.tandemTractor}>year</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}></div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {[
                        "Up to 2010",
                        "Up to 2015",
                        "Up to 2020",
                        "Up to 2023",
                      ].map((v: string, i: number) => (
                        <div
                          key={i}
                          className={`${styles.tag11} float-left ${
                            year.includes(v)
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setFilters(year.includes(v), v, setYear, year);
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>{v}</div>
                          {year.includes(v) && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="close.svg"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.inputTextParent1}>
                    <div>
                      <h5>
                        <div className={styles.inputText11}>Others</div>
                      </h5>
                    </div>
                    <div className={styles.gapContainer}>
                      {[
                        "Crew Cab",
                        "Ramp",
                        "Dock Height",
                        "IFTA Compliant",
                      ].map((v: string, i: number) => (
                        <div
                          key={i}
                          className={`${styles.tag11} float-left ${
                            others.includes(v)
                              ? `${styles.tag27} ${styles.tag11}`
                              : ""
                          }`}
                          onClick={() => {
                            setFilters(
                              others.includes(v),
                              v,
                              setothers,
                              others
                            );
                          }}
                        >
                          <img
                            className={styles.earnRevenueFromYourIdleFl}
                            alt=""
                            src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                          />
                          <div className={styles.reefer}>{v}</div>
                          {others.includes(v) && (
                            <img
                              className={styles.iconsolidx1}
                              alt=""
                              src="close.svg"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={styles.parentRectangleDiv}>
                    <div className={styles.rectangleDiv}/>
                    <div className={styles.rectangleDiv2}/>
                  </div>
                  {/*<div className={styles.inputTextParent}>*/}
                  {/*  <div className={`${styles.frameChild30} my-4`}/>*/}


                  <div className={`${styles.userNameContainer} w-100`}>
                    <div className={styles.userName1}>Manufacturer</div>
                    <div className={`${styles.frameParent11}`}>
                      <div className={styles.inputTextParent11}>
                        <div className={`${styles.checkboxParent} ${styles.parentGrid}`}>
                          {truckManufacturers.map((v: string, i: number) => (
                            <>
                              <div className={styles.checkbox} key={i}>
                                <input
                                  checked={new RegExp(
                                    `\\b${v.toLowerCase()}\\b`
                                  ).test(menufacturer.toLowerCase())}
                                  className={styles.checkbox1}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const isChecked = event.target.checked;
                                    setFilters(
                                      !isChecked,
                                      `${v.toLowerCase()}`,
                                      setmenufacturer,
                                      menufacturer
                                    );
                                  }}
                                  type="checkbox"
                                />
                                <div className={styles.tandemTractor}>{v}</div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Modal.Body>
          <div className="border-top mt-2">
            <div
              className={`${styles.button15} hoverButtons col-lg-2 m-3 col-11`}
              onClick={resetAllStates}
              style={{float: "left"}}
            >
              <div className={styles.text}>Clear all</div>
            </div>
            <div
              className={`${styles.button14} hoverButtons col-lg-2 m-3 col-11`}
              onClick={handleClose}
              style={{float: "right"}}
            >
              <div className={styles.text}>Apply Filters</div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MapPageFilter;
