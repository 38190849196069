export const articles = [
  {
    id: "1",
    img: "/TrucksImages/1.jpg",
    alt: "Powerful truck built for tough terrains",
    heading: "Cargo Theft in Canada & U.S. Hits 13,500 Cases in 2024 – How to Protect Your Shipments",
    description: "Cargo theft is surging across North America, with over 13,500 cases recorded in 2024! Ontario leads in Canada with 85% of thefts, while California and Texas remain top hotspots in the U.S. Key trends include a rise in rail thefts (89%) and deceptive pickup scams. Learn how to secure your freight and prevent financial losses.",
    read: "4 min read",
    conclusion: "Cargo theft is evolving—stay ahead by securing your shipments and using advanced tracking methods.",
  },
  {
    id: "2",
    alt: "Well-maintained truck ready for work",
    img: "/TrucksImages/Trucks.jpg",
    heading: "Essential Elements of an Equipment Rental Agreement for Trucking Professionals",
    description: "Renting out trucks, trailers, or equipment? A solid rental agreement is critical to protect your business. Cover key points like rental terms, maintenance, liability, usage restrictions, and penalties for late returns. Avoid disputes by crafting a contract that ensures smooth transactions and safeguards your assets.",
    read: "6 min read",
    conclusion: "A well-structured rental contract protects your business and fosters long-term success in the trucking industry.",
  },
  {
    id: "3",
    img: "/TrucksImages/2.jpg",
    alt: "Heavy-duty trucks for transportation",
    heading: "Double Brokering Scams Are Targeting Truckers – How to Stay Safe",
    description: "Double brokering is a rising threat in the freight industry, where scammers pose as brokers to steal cargo. Learn how fraudsters operate, the risks involved, and the best strategies to protect your trucking business. From identity theft to fake bids, uncover the red flags and security measures you need to implement.",
    read: "2 min read",
    conclusion: "Vigilance is key—verify brokers, monitor transactions, and stay alert to prevent double brokering fraud.",
  },
  {
    id: "4",
    img: "/TrucksImages/3.jpg",
    alt: "Spacious and durable transport truck",
    heading: "How to Prevent Cargo Theft: 6 Security Tips for Truckers",
    description: "Cargo theft costs the trucking industry billions yearly. Secure your shipments with high-security locks, GPS tracking, vetted drivers, and strategic parking choices. Learn the best prevention strategies to keep your freight safe from organized criminals and opportunistic thieves.",
    read: "3 min read",
    conclusion: "Implement strong security measures to reduce theft risks and protect your cargo from losses.",
  },
  {
    id: "5",
    img: "/TrucksImages/4.jpg",
    alt: "Versatile truck for business use",
    heading: "New Trucking Regulations in 2024 – What Drivers & Fleet Owners Need to Know",
    description: "Major changes in trucking regulations, including new ELD requirements, emissions standards, and freight brokerage rules, will impact drivers and fleet owners in 2024. Stay informed to ensure compliance, avoid penalties, and optimize your operations.",
    read: "5 min read",
    conclusion: "Adapting to new trucking laws ensures smoother operations, fewer fines, and a more efficient fleet.",
  },
  {
    id: "6",
    img: "/TrucksImages/5.jpg",
    alt: "Powerful truck built for tough terrains",
    heading: "Understanding Freight Contracts – What Every Trucker Must Know",
    description: "Freight contracts define your payment terms, liability clauses, and flexibility options. Learn how to protect your profits by negotiating fair terms and avoiding common contract pitfalls in the trucking industry.",
    read: "6 min read",
    conclusion: "Strong freight contracts provide clarity, legal protection, and better financial stability for trucking businesses.",
  },
  {
    id: "7",
    img: "/TrucksImages/6.jpg",
    alt: "Reliable truck for cargo and logistics",
    heading: "7 Fuel-Saving Strategies for Truckers – Cut Costs & Boost Profits",
    description: "Fuel costs continue to rise—learn how to maximize savings with efficient route planning, fuel cards, regular truck maintenance, and smart driving techniques. Reduce expenses and keep more profits in your pocket.",
    read: "3 min read",
    conclusion: "Proper fuel management reduces operating costs and increases profitability in trucking operations.",
  },
  {
    id: "8",
    img: "/TrucksImages/7.jpg",
    alt: "Efficient truck for long-distance hauling",
    heading: "Truck Maintenance Checklist – Keep Your Fleet Running Smoothly",
    description: "Prevent costly breakdowns with a proactive truck maintenance routine. Check your engine, tire pressure, oil levels, and safety features regularly to ensure your fleet stays in peak condition and avoids downtime.",
    read: "5 min read",
    conclusion: "Routine maintenance keeps trucks running efficiently, ensuring safety and reducing repair costs.",
  }
];

export const trucks = [
  "Car Trailers",
  "Box truck",
  "Refrigerated Box Trucks",
  "Expeditor Trucks"
];

export const trailers = [
  "Flatbed ST",
  "Dry Van Semi-Trailer",
  "Drop Deck ST",
  "Car Carrier Trailers ST"
];

export const popularCities = [
  "Arlington, TX",
  "Charlotte, NC",
  "Dallas, TX",
  "Indianapolis, IN",
  "Los Angeles, CA",
  "New York, NY",
  "Phoenix, AZ",
  "San Diego, CA",
  "Atlanta, GA",
  "Baltimore, MD",
  "Chicago, IL",
  "Columbus, OH",
  "Detroit, MI",
  "Fontana, CA",
  "Jackson, CA",
  "Knoxville, TN",
  "Memphis, TN",
  "Miami, FL",
  "Newark, NJ",
  "Philadelphia, PA",
  "Pittsburgh, PA",
  "Sacramento, CA",
  "St. Louis, MO",
  "Stamford, CT"
];
