import { useState, useEffect } from "react";
import styles from "./css/AddTruckFinal.module.css";
import { Link, useNavigate } from "react-router-dom";
import { REACT_APP_SHORT_COUNTDOWN } from '../../utils/UrlConstants';
import SubscriptionCards from "../../components/Common/Subscription_Cards/SubscriptionCards";

const AddTruckFinal: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(parseInt(REACT_APP_SHORT_COUNTDOWN || '5'));

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCountdown((prevCountdown) => prevCountdown - 1);
  //   }, 1000);
  //
  //   if (countdown === 0) {
  //     clearInterval(timer);
  //     navigate("/main");
  //   }
  //
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [countdown, navigate]);

  return (<>
    {/*<div className={styles.registerATruckOrEdit7P}>*/}
    {/*  <div className={styles.registerATruckOrEdit7PChild} />*/}
    {/*  <div className={styles.modal}>*/}
    {/*    <div className={styles.iconParent}>*/}
    {/*      <img className={styles.icon1} src="/generics/iconsolidcheckcircle.svg" />*/}
    {/*      <div className={styles.userName}>*/}
    {/*        Thank you for successfully registering truck on Truck4 website!*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*    <div className={styles.inputTextParent}>*/}
    {/*      <div className={styles.inputText}>*/}
    {/*        The track has been submitted for moderation, you will be notified*/}
    {/*        when the registration process is completed*/}
    {/*      </div>*/}
    {/*      <div className={styles.inputText1}>*/}
    {/*        Redirecting in {countdown} sec*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*    <Link to="/main" className={styles.button4}>*/}
    {/*      <div className={styles.text3}>Go back to site</div>*/}
    {/*    </Link>*/}
    {/*  </div>*/}
    {/*</div>*/}
      <div className={styles.subscriptionCard}>
      <SubscriptionCards/>
      </div>
    </>
  );
};

export default AddTruckFinal;
