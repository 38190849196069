import styled from "styled-components";

export const StyledButton = styled.button`
  border: 0;
  right: 5%;
  background-color: transparent;
`;

export const Container = styled.div`
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 24vh;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;